import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import AuthService from '../../../api/services/AuthService';
import { alertSuccessMessage } from '../../../customComponents/CustomAlertMessage';
import TVChartContainer from "../../../customComponents/Libraries/TVChartContainer/indexmobile";
import { ProfileContext } from "../../../context/ProfileProvider";
import "swiper/css";
import "swiper/css/pagination";
import { SocketContext } from '../../../customComponents/SocketContext';

const MobileChart = () => {
    let params = useParams()
    let URL = params?.pairs?.split('_');
    const { buySellSkip, handleUserDetials, kycStatus } = useContext(ProfileContext);
    const token = sessionStorage.getItem('token');
    const userId = sessionStorage.getItem('userId');
    const [urlPath, setUrlPath] = useState(URL ? URL : []);
    const [search, setsearch] = useState('');
    const [AllData, setAllData] = useState([]);
    const [BuyOrders, setBuyOrders] = useState([]);
    const [CoinPairDetails, setCoinPairDetails] = useState();
    const [RecentTrade, setRecentTrade] = useState([]);
    const [SellOrders, setSellOrders] = useState([]);
    const [SelectedCoin, setSelectedCoin] = useState();
    const [baseCurId, setbaseCurId] = useState();
    const [quoteCurId, setquoteCurId] = useState();




    // ********* Socket Connection and Data fetch ********** //
    const { socket } = useContext(SocketContext);

    useEffect(() => {
        if (socket) {
            let payload = {
                'message': 'exchange',
            }
            socket.emit('message', payload);
            socket.on('message', (data) => {
                setAllData(data);
                setCoinPairDetails(data?.pairs);
            });
        }
    }, [socket]);

    // ********* Auto Select Coin Pair after Socket Connection ********** //
    useEffect(() => {
        if (!SelectedCoin && CoinPairDetails) {
            var Pair;
            var filteredData;
            if (urlPath?.length > 0) {
                filteredData = CoinPairDetails?.filter?.((item) => {
                    return urlPath[0]?.includes(item?.base_currency) && urlPath[1]?.includes(item?.quote_currency)
                })
            }
            if (filteredData?.length > 0) {
                Pair = filteredData[0]
            }
            else {
                Pair = CoinPairDetails[0]
            }
            navigate(`/chart/${Pair?.base_currency}_${Pair?.quote_currency}`);

            setSelectedCoin(Pair);
            let payload = {
                'message': 'exchange',
                'userId': userId,
                'base_currency_id': Pair?.base_currency_id,
                'quote_currency_id': Pair?.quote_currency_id,
            }
            socket.emit('message', payload);
        }
    }, [CoinPairDetails]);

    useEffect(() => {
        let interval;
        if (baseCurId && quoteCurId) {
            interval = setInterval(() => {
                let payload = {
                    'message': 'exchange',
                    'userId': userId,
                    'base_currency_id': baseCurId,
                    'quote_currency_id': quoteCurId,
                }
                socket.emit('message', payload);
            }, 5000)
        }
        return (() => {
            clearInterval(interval)
        })
    }, [baseCurId]);





    const navigate = useNavigate()

    return (
        <>
            <div className="trade-wrapper mobile_trade spot login_bg mb-5 pb-3 " style={{ backgroundColor: 'black' }}>
                <div className="spot-container container-fluid p-0">
                    <div className="row g-2" >
                        <div className="col-12" >
                            {/* Trade view */}
                            <div className=" mb-1 p-0"  >
                                <div className="cstm_tabs" >
                                    <div  >
                                        {SelectedCoin?.base_currency === undefined ? <div style={{ width: '100%', height: '500px' }}>
                                            <div className="loading-wave " style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                                <div className="loading-bar"></div>
                                            </div>
                                        </div> :
                                            <TVChartContainer symbol={`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`} />
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileChart;