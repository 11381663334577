import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponents/CustomAlertMessage";
import TVChartContainer from "../../../customComponents/Libraries/TVChartContainer";
import '../TradePage/trade_new.css'
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import { ProfileContext } from "../../../context/ProfileProvider";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { SocketContext } from "../../../customComponents/SocketContext";
import "swiper/css";
import "swiper/css/pagination";
import { Helmet } from "react-helmet-async";

const Trade = () => {
    let params = useParams()
    let URL = params?.pairs?.split('_');

    const location = useLocation();
    const { state } = location;

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    let recentPair = sessionStorage.getItem('RecentPair');
    let ParsedPair = JSON.parse(recentPair);
    const [urlPath, setUrlPath] = useState(URL ? URL : []);
    const [search, setsearch] = useState('');
    const [AllData, setAllData] = useState([]);
    const [BuyOrders, setBuyOrders] = useState([]);
    const [CoinPairDetails, setCoinPairDetails] = useState();
    const [RecentTrade, setRecentTrade] = useState([]);
    const [SellOrders, setSellOrders] = useState([]);
    const [buyamount, setbuyamount] = useState(1);
    const [sellAmount, setsellAmount] = useState(1);
    const [infoPlaceOrder, setinfoPlaceOrder] = useState('MARKET');
    const [coinFilter, setcoinFilter] = useState('ALL');
    const [BuyCoinBal, setBuyCoinBal] = useState();
    const [SellCoinBal, setSellCoinBal] = useState();
    const [openOrders, setopenOrders] = useState([]);
    const [orderType, setorderType] = useState('All');
    const [pastOrderType, setpastOrderType] = useState('All');
    const [priceDecimal, setpriceDecimal] = useState(8);
    const [pastOrders, setpastOrders] = useState([]);
    const [pastOrder2, setpastOrder2] = useState([]);
    const [favCoins, setfavCoins] = useState([]);
    const [sellOrderPrice, setsellOrderPrice] = useState(undefined);
    const [buyOrderPrice, setbuyOrderPrice] = useState(undefined);
    const [priceChange, setpriceChange] = useState();
    const [changesHour, setChangesHour] = useState();
    const [priceHigh, setpriceHigh] = useState();
    const [priceLow, setpriceLow] = useState();
    const [volume, setvolume] = useState();
    const [showCoinList, setShowCoinList] = useState(false);
    const [showDeleteAllOrder, setShowDeleteAllOrder] = useState(true);
    const [activetabpast, setActivetabpast] = useState(false);
    const [loader, setloader] = useState(true);
    const [baseCurId, setbaseCurId] = useState();
    const [quoteCurId, setquoteCurId] = useState();
    const [buyprice, setbuyprice] = useState();
    const [sellPrice, setsellPrice] = useState();
    const [SelectedCoin, setSelectedCoin] = useState();
    const [totalDataLength, setTotalDataLength] = useState([]);
    const [notificationList, setnotificationList] = useState([]);
    const [announcementBar, setannouncementBar] = useState(false);
    const { userDetails } = useContext(ProfileContext);
    const KycStatus = userDetails?.kycVerified;
    const { socket } = useContext(SocketContext);
    const navigate = useNavigate()
    let socketId = sessionStorage.getItem("socketId")



    useEffect(() => {
        if (socket) {
            if (state) {
                let payload = {
                    'message': 'market',
                    'userId': userId,
                    'base_currency_id': state?.base_currency_id,
                    'quote_currency_id': state?.quote_currency_id,
                };
                socket.emit('message', payload);
            } else if (ParsedPair && !state) {
                let payload = {
                    'message': 'exchange',
                    'userId': userId,
                    'socketId': socketId,
                    'base_currency_id': ParsedPair?.base_currency_id,
                    'quote_currency_id': ParsedPair?.quote_currency_id,
                };
                socket.emit('message', payload);
            } else {
                let payload = {
                    'message': 'market',
                    'userId': userId,
                };
                socket.emit('message', payload);
            }
        }

    }, [state, socket]);

    useEffect(() => {
        if (socket) {
            let payload = {
                'message': 'exchange',
                'userId': userId,
                'socketId': socketId,
            };
            socket.emit('message', payload);
            socket.on('message', (data) => {
                setAllData(data);
                setBuyOrders(data?.buy_order?.slice(0, 10));
                setRecentTrade(data?.recent_trades);
                setSellOrders(data?.sell_order?.reverse()?.slice(-10)?.reverse());
                setBuyCoinBal(data?.balance?.quote_currency_balance);
                setSellCoinBal(data?.balance?.base_currency_balance);
                setopenOrders(data?.open_orders?.reverse());
                setloader(false);
            });
        }
    }, [socket]);


    useEffect(() => {
        let interval;
        if (baseCurId && quoteCurId && socket) {

            interval = setInterval(() => {
                let payload = {
                    'message': 'exchange',
                    'userId': userId,
                    'socketId': socketId,
                    'base_currency_id': baseCurId,
                    'quote_currency_id': quoteCurId,
                    name: "socket",
                }
                socket.emit('message', payload);
            }, 1000)
        }
        return (() => {
            clearInterval(interval)
        })
    }, [baseCurId, quoteCurId, socket]);



    // ********* Auto Select Coin Pair after Socket Connection ********** //
    useEffect(() => {
        if (!SelectedCoin && CoinPairDetails) {
            var Pair;
            var filteredData;
            if (urlPath?.length > 0) {
                filteredData = CoinPairDetails?.filter?.((item) => {
                    return urlPath[0]?.includes(item?.base_currency) && urlPath[1]?.includes(item?.quote_currency)
                })
            }
            if (filteredData?.length > 0) {
                Pair = filteredData[0]
            }
            else {
                Pair = CoinPairDetails[0]
            }
            navigate(`/trade/${Pair?.base_currency}_${Pair?.quote_currency}`);
            setloader(true);
            setsellOrderPrice(undefined);
            setbuyOrderPrice(undefined);
            setSelectedCoin(Pair);
            setbaseCurId(Pair?.base_currency_id);
            setquoteCurId(Pair?.quote_currency_id);
            setbuyprice(Pair?.buy_price);
            setsellPrice(Pair?.sell_price);
            let payload = {
                'message': 'exchange',
                'socketId': socketId,
                'userId': userId,
                'base_currency_id': Pair?.base_currency_id,
                'quote_currency_id': Pair?.quote_currency_id,
            }
            socket.emit('message', payload);
        }
    }, [CoinPairDetails, infoPlaceOrder]);


    useEffect(() => {
        let filteredData = pastOrder2?.filter((item) => {
            return pastOrderType === item?.side || pastOrderType === 'All'
        })
        setpastOrders(filteredData ? filteredData?.reverse() : [])
    }, [pastOrderType]);


    // ********* Update Buy Sell 24HChange High Low Volume Price********** //
    useEffect(() => {
        let filteredData = AllData?.pairs?.filter((item) => {
            return item?.base_currency_id === SelectedCoin?.base_currency_id
        })
        if (filteredData) {
            setbuyprice(filteredData[0]?.buy_price);
            setsellPrice(filteredData[0]?.sell_price);
            setpriceChange(filteredData[0]?.change_percentage);
            setChangesHour(filteredData[0]?.change);
            setpriceHigh(filteredData[0]?.high);
            setpriceLow(filteredData[0]?.low);
            setvolume(filteredData[0]?.volume);
        }
    }, [AllData]);

    // ********* Update Buy Sell 24HChange High Low Volume Price********** //
    useEffect(() => {
        if (AllData && SelectedCoin) {
            let filteredData = AllData?.pairs?.filter((item) => {
                return item?.base_currency_id === SelectedCoin?.base_currency_id && item?.quote_currency_id === SelectedCoin?.quote_currency_id
            })
            if (filteredData) {
                setbuyprice(filteredData[0]?.buy_price);
                setsellPrice(filteredData[0]?.sell_price);
                setpriceChange(filteredData[0]?.change_percentage);
                setChangesHour(filteredData[0]?.change);
                setpriceHigh(filteredData[0]?.high);
                setpriceLow(filteredData[0]?.low);
                setvolume(filteredData[0]?.volume);
            }
        }
    }, [AllData]);


    // ********* Search Coins ********** //
    useEffect(() => {
        let filteredData = AllData?.pairs?.filter((item) => {
            return item?.base_currency?.toLowerCase().includes(search?.toLowerCase()) || item?.quote_currency?.toLowerCase().includes(search?.toLowerCase())
        })
        setCoinPairDetails(filteredData)
    }, [search, AllData]);



    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        token && favoriteList();
        NotificationList()
    }, []);


    const NotificationList = async () => {
        await AuthService.getLatestNews().then(async result => {
            if (result?.success) {
                let filteredData = result?.data?.filter((item) => item?.language === "english")
                setannouncementBar(true)
                setnotificationList(filteredData);
            }
            else {
                setannouncementBar(false)
            }
        });
    };


    const handleOrderPlace = async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.placeOrder(infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage('Order Placed Successfully!!')
                    setbuyOrderPrice(undefined);
                    setsellOrderPrice(undefined);
                    let payload = {
                        'message': 'exchange',
                        'userId': userId,
                        'socketId': socketId,
                        'base_currency_id': SelectedCoin?.base_currency_id,
                        'quote_currency_id': SelectedCoin?.quote_currency_id,
                    };
                    socket.emit('message', payload);
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message)
            }
        })
    };

    const handlePastOrder = async (base_currency_id, quote_currency_id, skip, limit) => {
        await AuthService.pastOrder(base_currency_id, quote_currency_id, skip, limit).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setpastOrders(result?.data)
                    setpastOrder2(result?.data)
                    setTotalDataLength(result?.total_count)
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        })
    };

    const cancelOrder = async (orderId) => {
        await AuthService.cancelOrder(orderId).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage('Order Cancelled Successfully');
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message)
            }
        })
    };


    const handleCancelAllOrder = async () => {
        await AuthService.cancelAllOrder().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage('Order Cancelled Successfully');
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message)
            }
        })
    };


    const handleAddFav = async (pairId) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.favoriteCoin(pairId).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                favoriteList()
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message)
            }
        })
    };
    const favoriteList = async () => {
        await AuthService.favoriteList().then((result) => {
            if (result?.success) {
                setfavCoins(result?.data?.pairs ? result?.data?.pairs : ['']);
            }
        });
    };


    const handleSelectCoin = (data) => {
        setinfoPlaceOrder("MARKET");
        navigate(`/trade/${data?.base_currency}_${data?.quote_currency}`);
        setloader(true);
        setsellOrderPrice(undefined);
        setbuyOrderPrice(undefined);
        setSelectedCoin(data);
        setbaseCurId(data?.base_currency_id);
        setquoteCurId(data?.quote_currency_id);
        setbuyprice(data?.buy_price);
        setsellPrice(data?.sell_price);
        setShowCoinList(!showCoinList);
        setbuyamount(1);
        setsellAmount(1);
        let payload = {
            'message': 'exchange',
            'userId': userId,
            'socketId': socketId,
            'base_currency_id': data?.base_currency_id,
            'quote_currency_id': data?.quote_currency_id,
        }
        socket.emit('message', payload);
    };

    const handleOrderType = (e) => {
        // if (SelectedCoin?.available === "LOCAL") return;
        setinfoPlaceOrder(e.target.value);
        if (e.target.value === 'MARKET') {
            setsellOrderPrice(undefined);
            setbuyOrderPrice(undefined);
            setbuyprice(SelectedCoin?.buy_price)
            setsellPrice(SelectedCoin?.sell_price)
        };
    };

    const [skip, setSkip] = useState(0);
    const limit = 10;

    const handlePagination = (action) => {
        if (action === 'prev') {
            if (skip - limit >= 0) {
                setSkip(skip - limit);
            }
        } else if (action === 'next') {
            if (skip + limit < totalDataLength) {
                setSkip(skip + limit);
            }
        } else if (action === 'first') {
            setSkip(0);
        } else if (action === 'last') {
            const lastPageSkip = Math.floor(totalDataLength);
            if (totalDataLength > 10) {
                const data = lastPageSkip - 10
                setSkip(data);
            }
        }
    };

    useEffect(() => {
        if (token) {
            handlePastOrder(baseCurId, quoteCurId, skip, limit)
        }
    }, [skip, limit]);


    const formatTotal = (value) => {
        const finalValue = value?.toFixed(8)?.replace(/\.?0+$/, '');
        let formattedNum = finalValue?.toString();
        let result = formattedNum?.replace(/^0\.0*/, '');
        const decimalPart = finalValue?.toString()?.split('.')[1];
        if (!decimalPart) return finalValue;
        let zeroCount = 0;
        for (let char of decimalPart) {
            if (char === '0') {
                zeroCount++;
            } else {
                break;
            }
        }
        if (zeroCount > 4) {
            return `0.0{${zeroCount}}${result}`;
        }
        if (value < 1e-7) {
            return `0.0{${zeroCount}}${result}`;
        } else {
            return finalValue;
        }
    };

    const toFixed8 = (data) => Math.floor(data * 1000000) / 1000000;
    return (
        <>
            <Helmet>
                <title>{`${SelectedCoin?.base_currency || "BTC"}/${SelectedCoin?.quote_currency || "USDT"} is at ${buyprice ||"0"} - Trade ${SelectedCoin?.base_currency_fullname} with Low Fee and Securely with CVTrade.io`}</title>
                <meta name="description" content={`Trade ${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency} on CVTrade with real-time prices and low fees. Experience seamless, secure, and fast crypto transactions for Bitcoin and USDT pairings.`} />
            </Helmet>
           
            {announcementBar && <div className={`announce_bar ${(announcementBar) ? " " : 'd-none'}`} >
                <div className="container-fluid" >
                    <div className="d-flex align-items-center gap-2" >
                        <i className="ri-megaphone-line me-2"></i>
                        <div className="mb-0 p_data" >
                            <Swiper
                                loop={true}

                                autoplay={{
                                    delay: 3500,
                                    pauseOnMouseEnter: true
                                }}
                                direction="vertical"
                                modules={[Autoplay, Pagination, Navigation]}>
                                {notificationList?.map((item) => {
                                    return (
                                        <SwiperSlide key={item?._id} >
                                            <div className="slidess" >
                                                <div className="newsTicker_swipeItem__7WaHs" >
                                                    <span className="newsTicker_swipeText__w0lw1"> {item?.title} </span>
                                                    {item?.link && <a href={item?.link} target="_blank" className="btn-link  cursor-pointer">View More&gt;&gt;</a>}
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                        <button className="close_btn " onClick={() => { setannouncementBar(false) }} >
                            <i className="ri-close-line"></i>
                        </button>
                    </div>
                </div>
            </div>
            }
            <div className="trade-wrapper spot pb-3 ">
                <div className="  container-fluid">
                    <div className="row g-1 g-md-2" >
                        <div className="col-12 col-lg-12 col-xl-7  col-xxl-7 " >
                            <div className={`spotLists ${showCoinList === true ? 'active' : ""}`} >
                                <div className=" trade_tabs buy_sell_cards   ">
                                    <ul className="nav custom-tabs nav_order">
                                        <li className="all-tab">
                                            <a className="active" data-bs-toggle="tab" href="#tab_all" onClick={() => setcoinFilter('ALL')}> All </a>
                                        </li>
                                        <li className="cvt-tab">
                                            <a data-bs-toggle="tab" href="#tab_all" onClick={() => setcoinFilter('CVT')}>CVT</a>
                                        </li>
                                        <li className="usdt-tab">
                                            <a data-bs-toggle="tab" href="#tab_all" onClick={() => setcoinFilter('USDT')}>USDT</a>
                                        </li>
                                        {token &&
                                            <li className="favt-tab">
                                                <a data-bs-toggle="tab" href="#tab_fav" onClick={() => setcoinFilter('FAV')}>FAV</a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                                <div className="spot-list-search">
                                    <div className="ivu-input" >
                                        <i className="ri-search-2-line"></i>
                                        <input autoComplete="off" spellCheck="false" type="search" placeholder="Search" className=""
                                            onChange={(e) => { setsearch(e.target.value) }} value={search} />
                                    </div>
                                </div>
                                <div className="price_card">
                                    <div className="price_card_head">
                                        <div>Pair</div>
                                        <div>Price</div>
                                        <div>24H%</div>
                                    </div>
                                    <div className="price_card_body tab-content scroll_y" style={{ cursor: "pointer" }}>
                                        <div className="tab-pane px-0" id="tab_fav" >
                                            {CoinPairDetails ? CoinPairDetails?.map((data, index) => {
                                                return (
                                                    favCoins.includes(data?._id) && <div className={`price_item_value ${SelectedCoin?.base_currency === data?.base_currency && SelectedCoin?.quote_currency === data?.quote_currency ? 'active' : ''}`} key={index}>
                                                        <span className="d-flex align-items-center gap-1">
                                                            {token && <i className={favCoins.includes(data?._id) ? "ri ri-star-fill ri-xl" : "ri ri-star-line ri-xl"} onClick={() => { handleAddFav(data?._id) }} >
                                                            </i>}
                                                            <dt className="td_div" onClick={() => handleSelectCoin(data)}>
                                                                <img alt="" src={ApiConfig.baseUrl + data?.icon_path} className="img-fluid  me-1 " />
                                                                {`${data?.base_currency}/${data?.quote_currency}`}
                                                            </dt>
                                                        </span>
                                                        <span className="">{data?.buy_price}</span>
                                                        <span className={data?.change_percentage >= 0 ? "text-green" : "text-danger"}>
                                                            {parseFloat(data?.change_percentage?.toFixed(5))}%
                                                        </span>
                                                    </div>

                                                )
                                            }) : null}
                                        </div>
                                        <div className="tab-pane px-0 active" id="tab_all" >
                                            {CoinPairDetails ?
                                                CoinPairDetails?.map((data, index) => {
                                                    return (
                                                        (coinFilter === 'ALL' ||
                                                            (coinFilter === 'USDT' && (data?.quote_currency === 'USDT' || data?.base_currency === 'USDT')) ||
                                                            (coinFilter === 'CVT' && (data?.quote_currency === 'CVT' || data?.base_currency === 'CVT'))) &&

                                                        <div className={`price_item_value ${SelectedCoin?.base_currency === data?.base_currency && SelectedCoin?.quote_currency === data?.quote_currency ? 'active' : ''}`} key={index}>
                                                            <span className="d-flex align-items-center gap-1">
                                                                {token && <i className={favCoins.includes(data?._id) ? "ri ri-star-fill ri-xl" : "ri ri-star-line  ri-xl"} onClick={() => { handleAddFav(data?._id) }} >
                                                                </i>}
                                                                <dt className="td_div" onClick={() => handleSelectCoin(data)}>
                                                                    <img alt="" src={ApiConfig.baseUrl + data?.icon_path} className="img-fluid  me-1 " />
                                                                    {`${data?.base_currency}/${data?.quote_currency}`}
                                                                </dt>
                                                            </span>
                                                            <span className="">{data?.buy_price}</span>
                                                            <span className={data?.change_percentage >= 0 ? "text-green" : "text-danger"}>{parseFloat(data?.change_percentage?.toFixed(5))}%</span>
                                                        </div>
                                                    );
                                                })
                                                : null}
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div className="trade_card p-2  overflow_card mb-1" >
                                <div className="headline_left__lBBPY">


                                    <div className="headline_left__lBBPY_leftmain d-flex align-items-center">
                                        <div className="headline_symbolName__KfmIZ mt_tr_pr">
                                            <div className="headline_bigName__dspVW " onClick={() => setShowCoinList(!showCoinList)} ><i className="faaa  ri-menu-add-line"></i></div>
                                            <div>
                                                <div className="headline_bigName__dspVW ">
                                                    <h1>{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</h1>
                                                </div>
                                                <div className="headline_etfDisplay__P4Hdv"><span>{SelectedCoin?.base_currency_fullname}</span></div>
                                            </div>
                                        </div>
                                        <div className="headline_leftItem__7BFYq headline_latestPrice__AYXu0">
                                            <div>
                                                <span className="headline_title__x1csO">{parseFloat(buyprice?.toFixed(8))} </span>
                                                <span className={`headline_change__tGyxo  ms-2 ${priceChange >= 0 ? "text-green" : "text-danger"}`} >{parseFloat(parseFloat(priceChange?.toFixed(priceDecimal)))}%</span>
                                            </div>
                                            <div className="headline_etfDisplay__P4Hdv"><span>$ {parseFloat(buyprice?.toFixed(8))} </span></div>
                                        </div>
                                    </div>

                                    <div className="scroll-subtabs_scrollSubInfo__T5nZF headline_left__lBBPY_rightmain" >
                                        <div className="scroll-subtabs_tabs__Prom8" >
                                            <div className="scroll-subtabs_subMarketWrap__XVmHp" >
                                                <div className="headline_extendInfoWrapper__dooIS">
                                                    <div className="headline_leftItem__7BFYq">
                                                        <div className="headline_withBorder__a6ZD2 ">24h High</div>
                                                        <div className="headline_title__x1csO text-success"  >
                                                            {parseFloat(priceHigh?.toFixed(2))}
                                                        </div>
                                                    </div>
                                                    <div className="headline_leftItem__7BFYq">
                                                        <div className="headline_withBorder__a6ZD2 ">24h Low</div>
                                                        <div className="headline_title__x1csO text-danger" >
                                                            {parseFloat(priceLow?.toFixed(2))}
                                                        </div>
                                                    </div>
                                                    <div className="headline_leftItem__7BFYq">
                                                        <div className="headline_withBorder__a6ZD2">24h Volume ({SelectedCoin?.base_currency})</div>
                                                        <div className="headline_title__x1csO">{parseFloat(volume?.toFixed(2))}</div>
                                                    </div>
                                                    {/* <div className="headline_leftItem__7BFYq">
                                                        <div className="headline_withBorder__a6ZD2">24h Change </div>
                                                        <div className="headline_title__x1csO">{parseFloat(changesHour?.toFixed(2)) || 0.00}</div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="trade_card trade_chart p-0"  >
                                {!SelectedCoin?.base_currency ?
                                    <div style={{ width: '100%', height: '467px' }}>
                                        <div className="loading-wave" style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                            <div className="loading-bar"></div>
                                        </div>
                                    </div> :
                                    <TVChartContainer symbol={`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`} />
                                }
                            </div>
                        </div>

                        <div className="col-12 col-lg-12 col-xl-5 col-xxl-5" >
                            <div className="row g-1 g-md-2 px-1 px-md-0" >
                                <div className="col-6" >
                                    <div className="trade_card " >
                                        <div class="treade_card_header"><div class="card_header_title active">Order Book</div></div>
                                        <div className=" trade_tabs buy_sell_cards  buy_sell_row d-flex-between">
                                            <ul className="nav custom-tabs nav_order">
                                                <li className="fav-tab"><a className="active" data-bs-toggle="tab" href="#all_orders"> <img alt='' src="/images/order_1.svg" width="22" height="11" />   </a></li>

                                                <li className="usdt-tab"><a data-bs-toggle="tab" href="#buy_orders"> <img alt='' src="/images/order_2.svg" width="22" height="11" />  </a></li>
                                                <li className="btc-tab"><a data-bs-toggle="tab" href="#sell_orders" className="me-0"> <img alt='' src="/images/order_3.svg" width="22" height="11" /> </a></li>
                                            </ul>
                                            <div className='num-div' >
                                                <select className="form-select num-select p-0 input-select cursor-pointer" aria-label="Default select example" onClick={(e) => { setpriceDecimal(e.target.value) }}>
                                                    <option value={8}>
                                                        0.00000001
                                                    </option>
                                                    <option value={7}>
                                                        0.0000001
                                                    </option>
                                                    <option value={6}>
                                                        0.000001
                                                    </option>
                                                    <option value={5}>
                                                        0.00001
                                                    </option>
                                                    <option value={4}>
                                                        0.0001
                                                    </option>
                                                    <option value={3}>
                                                        0.001
                                                    </option>
                                                    <option value={2}>
                                                        0.01
                                                    </option>
                                                    <option value={1}>
                                                        0.1
                                                    </option>

                                                </select>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                        <div className="tab-content buy_sell_row_price" >
                                            <div className="tab-pane fade px-0  active show" id="all_orders">
                                                <div className="price_card">
                                                    <div className="price_card_head">
                                                        <div className="ps-0" >Price({SelectedCoin?.quote_currency})</div>
                                                        <div>Quantity({SelectedCoin?.base_currency})</div>
                                                        <div>Total({SelectedCoin?.quote_currency})</div>
                                                    </div>
                                                    <div className="price_card_body scroll_y scroll_y_reverse" style={{ cursor: "pointer" }} >
                                                        {(SellOrders?.length > 0 && !loader) ? SellOrders?.map((data, index) => {
                                                            return (
                                                                <div className="price_item_value" key={index} onClick={() => { setbuyamount(data?.remaining?.toFixed(8)); infoPlaceOrder !== 'MARKET' && setbuyOrderPrice(data?.price) }}>
                                                                    <span className="d-flex align-items-center text-danger "> {parseFloat((data?.price)?.toFixed(priceDecimal))}</span>
                                                                    <span className=""> {parseFloat((data?.remaining)?.toFixed(priceDecimal))}</span>
                                                                    <span className="text-danger"> {parseFloat((data?.price * data?.remaining)?.toFixed(priceDecimal))}</span>
                                                                </div>
                                                            )
                                                        }) : loader ?
                                                            <div className="favouriteData">
                                                                <div style={{ width: '100%', height: '467px' }}>
                                                                    <div className="loading-wave" style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                                                        <div className="loading-bar"></div>
                                                                        <div className="loading-bar"></div>
                                                                        <div className="loading-bar"></div>
                                                                        <div className="loading-bar"></div>
                                                                    </div>
                                                                </div>
                                                            </div> : <p className="text-center no-data h-100 mb-0" >
                                                                <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                                                No data Available
                                                            </p>
                                                        }
                                                    </div>
                                                    <div className="mrkt_trde_tab justify-content-center" >
                                                        <b className={priceChange >= 0 ? "text-green" : "text-danger"} >
                                                            {parseFloat(buyprice?.toFixed(8))}
                                                        </b>
                                                        <i className={priceChange >= 0 ? 'ri-arrow-up-line ri-xl mx-3 text-green' : 'ri-arrow-down-line ri-xl mx-3 text-danger'}></i>
                                                        <span>{parseFloat(priceChange?.toFixed(priceDecimal))}%</span>
                                                    </div>
                                                    <div className="price_card_body scroll_y" style={{ cursor: "pointer" }} >
                                                        {(BuyOrders?.length > 0 && !loader) ?
                                                            BuyOrders?.map((data, index) => {
                                                                return (
                                                                    <div className="price_item_value" key={index} onClick={() => { setsellAmount(data?.remaining?.toFixed(8)); infoPlaceOrder !== 'MARKET' && setsellOrderPrice(data?.price) }}>
                                                                        <span className={"text-green d-flex lign-items-center"} >{parseFloat((data?.price)?.toFixed(priceDecimal))}</span>
                                                                        <span className="">{parseFloat((data?.remaining)?.toFixed(priceDecimal))}</span>
                                                                        <span className="text-green">{parseFloat((data?.price * data?.remaining)?.toFixed(priceDecimal))}</span>
                                                                    </div>
                                                                )
                                                            }) :
                                                            loader ?
                                                                <div className="favouriteData">
                                                                    <div style={{ width: '100%', height: '467px' }}>
                                                                        <div className="loading-wave" style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                                                                            <div className="loading-bar"></div>
                                                                            <div className="loading-bar"></div>
                                                                            <div className="loading-bar"></div>
                                                                            <div className="loading-bar"></div>
                                                                        </div>
                                                                    </div>
                                                                </div> : <p className="text-center no-data h-100 mb-0" >
                                                                    <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                                                                    No data Available
                                                                </p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade px-0 " id="buy_orders">
                                                <div className="price_card">
                                                    <div className="price_card_head">
                                                        <div className="ps-0" >Price({SelectedCoin?.quote_currency})</div>
                                                        <div>Quantity({SelectedCoin?.base_currency})</div>
                                                        <div>Total({SelectedCoin?.quote_currency})</div>
                                                    </div>
                                                    <div className="price_card_body scroll_y" style={{ cursor: "pointer" }} >
                                                        {BuyOrders?.length > 0 ?
                                                            BuyOrders?.map((data, index) => {
                                                                return (
                                                                    <div className="price_item_value" key={index} onClick={() => { setsellAmount(data?.remaining?.toFixed(8)); infoPlaceOrder !== 'MARKET' && setsellOrderPrice(data?.price) }}>
                                                                        <span className={"text-green d-flex lign-items-center"} >{parseFloat((data?.price)?.toFixed(priceDecimal))}</span>
                                                                        <span className="">{parseFloat((data?.remaining)?.toFixed(priceDecimal))}</span>
                                                                        <span className="text-green">{parseFloat((data?.price * data?.remaining)?.toFixed(priceDecimal))}</span>
                                                                    </div>
                                                                )
                                                            }) :
                                                            <p className="text-center" style={{ textAlign: "center" }}>
                                                                No data Available</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade px-0 " id="sell_orders">
                                                <div className="price_card">
                                                    <div className="price_card_head">
                                                        <div className="ps-0" >Price({SelectedCoin?.quote_currency})</div>
                                                        <div>Quantity({SelectedCoin?.base_currency})</div>
                                                        <div>Total({SelectedCoin?.quote_currency})
                                                        </div>
                                                    </div>
                                                    <div className="price_card_body scroll_y scroll_y_reverse" style={{ cursor: "pointer" }} >
                                                        {SellOrders?.length > 0 ? SellOrders?.map((data, index) => {
                                                            return (
                                                                <div className="price_item_value" key={index} onClick={() => { setbuyamount(data?.remaining?.toFixed(8)); infoPlaceOrder !== 'MARKET' && setbuyOrderPrice(data?.price) }}>
                                                                    <span className="d-flex align-items-center text-danger "> {parseFloat((data?.price)?.toFixed(priceDecimal))}</span>
                                                                    <span className=""> {parseFloat((data?.remaining)?.toFixed(priceDecimal))}</span>
                                                                    <span className="text-danger"> {parseFloat((data?.price * data?.remaining)?.toFixed(priceDecimal))}</span>
                                                                </div>
                                                            )
                                                        }) : <p className="text-center" style={{ textAlign: "center" }}>
                                                            No data Available</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    {/* order book */}

                                    <div className="trade_card trade_chart  buysell_card ">
                                        <div class="treade_card_header buysell_heder ">
                                            <ul class="nav custom-tabs   ">
                                                <li class="buysell-tab buy-tab"><a class="active" data-bs-toggle="tab" href="#buytab"> Buy </a></li>
                                                <li class="  sell-tab"><a data-bs-toggle="tab" href="#selltab"> Sell </a></li>
                                            </ul>
                                        </div>
                                        <div className=" p-2 p-md-3" >
                                            <div className="col-md-12 mb-3">
                                                <div className="spot_limit  " >
                                                    <select className=" mb-0 form-select-sm" name="infoPlaceOrder" onChange={handleOrderType} value={infoPlaceOrder}>
                                                        <option value="LIMIT">Limit</option>
                                                        <option value="MARKET">Market</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="tab-content" >
                                                <div className="tab-pane show active  px-0" id="buytab" >
                                                    <form action="" className="buysellform data-buy">
                                                        <div class="actions_balance__kTHO0">
                                                            <span class="actions_primaryText__ufKT0"> Available Balance: </span>
                                                            <div>
                                                                <span> {BuyCoinBal ? BuyCoinBal?.toFixed(9) : 0}</span>
                                                                <span class="text-muted ms-1">{SelectedCoin?.quote_currency}</span>
                                                                <Link class="actions_deposit__Ydutk" to={token ? '/funds_details' : '/login'}>
                                                                    <i class="ri-add-circle-fill"></i>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="form-group  mb-3" >
                                                            <label>Price</label>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" disabled={infoPlaceOrder === 'MARKET'} value={buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : formatTotal(buyprice)}

                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        const regex = /^\d{0,8}(\.\d{0,8})?$/;
                                                                        if (regex.test(value) || value === '') {
                                                                            setbuyOrderPrice(value);
                                                                        }
                                                                    }}

                                                                />
                                                                <span className="input-group-text text-start"><small>  {SelectedCoin?.quote_currency}</small></span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group  mb-3" >
                                                            <label>Amount</label>
                                                            <div className="input-group ">
                                                                <input type="number" aria-invalid="true" className="form-control" value={buyamount}
                                                                    onChange={(e) => {
                                                                        if (/^\d{0,8}(\.\d{0,8})?$/.test(e.target.value) || e.target.value === '') {
                                                                            setbuyamount(e.target.value)
                                                                        }
                                                                    }}
                                                                />
                                                                <span className="input-group-text text-start"><small> {SelectedCoin?.base_currency}</small></span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group  mb-3" >
                                                            <div className="input-group  ">
                                                                <input type="text" className="form-control" value={
                                                                    (buyOrderPrice !== undefined && buyOrderPrice && buyamount) ? formatTotal(+buyOrderPrice * +buyamount) :
                                                                        (buyprice && buyamount) ? formatTotal(+buyprice * +buyamount) : formatTotal(0)
                                                                } />
                                                                <span className="input-group-text text-start"><small>Total</small></span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group" >
                                                            <div className="btn-group btn-group-mini  mb-3" role="group" aria-label="Basic radio toggle button group">
                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio125" autoComplete="off" />
                                                                <label className="btn btn-outline-success" htmlFor="btnradio125" onClick={() => { setbuyamount(toFixed8(((BuyCoinBal / 100) * 25) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }} >25%</label>
                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" />
                                                                <label className="btn btn-outline-success" htmlFor="btnradio250" onClick={() => { setbuyamount(toFixed8(((BuyCoinBal / 100) * 50) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }}>50%</label>
                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" />
                                                                <label className="btn btn-outline-success" htmlFor="btnradio375" onClick={() => { setbuyamount(toFixed8(((BuyCoinBal / 100) * 75) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }}>75%</label>
                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio3100" autoComplete="off" />
                                                                <label className="btn btn-outline-success" htmlFor="btnradio3100" onClick={() => { setbuyamount(toFixed8(((BuyCoinBal)) / (buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice))) }}>100%</label>
                                                            </div>
                                                        </div>
                                                        <>


                                                            {token ?
                                                                KycStatus === 0 || KycStatus == 1 || KycStatus == 3 ?
                                                                    <Link to={KycStatus == 1 ? "" : '/kyc'
                                                                    } className={`btn custom-btn w-100 btn-mini w-100 my-3 my-md-0 ${KycStatus === 1 ? "btn-warning" : KycStatus === 0 ? "btn-warning" : "btn-danger"}`}>
                                                                        {KycStatus == 1 ? "Verification Pending" : KycStatus == 0 ? "Submit Kyc" : "Kyc Rejected Verify Again"}
                                                                    </Link> :
                                                                    <button type='button' className="btn custom-btn btn-success btn-mini  w-100 my-3 my-md-0"
                                                                        onClick={() => handleOrderPlace(infoPlaceOrder, buyOrderPrice !== undefined || buyOrderPrice ? buyOrderPrice : buyprice, buyamount, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'BUY')}>
                                                                        Buy {SelectedCoin?.base_currency}
                                                                    </button>
                                                                :
                                                                <div className="order-btns" >
                                                                    <Link to='/login' className="btn custom-btn btn-primary  btn-mini w-100   ">
                                                                        Login
                                                                    </Link>
                                                                    <Link to='/signup' className="btn  custom-border-btn  custom-border-btn-white  btn-mini w-100  ">
                                                                        Register
                                                                    </Link>
                                                                </div>
                                                            }


                                                        </>
                                                    </form>
                                                </div>
                                                <div class="tab-pane px-0" id="selltab" >
                                                    <form action="" className="buysellform data-sell">
                                                        <div class="actions_balance__kTHO0">
                                                            <span class="actions_primaryText__ufKT0"> Available Balance: </span>
                                                            <div>
                                                                <span>{SellCoinBal ? SellCoinBal?.toFixed(9) : 0} {" "}
                                                                </span>
                                                                <span class="text-muted ms-1">{SelectedCoin?.base_currency}</span>
                                                                <Link class="actions_deposit__Ydutk" to={token ? '/funds_details' : '/login'}>
                                                                    <i class="ri-add-circle-fill"></i>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="form-group  mb-3" >
                                                            <label>Price</label>
                                                            <div className="input-group ">
                                                                <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)" value={sellOrderPrice !== undefined || sellOrderPrice ? sellOrderPrice : formatTotal(sellPrice)}

                                                                    onChange={(e) => {
                                                                        if (/^\d{0,8}(\.\d{0,8})?$/.test(e.target.value) || e.target.value === '') {
                                                                            setsellOrderPrice(e.target.value)
                                                                        }
                                                                    }}

                                                                    disabled={infoPlaceOrder === 'MARKET'} />
                                                                <span className="input-group-text text-start" ><small> {SelectedCoin?.quote_currency}</small></span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group  mb-3" >
                                                            <label>Amount</label>
                                                            <div className="input-group ">
                                                                <input type="text" aria-invalid="true" className="form-control" aria-label="Amount (to the nearest dollar)" value={sellAmount}
                                                                    onChange={(e) => {
                                                                        if (/^\d{0,8}(\.\d{0,8})?$/.test(e.target.value) || e.target.value === '') {
                                                                            setsellAmount(e.target.value)
                                                                        }
                                                                    }}
                                                                />
                                                                <span className="input-group-text text-start"><small>{SelectedCoin?.base_currency}</small></span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group  mb-3" >
                                                            <div className="input-group  ">
                                                                <input type="text" className="form-control" aria-label="Amount (to the nearest dollar)" value=
                                                                    {(sellOrderPrice !== undefined && sellOrderPrice && sellAmount) ? formatTotal(+sellOrderPrice * +sellAmount) :
                                                                        (sellPrice && sellAmount) ? formatTotal(+sellPrice * +sellAmount) : formatTotal(0)}

                                                                />
                                                                <span className="input-group-text text-start"><small>Total</small></span>
                                                            </div>
                                                        </div>
                                                        <div className="form-group" >
                                                            <div className="btn-group btn-group-mini   mb-3 " role="group" aria-label="Basic radio toggle button group">
                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio15" autoComplete="off" />
                                                                <label className="btn btn-outline-danger" htmlFor="btnradio15" onClick={() => { setsellAmount(toFixed8(SellCoinBal / 100) * 25) }}>25%</label>
                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio20" autoComplete="off" />
                                                                <label className="btn btn-outline-danger" htmlFor="btnradio20" onClick={() => { setsellAmount(toFixed8((SellCoinBal / 100) * 50)) }}>50%</label>
                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio35" autoComplete="off" />
                                                                <label className="btn btn-outline-danger" htmlFor="btnradio35" onClick={() => { setsellAmount(toFixed8((SellCoinBal / 100) * 75)) }}>75%</label>
                                                                <input type="radio" className="btn-check" name="btnradio" id="btnradio300" autoComplete="off" />
                                                                <label className="btn btn-outline-danger" htmlFor="btnradio300" onClick={() => { setsellAmount(toFixed8(SellCoinBal)) }}>100%</label>
                                                            </div>
                                                        </div>

                                                        <>

                                                            {token ?
                                                                KycStatus === 0 || KycStatus == 1 || KycStatus == 3 ?
                                                                    <Link to={KycStatus == 1 ? "" : '/kyc'
                                                                    } className={`btn custom-btn w-100 btn-mini w-100 my-3 my-md-0 ${KycStatus === 1 ? "btn-warning" : KycStatus === 0 ? "btn-warning" : "btn-danger"}`}>
                                                                        {KycStatus == 1 ? "Verification Pending" : KycStatus == 0 ? "Submit Kyc" : "Kyc Rejected Verify Again"}
                                                                    </Link> :
                                                                    <button type='button' className="btn custom-btn btn-danger btn-mini w-100 my-3 my-md-0"
                                                                        onClick={() => handleOrderPlace(infoPlaceOrder, sellOrderPrice !== undefined || sellOrderPrice ? sellOrderPrice : sellPrice, sellAmount, SelectedCoin?.base_currency_id, SelectedCoin?.quote_currency_id, 'SELL')} disabled={!sellAmount || !token || sellAmount === '0'}>
                                                                        Sell {SelectedCoin?.base_currency}
                                                                    </button>
                                                                :
                                                                <div className="order-btns" >
                                                                    <Link to='/login' className="btn custom-btn btn-primary  btn-mini w-100   ">
                                                                        Login
                                                                    </Link>
                                                                    <Link to='/signup' className="btn  custom-border-btn  custom-border-btn-white  btn-mini w-100  ">
                                                                        Register
                                                                    </Link>
                                                                </div>
                                                            }

                                                        </>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6 col-xl-9 col-xxl-9" >
                            <div className="trade_card mb-0">
                                <div className="d-flex-between trade_tabs w-100 align-items-center buy_sell_cards  pb-0 ">
                                    <ul className="nav custom-tabs order_his_tabs ">
                                        <li className="buysell-tab">
                                            <a className="active" data-bs-toggle="tab" href="#open_orders" onClick={() => { setActivetabpast(false);; setShowDeleteAllOrder(true) }}>Open Orders</a>
                                        </li>
                                        <li className="buysell-tab">
                                            <a data-bs-toggle="tab" href="#past_orders" onClick={() => { token && handlePastOrder(baseCurId, quoteCurId, skip, limit); setActivetabpast(true); setShowDeleteAllOrder(false) }}>Past Orders </a></li>
                                    </ul>
                                    {/* {token && openOrders?.length > 0 && showDeleteAllOrder ?

                                        <button className={`btn custom-btn btn-danger btn-sm ${showDeleteAllOrder === true ? '' : 'd-none'}`} type="button" onClick={() => handleCancelAllOrder()} >Cancel All Orders</button>
                                        :
                                        ""
                                    } */}
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane  show active  px-0" id="open_orders">
                                        <div className="scroll_y" style={{ height: '353px' }}>
                                            <div className='table-responsive'>
                                                <table className="table table_home ">
                                                    <thead>
                                                        <tr>
                                                            <th>Trading Pair</th>
                                                            <th> Date</th>
                                                            <th> Type</th>
                                                            <th> <div className="num-div justify-content-start">
                                                                <select className=" form-select num-select p-0 input-select cursor-pointer" name="" value={orderType} onChange={(e) => { setorderType(e.target.value) }}>
                                                                    <option value="All">All</option>
                                                                    <option value="BUY">Buy</option>
                                                                    <option value="SELL">Sell</option>
                                                                </select>
                                                            </div></th>
                                                            <th> Price</th>
                                                            <th>Amount</th>
                                                            <th>Remaining</th>
                                                            <th>Filled</th>
                                                            <th>Total</th>
                                                            <th> Action </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {openOrders?.length > 0 ? openOrders.map((item, index) =>
                                                            (orderType === item?.side || orderType === 'All') &&
                                                            <tr key={index}>
                                                                <td>{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</td>
                                                                <td>
                                                                    <small>
                                                                        <div className="c_view justify-content-start" >
                                                                            <span>{moment(item?.updatedAt).format("DD/MM/YYYY  ")}
                                                                                <small>{moment(item?.updatedAt).format("hh:mm")}</small>
                                                                            </span>
                                                                        </div>
                                                                    </small>
                                                                </td>
                                                                <td>{item?.order_type}</td>
                                                                <td>{item?.side}</td>
                                                                <td>{item?.price?.toFixed(8)}</td>
                                                                <td>{item?.quantity?.toFixed(8)}</td>
                                                                <td>{item?.remaining?.toFixed(8)}</td>
                                                                <td>{item?.filled?.toFixed(8)}</td>
                                                                <td>{(item?.price * item?.quantity)?.toFixed(8)}</td>
                                                                <td>
                                                                    <button className="btn text-danger btn-sm btn-icon" type="button" onClick={() => { cancelOrder(item?._id) }}><i className="ri-delete-bin-6-line pr-0"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ) : <tr rowSpan="5">
                                                            <td colSpan="12">
                                                                <div className="favouriteData">
                                                                    <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                                                                    <p>No Data Available</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="tab-pane  show px-0" id="past_orders">
                                        <div className="scroll_y" style={{ height: '353px' }} >
                                            <div className='table-responsive'>
                                                <table className="table table_home ">
                                                    <thead>
                                                        <tr>
                                                            <th>Trading Pair</th>
                                                            <th>Date</th>
                                                            <th> <div className="num-div justify-content-start">
                                                                <select className=" form-select num-select p-0 input-select cursor-pointer" value={pastOrderType} onChange={(e) => { setpastOrderType(e.target.value) }}>
                                                                    <option value="All">All</option>
                                                                    <option value="BUY">Buy</option>
                                                                    <option value="SELL">Sell</option>
                                                                </select>
                                                            </div></th>
                                                            <th>Average Filled Price</th>
                                                            <th>Executed</th>
                                                            <th>Total</th>
                                                            <th>Order Type</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pastOrders?.length > 0 ? pastOrders.map((item, index) =>
                                                            <tr key={index}>
                                                                <td>{`${SelectedCoin?.base_currency}/${SelectedCoin?.quote_currency}`}</td>
                                                                <td>
                                                                    <td>
                                                                        <small>
                                                                            <div className="c_view justify-content-start" >
                                                                                <span>{moment(item?.updatedAt).format("DD/MM/YYYY  ")}
                                                                                    <small>{moment(item?.updatedAt).format("hh:mm")}</small>
                                                                                </span>
                                                                            </div>
                                                                        </small>
                                                                    </td></td>
                                                                <td>{item?.side}</td>
                                                                <td>{item?.price?.toFixed(8)}</td>
                                                                <td>{item?.quantity?.toFixed(8)}</td>
                                                                <td>{(item?.quantity * item?.price)?.toFixed(8)}</td>
                                                                <td>{item?.order_type}</td>
                                                                <td>{item?.status === 'FILLED' ? 'EXECUTED' : item?.status}</td>
                                                            </tr>
                                                        ) : <tr rowSpan="5">
                                                            <td colSpan="12">
                                                                <div className="favouriteData">
                                                                    <img src="/images/no-data.svg" className="img-fluid" width="96" height="96" alt="" />
                                                                    <p>No Data Available</p>
                                                                </div>
                                                            </td>
                                                        </tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    {pastOrders?.length > 0 && activetabpast ?
                                        < div className="hVPalX gap-2" >
                                            <span className="" >{skip + 1}-{Math.min(skip + limit, totalDataLength)} of {totalDataLength}</span>
                                            <div className="sc-eAKtBH gVtWSU">
                                                <button type="button" aria-label="First Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('first')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                                        <path fill="none" d="M24 24H0V0h24v24z"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('prev')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>

                                                <button type="button" aria-label="Next Page" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('next')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                    </svg>
                                                </button>
                                                <button type="button" className="sc-gjLLEI kuPCgf" onClick={() => handlePagination('last')}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12  col-lg-6 col-xl-3 col-xxl-3" >
                            <div className="trade_card">
                                <div className="price_card">
                                    <div class="treade_card_header">
                                        <div class="card_header_title active">Trade History </div>
                                    </div>
                                    <div className="price_card_head">
                                        <div className="ps-0">Price({SelectedCoin?.quote_currency})</div>
                                        <div >Quantity({SelectedCoin?.base_currency})</div>
                                        <div >Time</div>
                                    </div>
                                    <div className="price_card_body scroll_y scroll_y_mt" style={{ cursor: "pointer" }}>
                                        {RecentTrade?.length > 0 ?
                                            RecentTrade.map((item, index) =>
                                                <div className="price_item_value" key={index}>
                                                    <span className={item?.side === "BUY" ? "text-green d-flex align-items-center" : "text-danger d-flex align-items-center"}> {parseFloat((item?.price)?.toFixed(priceDecimal))}</span>
                                                    <span>{parseFloat((item?.quantity)?.toFixed(priceDecimal))}</span>
                                                    <span> {item.time?.split(',')[1].trim()}</span>
                                                </div>
                                            ) :
                                            <div className="favouriteData">
                                                <p>No Data Available</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
        </>
    )
}

export default Trade
