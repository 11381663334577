// const appUrl = "http://localhost:4003";
const appUrl = "https://backend.cvtrade.io";
// const appUrl = "http://103.110.127.63:4003";
// const appUrl = "http://103.110.127.63:4003";

export const deployedUrl = `${window.origin}/`

export const ApiConfig = {
  // =========EndPoints==========
  loginwithGoogle: 'user/third-party-login',
  login: "login",
  register: "register",
  getOtp: "send-otp",
  getcode: "verify-otp",
  forgotpassword: "forgot_password",
  activityLogs: "get_logs",
  setCurrency: "currency-preference",
  userfunds: "user-wallet",
  userP2pWallet: "p2p-wallet",
  walletHistory: 'wallet-history',
  generateAddress: "generate-address",
  estimatedPortfolio: "estimated-portfolio",
  withdrawalcurrencypath: 'withdrawal',
  identity: "submit-kyc",
  categoryList: 'coin-category-list',
  favoriteCoin: 'favorite-coin',
  favoriteList: 'favorite-list',
  setSecurity: "change_password",
  updateSettings: "edit-profile",
  placeOrder: 'place-order',
  pastOrder: 'past-order',
  cancelOrder: 'cancel-order',
  coinDetails: 'coin-details',
  update2fa: "enable-2fa",
  googleAuth: "generate-google-qr",
  getHistoricalData: 'historical-data',
  getDetails: "profile",
  depositInr: 'deposit_inr',
  withdrawalInr: 'withdraw_inr',
  adminBankDetails: 'get_user_bank_details',
  userBankDetails: 'get_user_bank_details',
  editBank: 'user_bank_details',
  sendkginotp: 'send-kgin-otp',
  verifykginotp: 'verify-kgin-otp',
  notifiactionlist: 'notification-list',
  reffercode: 'user_refer_code',
  getPairs: 'get-pairs',
  convertToken: "v1/swapping/convertToken",
  swapToken: "v1/swapping/swapToken",
  coinListedDetails: "coinListedDetails",
  partnerships: "signUp",
  total_refer_count: "total_refer_count",
  refer_list: "user_referral_list",
  referral_balance: "referral_balance",
  joining_balance: "joining_balance",
  coin_details: "coin-details",
  get_top_GL: "get_top_GL",
  get_trending: "get_trending",
  user_cancel_withdrawal: "user_cancel_withdrawal",
  cancel_all_order: "cancel_all_order",
  verify_deposit: "verify-deposit",
  bannerList: "banner_list",
  p2pCoinList: "p2p_coin_list",
  fiatCurrencyList: "fiat_currency_list",
  addBankDetails: "add-bank-details",
  addUpiDetails: "add_user_upi",
  createNewPost: "create_new_post",
  sellOrder: "sell_order",
  buyOrder: "buy_order",
  fetchPaymentMethods: "fetch_payment_methods",
  getCoinList: "get-coin",
  currentPrice: "current_price",
  myAds: "my_ads",
  buyCurrency: "buy_currency",
  myOrders: "my_orders",
  p2pRuntime: "runtime_api",
  buyRequest: "buy_request",
  notifySeller: "merchant_confirmation",
  notifyMerchent: "trader_confirmation",
  addOrderNotification: "add_order_notification",
  notify: "notify_trader",
  editUpiDetails: 'edit_user_upi',
  editBankDetails: 'edit-bank-details',
  rateOrder : "rate_user",
  transferFunds: "swaping_wallets",
  submitTicket: "submit-ticket",
  getUserTickets: "get-user-tickets",
  replyTicket: "reply-ticket",
  swapHistory: "swapping_history",
  getLatestNews: "get_latest_news",
  quick_buy_sell: "quick_buy_sell",
  quickBuySellHistory: "history",
  request_refund: "request_refund",
  getPartnerName: "getPartnerName",

  // ============URLs================

  baseUrl: `${appUrl}/`,
  baseAuth: `${appUrl}/v1/user/`,
  basePartner: `${appUrl}/v1/partnerShip/`,
  baseTrans: `${appUrl}/v1/transaction/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  baseAdmin: `${appUrl}/v1/admin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseP2p: `${appUrl}/v1/p2p/`,
  baseSupport: `${appUrl}/v1/support/`,
  baseSwap: `${appUrl}/v1/qbs/`,
  // ============webSocketUrl================

  webSocketUrl: appUrl,
};
