import React, { useContext, useEffect } from "react";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import { Link, useLocation } from "react-router-dom";
import SettingsPage from "../SettingsPage";
import CurrencyPrefrence from "../CurrencyPrefrence";
import TwofactorPage from "../TwofactorPage";
import SecurityPage from "../SecurityPage";
import ActivitylogPage from "../ActivitylogPage";
import AccountDetails from "../AccountDetails";
import SupportPage from "../Support";
import { ProfileContext } from "../../../context/ProfileProvider";
import { Helmet } from "react-helmet-async";

const ProfilePage = (props) => {


  const location = useLocation();
  const { state } = location;
  const { activeTab, setActiveTab } = useContext(ProfileContext)
  useEffect(() => {
    setActiveTab(activeTab || state || 'settings')
  }, []);


  return (
    <>
    <Helmet>
      <title> CV Trade | The world class new generation crypto asset exchange</title>
    </Helmet>   
      <div className="profile_sections" >
        <div className="container" >
          <div className="row" >
            <div className="col-md-6" >
              <h2 className="mb-0 pb-0">Account Settings</h2>
            </div>
            <div className="col-md-6" >
              <nav className=" ">
                <ol className="breadcrumb justify-content-end mb-0">
                  <li className="breadcrumb-item"><Link onClick={() => setActiveTab('settings')} to="/user_profile">My Account</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {activeTab === 'settings' && 'Profile Settings'}
                    {activeTab === 'update_kgin' && 'Update KGIN'}
                    {activeTab === 'TwofactorPage' && 'Two Factor Authentication'}
                    {activeTab === 'SecurityPage' && 'Security'}
                    {activeTab === 'currencyPrefrence' && 'Currency Prefrence'}
                    {activeTab === 'payment_option' && 'Payment Option'}
                    {activeTab === 'Support' && 'Support'}
                    {activeTab === 'ActivitylogPage' && 'Activity log'}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="per_ac_settings ">
        <div className="container container-custom">
          <div className="row">
            <div className="col-lg-12  mx-auto">
              <div className="row">
                <div className="col-lg-3 col-md-12 col-md-4">
                  <div className=" d-flex align-items-center ">
                    <img alt="" className="img-account-profile rounded-circle me-3" src={props?.userDetails?.profilepicture ? `${ApiConfig.baseUrl + props?.userDetails?.profilepicture}` : 'images/user.png'}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/images/user.png";
                      }}
                    />
                    <div>
                      <h5 className=" pp-name fs-4 mb-1"> {props?.userDetails?.firstName} {props?.userDetails?.lastName}</h5>
                      <div className=" kgin_row " >
                        <small>{props?.userDetails?.emailId}</small>
                      </div>
                    </div>
                  </div>
                  <div className="acc_tabs">
                    <h6 className="acc_title" >Account Settings</h6>
                    <ul className="nav nav-pills flex-column" id="cardTab" role="tablist">
                      <li className="active" onClick={() => setActiveTab('settings')} >
                        <a className={activeTab === "settings" ? "active menu-link  nav-link" : "menu-link  nav-link"} >
                          <i className="ri-user-line me-3"></i>
                          <span className="menu-title"> My Profile </span>
                        </a>
                      </li>

                      <li className="active">
                        <Link className="menu-link  nav-link" id="kyc" to="/kyc">
                          <i className="ri-user-follow-fill me-3"></i>
                          <span className="menu-title"> KYC Verification</span>
                        </Link>
                      </li>
                      <li className={activeTab === "TwofactorPage" ? "active menu-link  nav-link" : "menu-link  nav-link"} onClick={() => setActiveTab('TwofactorPage')}>

                        <i className="ri-shield-user-line me-3 "></i>
                        <span className="menu-title">Two Factor Authentication </span>
                      </li>

                      <li className="active">
                        <Link className="menu-link  nav-link" id="NotificationPage" to="/notification">

                          <i className="ri-notification-3-line me-3"></i>
                          <span className="menu-title">Notification</span>
                        </Link>
                      </li>

                      <li className={activeTab === "SecurityPage" ? "active menu-link  nav-link" : "menu-link  nav-link"} onClick={() => setActiveTab('SecurityPage')}>
                        <i className="ri-lock-password-line me-3"></i>
                        <span className="menu-title">Security</span>
                      </li>


                      <li className={activeTab === "currencyPrefrence" ? "active menu-link  nav-link" : "menu-link  nav-link"} onClick={() => setActiveTab('currencyPrefrence')}>
                        <i className="ri-money-dollar-circle-line me-3"></i>
                        <span className="menu-title">Currency Preference</span>
                      </li>

                      <li className={activeTab === "Support" ? "active menu-link  nav-link" : "menu-link  nav-link"} onClick={() => setActiveTab('Support')}>
                        <i className="ri-coupon-line me-3"></i>
                        <span className="menu-title">Support</span>
                      </li>

                      <li className={activeTab === "ActivitylogPage" ? "active menu-link  nav-link" : "menu-link  nav-link"} onClick={() => setActiveTab('ActivitylogPage')}>

                        <i className="ri-file-list-3-line me-3"></i>
                        <span className="menu-title">Activity logs</span>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="col-lg-9 col-md-12 col-md-4">
                  {activeTab === 'settings' && <SettingsPage userDetails={props?.userDetails} />}
                  {activeTab === 'currencyPrefrence' && <CurrencyPrefrence userDetails={props?.userDetails} />}
                  {activeTab === 'ActivitylogPage' && <ActivitylogPage userDetails={props?.userDetails} />}
                  {activeTab === 'SecurityPage' && <SecurityPage userDetails={props?.userDetails} />}
                  {activeTab === 'TwofactorPage' && <TwofactorPage userDetails={props?.userDetails} />}
                  {activeTab === 'payment_option' && <AccountDetails userDetails={props?.userDetails} />}
                  {activeTab === 'Support' && <SupportPage userDetails={props?.userDetails} />}

                </div>
              </div>
              {/* </div>
            </div> */}
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default ProfilePage
