import React from "react";
import { Helmet } from "react-helmet-async";


const BlogList = () => {

  let blogContent = [
    { 
      
      blogimg: 'images/blog_4.jpeg',
      title: 'Cv Trade - Your Gateway to the Future of Crypto Trading',
      description: "In a world marked by rapid technological evolution, the financial landscape is undergoing a profound transformation",
      tag: 'Ecosystem'
    },
    {
      blogimg: 'images/blog_2.jpg',
      title: 'Cv Trade - Redefining the Crypto Exchange Experience',
      description: "Welcome to Cv Trade, a cutting-edge cryptocurrency exchange that is set to revolutionize the way traders engage with digital assets.. With a focus on innovation, security,",
      tag: 'Blockchain'
    },
    {
      blogimg: 'images/blog_3.jpg',
      title: 'Latest Crypto News: An Overview of Recent Developments in the Cryptocurrency Space',
      description: "The world of cryptocurrencies is constantly evolving, and staying up to date with the latest news and developments is essential for crypto enthusiasts and investors alike.",
      tag: 'P2P'
    },
    {
      blogimg: 'images/blog_1.jpg',
      title: "Embark on Your Crypto Journey with Cv Trade Here's How to Get Started!",
      description: "Are you ready to step into the fascinating world of cryptocurrency trading? Cv Trade is your gateway to this exciting universe,",
      tag: 'Launchpad'
    },
    {
      blogimg: 'images/blog_5.jpg',
      title: "Unveiling Web 3.0: Exploring its Transformative Use Cases",
      description: "The digital landscape is in a constant state of evolution, and the emergence of Web 3.0 marks a monumental shift in the way we interact with the online world.",
      tag: 'Community'
    },

    {
      blogimg: 'images/blog_6.jpg',
      title: "Cv Trade - Redefining the Crypto Exchange Experience",
      description: "Welcome to Cv Trade, a cutting-edge cryptocurrency exchange that is set to revolutionize the way traders engage with digital assets.. With a focus on innovation, security,",
      tag: 'Community'
    },

  ];


  return (
    <>
    <Helmet>
      <title> CV Trade Blog: Stay Updated on Crypto Trends</title>
      <meta name="description" content="Dive into the CV Trade blog for the latest insights and trends in cryptocurrency. Enhance your trading knowledge with expert articles and market analyses" />
    </Helmet>
    <section className="mt-5">
      <div className="container">
        <div className="row" >

          <div className="col-xl-12 col-lg-12 " >
            <div className="row g-4">
              {blogContent?.length > 0 ? blogContent?.map((item, index) => {
                return (
                  <div key={index} className="col-xl-6 col-lg-12">
                    <div className="single-blog h-100 card">
                      <div className="card-body" >
                        <div className="ratio ratio-16x9 thumbnail"  >
                        <img src={item?.blogimg} alt="nft blog" className=""/>
                        </div>
                        <div className="content">
                          <ul className="meta">
                            <li>23 August</li>
                            <li><i class="ri-git-commit-fill"></i></li>
                            <li>{item?.tag}</li>
                            {/* <li className="date"><i className="ri-time-line"></i>4 Hour Ago</li> */}
                          </ul>
                          <h4 className="title">{item?.title}
                            <small>
                              {item?.description}
                            </small>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) : <p className="text-center no-data h-100 mb-0" >
                <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                No blog found
              </p>}
            </div>
          </div>


        </div>
      </div>
    </section>
    </>
  );
};

export default BlogList;
