import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ProfileContext } from "../../../context/ProfileProvider";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponents/CustomAlertMessage";
import { $ } from "react-jquery-plugin";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { Helmet } from "react-helmet-async";


const PTwoP = () => {
  const navigate = useNavigate();
  const Id = sessionStorage.getItem("userId")
  const [coinList, setCoinList] = useState([]);
  const [fiatCurrency, setFiatCurrency] = useState([]);
  const [allData, setAllData] = useState([]);
  const [buyOrders, setbuyOrders] = useState([]);
  const [sellOrders, setSellOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedCoin, setSelectedCoin] = useState('');
  const [payableAmount, setPayableAmount] = useState('');
  const [receivableAmount, setReceivableAmount] = useState('');
  const [checkAgreement, setCheckAgreement] = useState(false);
  const [filterInp, setFilterInp] = useState({ amount: '', fiat: 'ALL', paymentMethod: 'ALL' });
  const [currentTab, setCurrentTab] = useState('BUY');
  const [traderPaymentMethod, setTraderPaymentMethod] = useState()
  const [sellerSelectedPayment, setSellerSelectedPayment] = useState();
  const [buyerSelectedPayment, setBuyerSelectedPayment] = useState();
  const [sellerMethod, setSellerMethod] = useState()
  const [buyerMethod, setBuyerMethod] = useState()
  const paymentMethod = ["UPI", "BANK"]

  const { userDetails } = useContext(ProfileContext);



  const showExpandedRow = (data) => {
    handleResetinput();
    if (data === selectedOrder) {
      setSelectedOrder(null);
    }
    else {
      setSelectedOrder(data);
      setReceivableAmount(data?.volume)
      setPayableAmount(data?.volume * data?.fixed_price)
    }
  };

  const showSellExpandedRow = (data) => {
    handleResetinput();
    if (data === selectedOrder) {
      setSelectedOrder(null);
    }
    else {
      setSelectedOrder(data);
      setPayableAmount(data?.volume)
      setReceivableAmount(data?.volume * data?.fixed_price)
    }
  };

  const resetInput = () => {
    setFilterInp({ amount: '', fiat: 'ALL', paymentMethod: 'ALL' })
  };

  const fetchPaymentMethods = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.fetchPaymentMethods().then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        let verifiedBank = result?.data?.BankDetails?.filter((item) => item?.verified == 1) || []
        let verifiedUPI = result?.data?.UpiDetails?.filter((item) => item?.verified == 1) || []
        setTraderPaymentMethod({ BankDetails: verifiedBank, UpiDetails: verifiedUPI })
      }
    });
  };

  const getBuyOrder = async (type) => {
    LoaderHelper?.loaderStatus(true);
    await AuthService.p2pBuyOrder(type).then(async (result) => {
      LoaderHelper?.loaderStatus(false);
      if (result?.success) {
        let filteredData = result?.data?.filter((item) => item?.postAd_user !== Id) || []
        setbuyOrders(filteredData);
        setAllData(filteredData);
      }
    });
  };

  const getSellOrder = async (type) => {
    LoaderHelper?.loaderStatus(true);
    await AuthService.p2pSellOrder(type).then(async (result) => {
      LoaderHelper?.loaderStatus(false);
      if (result?.success) {
        let filteredData = result?.data?.filter((item) => item?.postAd_user !== Id) || []
        setSellOrders(filteredData)
        setAllData(filteredData)
      }
    });
  };

  const p2pCoinList = async () => {
    await AuthService.p2pCoinList().then(async (result) => {
      if (result?.success) {
        setCoinList(result?.data)
        getBuyOrder(result?.data[0]?.short_name)
        setSelectedCoin(result?.data[0]?.short_name)
      }
    });
  };

  const fiatCurrencyList = async () => {
    await AuthService.fiatCurrencyList().then(async (result) => {
      if (result?.success) {
        setFiatCurrency(result?.data)
      }
    });
  };

  const selectCoin = (item) => {
    setSelectedCoin(item?.short_name);
    if (currentTab === 'BUY') {
      getBuyOrder(item?.short_name)
    } else {
      getSellOrder(item?.short_name)
    }
  };

  const handleTabChange = (type) => {
    setCurrentTab(type)
    setSelectedOrder(null)
    setSelectedCoin(coinList?.[0]?.short_name);
    if (type === "BUY") {
      getBuyOrder(coinList?.[0]?.short_name)
    } else {
      getSellOrder(coinList?.[0]?.short_name)
    }
  };

  const handleInput = (e) => {
    switch (e.target.name) {
      case 'payableAmount':
        setPayableAmount(e.target.value)
        setReceivableAmount(e.target.value / selectedOrder?.fixed_price)
        break;
      case 'receivableAmount':
        setReceivableAmount(e.target.value)
        setPayableAmount(e.target.value * selectedOrder?.fixed_price)
        break;
      case 'filterAmount':
        setFilterInp(data => ({ ...data, amount: e.target.value }));
        break;
      case 'filterFiat':
        setFilterInp(data => ({ ...data, fiat: e.target.value }))
        break;
      case 'filterPayment':
        setFilterInp(data => ({ ...data, paymentMethod: e.target.value }))
        break;

      default:
        break;
    }
  };

  const filterBuyOrders = () => {
    let filteredData = allData?.filter((item) => {
      let payments = item?.payment_method?.map((item) => item?.type);
      return ((item?.quote_short_name === filterInp?.fiat || filterInp?.fiat === "ALL") && (payments?.includes(filterInp?.paymentMethod) || filterInp?.paymentMethod === "ALL") && ((+filterInp?.amount == item?.volume) || filterInp?.amount === ""))
    })
    setbuyOrders(filteredData);
  };

  const filterSellOrders = () => {
    let filteredData = allData?.filter((item) => {
      return ((item?.quote_short_name === filterInp?.fiat || filterInp?.fiat === "ALL") && (item?.payment_method[0]?.type === (filterInp?.paymentMethod) || filterInp?.paymentMethod === "ALL") && ((+filterInp?.amount == item?.volume) || filterInp?.amount === ""))
    })
    setSellOrders(filteredData);
  };

  const handleResetinput = () => {
    setPayableAmount('');
    setReceivableAmount('');
    setCheckAgreement(false);
  };

  const handleOrder = () => {
    buyCurrency()
  };

  const buyCurrency = async () => {
    LoaderHelper?.loaderStatus(true);
    let timestamp = Math.floor(Date.now() / 1000)
    await AuthService.buyCurrency(payableAmount, receivableAmount, selectedOrder?._id, selectedOrder?.postAd_user, timestamp, currentTab == "BUY" ? sellerSelectedPayment : buyerSelectedPayment, currentTab == "BUY" ? (sellerMethod == "P2P_Wallet" ? [{ type: "P2P_Wallet" }] : sellerMethod) : (buyerMethod == "P2P_Wallet" ? [{ type: "P2P_Wallet" }] : buyerMethod)).then(async (result) => {
      LoaderHelper?.loaderStatus(false);
      if (result?.success && result?.data?.order_id) {
        buyP2pPage(result?.data?.order_id);
        alertSuccessMessage('Order placed successfully!!')
      } else {
        alertErrorMessage(result?.message)
      }
    });
  };

  const buyP2pPage = (id) => {
    navigate(`/buyPTwop_details/${id}`)
  };

  const handleFilterModal = () => {
    let modal = document.getElementById("custom_drop_modal")
    if (modal.classList.contains("active")) {
      modal.classList.remove("active")
    } else {
      modal.classList.add("active")
    }
  };


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    p2pCoinList();
    fiatCurrencyList();
    fetchPaymentMethods()
  }, []);

  useEffect(() => {
    if (currentTab === 'SELL') {
      filterSellOrders();
    } else {
      filterBuyOrders();
    }
  }, [filterInp]);


  return (
    <>
      <Helmet>
        <title> CV Trade | The world class new generation crypto asset exchange</title>
      </Helmet>
      <section className="page_banner" >
        <div className="container" >
          <div className="css-wcquo4">
            <h2 className="css-akh7ai">Trade USDT Easily - Buy and Sell Using Your Favorite Payment Method</h2>
          </div>
        </div>
      </section>
      <div className="page_title_bar" >
        <div className="container" >
          <div className="flex_rows" >
            <ul className="nav nav-pills  overflowx_scroll funds_tab mb-0  market_tabs pb-0" >
              <li className="nav-item" id="nav-tab" role="tablist">
                <Link to='/p2p_trading' className=" active nav-link" id="nav-p2p-tab" data-bs-toggle="tab" data-bs-target="#nav-p2p" type="button" role="tab"
                  aria-controls="nav-buy_crypto" aria-selected="false" > <i class="ri-p2p-line me-2 ri-xl "></i> P2P Trading</Link>
              </li>
            </ul>
            <nav className="  text-end" >
              <div className="pt_bar_right" >
                <Link className="pbr_link  d-none d-md-flex  " to="/my_orders" >
                  <i class="ri-list-unordered me-2"></i>
                  Orders
                </Link>
                <div className="dropdown pbr_link_dropdown">
                  <a className=" pbr_link pe-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="ri-more-2-fill me-2"></i>
                    <span className=" d-none d-md-flex "  >More</span>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>  <Link to="/add_post" className="dropdown-item" >  <i class="ri-add-box-line me-2 ri-xl"></i> Add New post</Link>  </li>
                    <li><Link className="dropdown-item" to="/MyPost"> <i class="ri-file-list-3-line me-2  ri-xl"></i> My Posts</Link></li>
                    <li><Link className="dropdown-item " to="/PaymentMethod"> <i className="ri-bank-card-line me-2  ri-xl"></i> Payment Method </Link></li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="pp_filter_bar" >
        <div className="container" >
          <div className="card-filter" >
            <div className="card-body-top card-body-top-riverse_mobile" >
              <div className="body_left" >
                <div className="mrt_row">
                  <div className="scroll-subtabs">
                    {coinList ? coinList?.map((item, index) => {
                      return (
                        <a key={index} href="#/" className={`subtabs_link curser-pointer ${item?.short_name === selectedCoin && 'active'}`} onClick={() => selectCoin(item)}>{item?.short_name}</a>
                      )
                    }) : null}

                  </div>
                </div>
              </div>
              <div className="body_right body_right_w_filter" >
                <ul className="buy_sell_btns nav nav-tabs" id="myTab" role="tablist" >
                  <li className="nav-item" role="presentation">
                    <button className="btn btn-success_inner active" id="p_two_p_buy-tab" data-bs-toggle="tab" data-bs-target="#p_two_p_buy" type="button" role="tab" aria-controls="p_two_p_buy" aria-selected="true" onClick={() => handleTabChange('BUY')} >
                      BUY
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="btn  btn-danger_inner" id="p_two_p_sell-tab" data-bs-toggle="tab" data-bs-target="#p_two_p_sell" type="button" role="tab" aria-controls="p_two_p_sell" aria-selected="false" onClick={() => handleTabChange("SELL")} >
                      Sell
                    </button>
                  </li>
                </ul>
                <div className="mob_filter d-md-none  btn custom-btn custom-border-btn  custom-border-btn-white " onClick={handleFilterModal}>
                  <i className="ri-filter-2-fill"></i>
                </div>
              </div>
            </div>
            <div className="custom_drop_modal active" id="custom_drop_modal" >
              <div className="card-body-bottom" >
                <div className="body_left" >
                  <div className="row gx-1 form_filter align-items-end" >
                    <div className=" col mb-2 mb-md-0 " >
                      <div className="form-group" >
                        <label>Amount</label>
                        <div className="froup_feild mt-1" >
                          <input type="number" className="form-control" placeholder="Enter amount" name="filterAmount" onWheel={(e) => e.target.blur()} onChange={handleInput} value={filterInp?.amount} />

                        </div>
                      </div>
                    </div>
                    <div className=" col mb-2 mb-md-0 " >
                      <div className="form-group" >
                        <label>Fiat</label>
                        <div className="froup_feild mt-1" >
                          <select className="form-control form-select" name="filterFiat" onWheel={(e) => e.target.blur()} onChange={handleInput} value={filterInp?.fiat} >
                            <option hidden>Select Fiat </option>
                            <option value='ALL'> ALL</option>
                            {fiatCurrency ? fiatCurrency?.map((item, index) => <option key={item?.short_name} value={item?.short_name}>{item?.short_name}</option>) : null}

                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col mb-2 mb-md-0 " >
                      <div className="form-group" >
                        <label>Payment</label>
                        <div className="froup_feild mt-1" >
                          <select className="form-control form-select" name="filterPayment" onChange={handleInput} value={filterInp?.paymentMethod}>
                            <option hidden>Select Payment Method </option>
                            <option value='ALL'> ALL</option>
                            {paymentMethod?.map((item) => <option key={item} value={item}>{item}</option>)}

                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body_right d-none d-md-flex" >
                  <button className="btn custom-btn custom-border-btn  custom-border-btn-white btn-sm mt-md-3" type="button" onClick={resetInput}>
                    <span> Clear Filter </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pp_data ant-table" >
        <div className="container" >
          <div className="tab-content" id="myTabContent">
            {/* buy data tab */}
            <div className="tab-pane fade show active" id="p_two_p_buy" role="tabpanel" aria-labelledby="p_two_p_buy-tab">
              <div className="ant-table-content">
                <div className="table" >
                  <div className="ant-table-thead thead d-none d-md-block">
                    <div className="tr" >
                      <div className="ant-table-cell th">Advertiser</div>
                      <div className="ant-table-cell th">Price</div>
                      <div className="ant-table-cell th">Limit</div>
                      <div className="ant-table-cell th">Payment Method</div>
                      <div className="ant-table-cell th">
                        <div className="advertiseList_tradeTitle__ae016">Trade
                          <div className="advertiseList_fee__TDbjz">0 Fee</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tbody ant-table-tbody">
                    {buyOrders?.length > 0 ? buyOrders?.map((item) => {
                      return (
                        <React.Fragment key={item?._id} >
                          <div className=" tr ant-table-row ant-table-row-level-0">
                            <div className=" td ant-table-cell patr_data1 ">
                              <div className="advertiseList_avatarContent__uAQHP">
                                <div className="advertiseList_avatar__rEIGv shadow-soft">💎</div>
                                <div className="advertiseList_adAvatarRight__SbOZP"><span className="advertiseList_nickName__VI9tJ">{item?.post_name}</span>
                                  {item?.successfullOrders == 0 ? null :
                                    <p className="advertiseList_merchantOrder__aO2o_">{item?.successfullOrders}<span className="advertiseList_tradingOrder__DjoPA">successfull orders</span>
                                    </p>}
                                  <div className="advertiseList_firstTrade__Z5OC5">
                                    <p className="advertiseList_merchantOrder__aO2o_">
                                      <span className="text-warning" >
                                        {item?.ratings == 1 ?
                                          <i className="ri-star-fill me-1"></i>
                                          : item?.ratings == 2 ?
                                            <>
                                              <i className="ri-star-fill me-1"></i>
                                              <i className="ri-star-fill me-1"></i>
                                            </>
                                            : item?.ratings == 3 ?
                                              <>
                                                <i className="ri-star-fill me-1"></i>
                                                <i className="ri-star-fill me-1"></i>
                                                <i className="ri-star-fill me-1"></i>
                                              </>
                                              : item?.ratings == 4 ?
                                                <>
                                                  <i className="ri-star-fill me-1"></i>
                                                  <i className="ri-star-fill me-1"></i>
                                                  <i className="ri-star-fill me-1"></i>
                                                  <i className="ri-star-fill me-1"></i>
                                                </>
                                                : item?.ratings == 5 ?
                                                  <>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                  </>
                                                  : "No Rating Available"
                                        }
                                      </span>
                                      {item?.ratings == 0 ? " " : <span className="advertiseList_tradingLine__swVrO"></span>}
                                      <span className="text-warning">{item?.ratings == 0 ? "" : item?.ratings}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" td ant-table-cell patr_data2">
                              <div className="advertiseList_adPrice__fKc28"> <small className="d-md-none" >Price</small> <span className="advertiseList_price__ZL3g1">{item?.fixed_price.toFixed(3)}</span><span className="advertiseList_unit__FXgOK">{item?.quote_short_name}</span></div>
                            </div>
                            <div className=" td ant-table-cell patr_data3">
                              <div className="advertiseList_limit__vjRP2">
                                <div>
                                </div>
                                <div>
                                  <p className="advertiseList_limitInfo__kSoM7">{item?.volume} <span className="advertiseList_expanCoin__FZDED">{item?.base_short_name}</span></p>
                                </div>
                              </div>
                            </div>

                            <div className=" td ant-table-cell patr_data4">
                              <div className="advertiseList_paymentContent__1oElX">
                                {item?.payment_method?.map((item, index) => {
                                  return (
                                    <span key={index} className={`advertiseList_paymentList__IageJ badge-${index % 3 === 0 ? 'success' : index % 3 === 1 ? 'danger' : 'primary'}`}>
                                      <span>
                                        <span >{item?.type}</span>
                                      </span>
                                    </span>
                                  )
                                })}
                              </div>
                            </div>
                            <div className=" td ant-table-cell ant-table-row-expand-icon-cell patr_data5">
                              {userDetails?.kycVerified == "2"
                                ?
                                <button className="btn advertiseList_expandIcon__3jNNF advertiseList_sellExpandIcon__F22Dj" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" type="button" onClick={() => { showExpandedRow(item) }}> Buy  {item?.base_short_name}  </button>
                                :
                                <button className="btn btn-outline-danger" type="button"> Restricted </button>
                              }

                            </div>
                          </div>
                          {selectedOrder === item &&
                            <div className="ant-table-expanded-row ant-table-expanded-row-level-1 collapse show shadow-soft" id="collapseExample" >
                              <div className="td w-100 ant-table-cell">
                                <div className="advertiseList_expandableContent___v_pW">
                                  <div className="advertiseList_placeAnOrderLeftContent__LgV_2">
                                    <div className="advertiseList_avatarContent__uAQHP">
                                      <div className="advertiseList_avatar__rEIGv shadow-soft">💎</div>
                                      <div className="advertiseList_adAvatarRight__SbOZP">
                                        <div className="d-flex align-items-center" >
                                          <span className="advertiseList_nickName__VI9tJ"> {selectedOrder?.post_name}  </span>
                                          <p className="advertiseList_merchantOrder__aO2o_ ms-2 "><span className="text-warning">

                                            {selectedOrder?.ratings == 1 ?
                                              <i className="ri-star-fill me-1"></i>
                                              : selectedOrder?.ratings == 2 ?
                                                <>
                                                  <i className="ri-star-fill me-1"></i>
                                                  <i className="ri-star-fill me-1"></i>
                                                </>
                                                : selectedOrder?.ratings == 3 ?
                                                  <>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                  </>
                                                  : selectedOrder?.ratings == 4 ?
                                                    <>
                                                      <i className="ri-star-fill me-1"></i>
                                                      <i className="ri-star-fill me-1"></i>
                                                      <i className="ri-star-fill me-1"></i>
                                                      <i className="ri-star-fill me-1"></i>
                                                    </>
                                                    : selectedOrder?.ratings == 5 ?
                                                      <>
                                                        <i className="ri-star-fill me-1"></i>
                                                        <i className="ri-star-fill me-1"></i>
                                                        <i className="ri-star-fill me-1"></i>
                                                        <i className="ri-star-fill me-1"></i>
                                                        <i className="ri-star-fill me-1"></i>
                                                      </>
                                                      : "No Rating Available"
                                            }
                                          </span>
                                            {selectedOrder?.ratings == 0 ? " " : <span className="advertiseList_tradingLine__swVrO"></span>}
                                            <span className="text-warning">{selectedOrder?.ratings == 0 ? "" : selectedOrder?.ratings}</span></p>
                                        </div>
                                        {selectedOrder?.successfullOrders == 0 ? null :
                                          <p className="advertiseList_merchantOrder__aO2o_">{selectedOrder?.successfullOrders}<span className="advertiseList_tradingOrder__DjoPA"> successfull orders</span>
                                          </p>}

                                      </div>
                                    </div>

                                    <div className="advertiseList_adLimitInfo__e8oAL">
                                      <div className="row" >
                                        <p className="col-6 col-md-3" >
                                          <span className="text-success advertiseList_expandPrice__Bgj3_">
                                            <span>{selectedOrder?.fixed_price} {selectedOrder?.quote_short_name}</span> </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block">Price</span>
                                        </p>
                                        <p className="col-6  col-md-3" >
                                          <span className="text-success advertiseList_expandPrice__Bgj3_">
                                            <span>{selectedOrder?.payment_time} Minutes</span> </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block">Payment Time Limit</span>
                                        </p>
                                        <p className="col-12  col-md-12" >
                                          <span className="advertiseList_paymentContent__1oElX">
                                            {item?.payment_method?.map((item, index) => {
                                              return (
                                                <span key={index} className={`advertiseList_paymentList__IageJ badge-${index % 3 === 0 ? 'success' : index % 3 === 1 ? 'danger' : 'primary'}`}>
                                                  <span>
                                                    <span >{item?.type}</span>
                                                  </span>
                                                </span>

                                              )
                                            })}

                                          </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block mt-1">{selectedOrder?.side === "BUY" ? "Buyer's" : "Seller's"} payment method</span>
                                        </p>
                                      </div>
                                    </div>
                                    {selectedOrder?.currencyAmoun && <> <div className="advertiseList_adRemark__afWVG">
                                      <div className="advertiseList_adRemarkTitle__ky3Rq">Merchant Remarks</div>
                                      <div className="advertiseList_remarkInfo__zSuXX">
                                        Minimum {selectedOrder?.currencyAmount + " " + selectedOrder?.counterCurrency} is required in {selectedOrder?.side === "BUY" ? "Seller's" : "Buyer's"} P2P Wallet
                                      </div>
                                    </div></>}
                                  </div>
                                  <div className="advertiseList_expandableLine__CDGum"></div>
                                  <div className="advertiseList_placeAnOrderRightContent__tE8rt">
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                      <p className="advertiseList_placeAnOrderPrompnt__0x9m7">I want to pay</p>
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input placeholder="0" className="form-control email_input form-select" id="control-hooks_amount" aria-required="true" type="number" onWheel={(e) => e.target.blur()} name="payableAmount" disabled
                                            value={payableAmount} />
                                          <span className="ant-input-suffix">
                                            <span className="ant-input-clear-icon ant-input-clear-icon-hidden ant-input-clear-icon-has-suffix" role="button" tabIndex="-1">
                                            </span>
                                            <div className="advertiseList_suffixContent__s3Fce"><span className="advertiseList_all__aTtrH"></span>{selectedOrder?.quote_short_name}</div>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                      <p className="advertiseList_placeAnOrderPrompnt__0x9m7">I will receive</p>
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input placeholder={`${selectedOrder?.min_amount} - ${selectedOrder?.max_amount}`} className="form-control email_input form-select" id="control-hooks_amount" aria-required="true" type="number" onWheel={(e) => e.target.blur()} disabled name="receivableAmount"
                                            value={receivableAmount} />
                                          <span className="ant-input-suffix">
                                            <span className="ant-input-clear-icon ant-input-clear-icon-hidden ant-input-clear-icon-has-suffix" role="button" tabIndex="-1">
                                            </span>
                                            <div className="advertiseList_suffixContent__s3Fce"><span className="advertiseList_all__aTtrH"></span>{selectedOrder?.base_short_name}</div>
                                          </span>
                                        </span>
                                      </div>

                                    </div>
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input placeholder="Select Payment Method" className="form-control email_input form-select"
                                            id="control-hooks_amount" aria-required="true" name="receivableAmount" data-bs-toggle="modal"
                                            data-bs-target="#payment_method_list" style={{ cursor: "pointer", color: "white" }}
                                            value={sellerSelectedPayment} />

                                        </span>
                                      </div>
                                    </div>
                                    <div className="advertiseList_tradingAgreement__5tQwy">
                                      <div className="form-check mb-4">
                                        <input className="form-check-input" type="checkbox" id="flexcheckbox" onClick={(e) => e.target.checked ? setCheckAgreement(true) : setCheckAgreement(false)} />I have read and agree to Inoutbit Peer-to-Peer (P2P) Service Agreement
                                      </div>
                                    </div>
                                    <div className="advertiseList_placeAnOrderBtnContent__L7B0w row gx-1">
                                      <div className="col-6 col-md-4" >
                                        <button type="button" className="btn custom-btn custom-border-btn  custom-border-btn-white btn-block w-100" onClick={() => setSelectedOrder(null)}><span>Cancel</span></button>
                                      </div>
                                      <div className="col-6  col-md-8" >
                                        <button type="button" className="btn btn-success btn-block w-100 btn-shadow-soft" disabled={!checkAgreement || !sellerSelectedPayment} onClick={handleOrder} >Buy <span className="advertiseList_expandableCoin__lMp3q">{selectedOrder?.base_short_name}</span></button>
                                      </div>
                                    </div>
                                    <div className="advertiseList_friendlyReminder__SAA_V"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </React.Fragment>)
                    }) : <p className="text-center p-3 pb-4 no-data h-100 mb-0" >
                      <img src="/images/no-data.png" className='img-fluid ' alt="no data" width="52" />
                      No Data Available
                    </p>}

                  </div>
                </div>
              </div>
            </div>

            {/* sell data tab */}
            <div className="tab-pane fade" id="p_two_p_sell" role="tabpanel" aria-labelledby="p_two_p_sell-tab">
              <div className="ant-table-content">
                <div className="table" >
                  <div className="ant-table-thead thead d-none d-md-block">
                    <div className="tr" >
                      <div className="ant-table-cell th">Advertiser</div>
                      <div className="ant-table-cell th">Price</div>
                      <div className="ant-table-cell th">Limit</div>
                      <div className="ant-table-cell th">Payment Method</div>
                      <div className="ant-table-cell th">
                        <div className="advertiseList_tradeTitle__ae016">Trade
                          <div className="advertiseList_fee__TDbjz">0 Fee</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" tbody ant-table-tbody">
                    {sellOrders?.length > 0 ? sellOrders?.map((item) => {
                      return (
                        <React.Fragment key={item?._id} >
                          <div className=" tr ant-table-row ant-table-row-level-0">
                            <div className=" td ant-table-cell patr_data1 ">
                              <div className="advertiseList_avatarContent__uAQHP">
                                <div className="advertiseList_avatar__rEIGv shadow-soft">💎</div>
                                <div className="advertiseList_adAvatarRight__SbOZP"><span className="advertiseList_nickName__VI9tJ">{item?.post_name}</span>
                                  {item?.successfullOrders == 0 ? null :
                                    <p className="advertiseList_merchantOrder__aO2o_">{item?.successfullOrders}<span className="advertiseList_tradingOrder__DjoPA"> successfull orders</span>
                                    </p>}
                                  <div className="advertiseList_firstTrade__Z5OC5">
                                    <p className="advertiseList_merchantOrder__aO2o_">
                                      <span className="text-warning" >
                                        {item?.ratings == 1 ?
                                          <i className="ri-star-fill me-1"></i>
                                          : item?.ratings == 2 ?
                                            <>
                                              <i className="ri-star-fill me-1"></i>
                                              <i className="ri-star-fill me-1"></i>
                                            </>
                                            : item?.ratings == 3 ?
                                              <>
                                                <i className="ri-star-fill me-1"></i>
                                                <i className="ri-star-fill me-1"></i>
                                                <i className="ri-star-fill me-1"></i>
                                              </>
                                              : item?.ratings == 4 ?
                                                <>
                                                  <i className="ri-star-fill me-1"></i>
                                                  <i className="ri-star-fill me-1"></i>
                                                  <i className="ri-star-fill me-1"></i>
                                                  <i className="ri-star-fill me-1"></i>
                                                </>
                                                : item?.ratings == 5 ?
                                                  <>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                  </>
                                                  : "No Rating Available"
                                        }
                                      </span>
                                      {item?.ratings == 0 ? " " : <span className="advertiseList_tradingLine__swVrO"></span>}
                                      <span className="text-warning">{item?.ratings == 0 ? "" : item?.ratings}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" td ant-table-cell patr_data2">
                              <div className="advertiseList_adPrice__fKc28"> <small className="d-md-none" >Price</small> <span className="advertiseList_price__ZL3g1">{item?.fixed_price.toFixed(3)}</span><span className="advertiseList_unit__FXgOK">{item?.quote_short_name}</span></div>
                            </div>
                            <div className=" td ant-table-cell patr_data3">
                              <div className="advertiseList_limit__vjRP2">
                                <div>
                                </div>
                                <div>
                                  <p className="advertiseList_limitInfo__kSoM7">{item?.volume} <span className="advertiseList_expanCoin__FZDED">{item?.base_short_name}</span></p>
                                </div>
                              </div>
                            </div>
                            <div className=" td ant-table-cell patr_data4">
                              <div className="advertiseList_paymentContent__1oElX">
                                {item?.payment_method?.map((item, index) => {
                                  return (
                                    <span key={index} className={`advertiseList_paymentList__IageJ badge-${index % 3 === 0 ? 'success' : index % 3 === 1 ? 'danger' : 'primary'}`}>
                                      <span>
                                        <span >{item?.type}</span>
                                      </span>
                                    </span>
                                  )
                                })}
                              </div>
                            </div>

                            <div className=" td ant-table-cell ant-table-row-expand-icon-cell patr_data5">
                              {userDetails?.kycVerified == "2"
                                ?
                                <button className="btn advertiseList_expandIcon__3jNNF advertiseList_sellExpandIcon__F22Dj sell_btn" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" type="button" onClick={() => showSellExpandedRow(item)}> Sell  {item?.base_short_name}  </button>
                                :
                                <button className="btn btn-outline-danger" type="button"> Restricted  </button>
                              }
                            </div>
                          </div>
                          {selectedOrder === item &&
                            <div className="ant-table-expanded-row ant-table-expanded-row-level-1 collapse show shadow-soft" id="collapseExample" >
                              <div className="td w-100 ant-table-cell">
                                <div className="advertiseList_expandableContent___v_pW">
                                  <div className="advertiseList_placeAnOrderLeftContent__LgV_2">
                                    <div className="advertiseList_avatarContent__uAQHP">
                                      <div className="advertiseList_avatar__rEIGv shadow-soft">💎</div>
                                      <div className="advertiseList_adAvatarRight__SbOZP">
                                        <div className="d-flex align-items-center" >
                                          <span className="advertiseList_nickName__VI9tJ"> {selectedOrder?.post_name}  </span>
                                          <p className="advertiseList_merchantOrder__aO2o_ ms-2 "><span className="text-warning">
                                            {selectedOrder?.ratings == 1 ?
                                              <i className="ri-star-fill me-1"></i>
                                              : selectedOrder?.ratings == 2 ?
                                                <>
                                                  <i className="ri-star-fill me-1"></i>
                                                  <i className="ri-star-fill me-1"></i>
                                                </>
                                                : selectedOrder?.ratings == 3 ?
                                                  <>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                    <i className="ri-star-fill me-1"></i>
                                                  </>
                                                  : selectedOrder?.ratings == 4 ?
                                                    <>
                                                      <i className="ri-star-fill me-1"></i>
                                                      <i className="ri-star-fill me-1"></i>
                                                      <i className="ri-star-fill me-1"></i>
                                                      <i className="ri-star-fill me-1"></i>
                                                    </>
                                                    : selectedOrder?.ratings == 5 ?
                                                      <>
                                                        <i className="ri-star-fill me-1"></i>
                                                        <i className="ri-star-fill me-1"></i>
                                                        <i className="ri-star-fill me-1"></i>
                                                        <i className="ri-star-fill me-1"></i>
                                                        <i className="ri-star-fill me-1"></i>
                                                      </>
                                                      : "No Rating Available"
                                            }
                                          </span>{selectedOrder?.ratings == 0 ? " " : <span className="advertiseList_tradingLine__swVrO"></span>}
                                            <span className="text-warning">{selectedOrder?.ratings == 0 ? "" : selectedOrder?.ratings}</span></p>
                                        </div>
                                        {selectedOrder?.successfullOrders == 0 ? null :
                                          <p className="advertiseList_merchantOrder__aO2o_">{selectedOrder?.successfullOrders}<span className="advertiseList_tradingOrder__DjoPA"> successfull orders</span>
                                          </p>}

                                      </div>
                                    </div>

                                    <div className="advertiseList_adLimitInfo__e8oAL">
                                      <div className="row" >
                                        <p className="col-6 col-md-3" >
                                          <span className="text-success advertiseList_expandPrice__Bgj3_">
                                            <span>{selectedOrder?.fixed_price} {selectedOrder?.quote_short_name}</span>
                                          </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block">Price</span>
                                        </p>
                                        <p className="col-6  col-md-3" >
                                          <span className="text-success advertiseList_expandPrice__Bgj3_">
                                            <span>{selectedOrder?.payment_time}</span> </span>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block">Payment Time Limit</span>
                                        </p>
                                        <p className="col-12  col-md-12" >
                                          <div className="advertiseList_paymentContent__1oElX">
                                            {item?.payment_method?.map((item, index) => {
                                              return (
                                                <span key={index} className={`advertiseList_paymentList__IageJ badge-${index % 3 === 0 ? 'success' : index % 3 === 1 ? 'danger' : 'primary'}`}>
                                                  <span>
                                                    <span >{item?.type}</span>
                                                  </span>
                                                </span>
                                              )
                                            })}

                                          </div>
                                          <span className="advertiseList_adLimitTitle__sGUqF d-block mt-1">{selectedOrder?.side === "BUY" ? "Buyer's" : "Seller's"} payment method</span>
                                        </p>
                                      </div>
                                    </div>
                                    {selectedOrder?.currencyAmoun && <> <div className="advertiseList_adRemark__afWVG">
                                      <div className="advertiseList_adRemarkTitle__ky3Rq">Merchant Remarks</div>
                                      <div className="advertiseList_remarkInfo__zSuXX">
                                        Minimum {selectedOrder?.currencyAmount + " " + selectedOrder?.counterCurrency} is required in {selectedOrder?.side === "BUY" ? "Seller's" : "Buyer's"} P2P Wallet
                                      </div>
                                    </div></>}
                                  </div>
                                  <div className="advertiseList_expandableLine__CDGum"></div>
                                  <div className="advertiseList_placeAnOrderRightContent__tE8rt">
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                      <p className="advertiseList_placeAnOrderPrompnt__0x9m7">I want to pay</p>
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input placeholder={`${selectedOrder?.min_amount} - ${selectedOrder?.max_amount}`} className="form-control email_input form-select" id="control-hooks_amount" aria-required="true" type="number" disabled onWheel={(e) => e.target.blur()} name="payableAmount" value={payableAmount} />
                                          <span className="ant-input-suffix">
                                            <span className="ant-input-clear-icon ant-input-clear-icon-hidden ant-input-clear-icon-has-suffix" role="button" tabIndex="-1">
                                            </span>
                                            <div className="advertiseList_suffixContent__s3Fce"><span className="advertiseList_all__aTtrH"></span>{selectedOrder?.base_short_name}</div>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                      <p className="advertiseList_placeAnOrderPrompnt__0x9m7">I will receive</p>
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input placeholder="0" className="form-control email_input form-select" id="control-hooks_amount" aria-required="true" type="number" onWheel={(e) => e.target.blur()} name="receivableAmount" disabled
                                            value={receivableAmount} />
                                          <span className="ant-input-suffix">
                                            <span className="ant-input-clear-icon ant-input-clear-icon-hidden ant-input-clear-icon-has-suffix" role="button" tabIndex="-1">
                                            </span>
                                            <div className="advertiseList_suffixContent__s3Fce"><span className="advertiseList_all__aTtrH"></span>{selectedOrder?.quote_short_name}</div>
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="advertiseList_placeAnOrder__XRO8U mb-3">
                                      <p className="advertiseList_placeAnOrderPrompnt__0x9m7">Select payment method</p>
                                      <div className="ant-form-item">
                                        <span className="froup_feild  ant-input-affix-wrapper advertiseList_coinNameInput__1l54d">
                                          <input placeholder="Select Payment Method" className="form-control email_input form-select" id="control-hooks_amount" aria-required="true" name="receivableAmount" data-bs-toggle="modal" data-bs-target="#payment_method_list" style={{ cursor: "pointer", color: "white" }} value={buyerSelectedPayment} />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="advertiseList_tradingAgreement__5tQwy">
                                      <div className="form-check mb-4">
                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" onClick={(e) => e.target.checked ? setCheckAgreement(true) : setCheckAgreement(false)} />I have read and agree to Inoutbit Peer-to-Peer (P2P) Service Agreement
                                      </div>
                                    </div>
                                    <div className="advertiseList_placeAnOrderBtnContent__L7B0w row gx-1">
                                      <div className="col-6 col-md-4" >
                                        <button type="button" className="btn custom-btn custom-border-btn  custom-border-btn-white btn-block w-100" onClick={() => setSelectedOrder(null)}><span>Cancel</span></button>
                                      </div>
                                      <div className="col-6  col-md-8" >
                                        <button type="button" className="btn btn-danger  btn-block w-100 btn-shadow-soft" disabled={!checkAgreement || !buyerSelectedPayment} onClick={() => { handleOrder() }}>Sell <span className="advertiseList_expandableCoin__lMp3q">{selectedOrder?.base_short_name}</span></button>
                                      </div>
                                    </div>
                                    <div className="advertiseList_friendlyReminder__SAA_V"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </React.Fragment>)
                    }) : <p className="text-center p-3 pb-4 no-data h-100 mb-0" >
                      <img src="/images/no-data.png" className='img-fluid ' alt="no data" width="52" />
                      No Data Available
                    </p>}



                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      {/* Payment Methods Modal */}
      <div className="modal fade" id="payment_method_list" tabIndex="-1" aria-labelledby="payment_method_listLaebl" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '500px' }}>
          <div className="modal-content p-2 pb-5">
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">Select Payment Method</h3>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
            </div>
            <div className="modal-footer p-1">
              {(traderPaymentMethod?.UpiDetails.length == 0 && traderPaymentMethod?.BankDetails.length == 0 && currentTab == "BUY") ? <button type="button" onClick={() => { $("#payment_method_list").modal('hide'); navigate("/profile/BankAccount") }}>Add Payment method</button> :
                currentTab == "BUY" &&
                <>
                  {traderPaymentMethod?.UpiDetails.length > 0 && traderPaymentMethod?.UpiDetails.map((item, index) => {
                    return (
                      <button key={index} type="button" className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setSellerSelectedPayment(item?.upi_id); setSellerMethod(item) }}><span>{item?.type} ({item?.upi_id})</span>
                      </button>
                    )
                  })}
                  {traderPaymentMethod?.BankDetails.length > 0 && traderPaymentMethod?.BankDetails.map((item, index) => {
                    return (
                      <button key={index} type="button" className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setSellerSelectedPayment(item?.bank_name + " " + item?.type); setSellerMethod(item) }}><span>{item?.type} ({item?.bank_name})</span>
                      </button>
                    )
                  })}
                </>
              }

              {currentTab == "SELL" && <div className="btn btn-gradient btn-medium justify-content-between w-100 m-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setBuyerSelectedPayment("P2P_Wallet"); setBuyerMethod("P2P_Wallet") }}
              ><span>P2P Wallet</span></div>
              }
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default PTwoP;
