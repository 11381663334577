import React from "react";
import { Link } from "react-router-dom";

const CookiePolicy = () => {

    return (
        <section className="  section-padding feature_bg pc_bg  login_sec" >
            <div className="container">
                <div className="inner text-center">
                    <h2 className="title"> COOKIE POLICY </h2>
                    <nav className="mt-4">
                        <ol className="breadcrumb justify-content-center">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">COOKIE POLICY</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="pb-90 pt-5 ">
                <div className="container">
                    <div className="row" >
                        <div className="col-md-10 m-auto" >
                            <div className="create-item-wrapper plicy_sec" >
                                <div className="row align-items-center py-5">
                                    <div className="col-lg-12">
                                        <div>
                                            <h2>CVTrade Cookie Policy</h2>

                                            <p>At CVTrade, we use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our website https://cvtrade.io, including any associated media forms, channels, mobile websites, or applications (collectively referred to as the &ldquo;Site&rdquo;). These technologies help enhance your experience, customize content, and optimize the Site&rsquo;s functionality.</p>
                                            <hr />
                                            <h3>Changes to This Policy</h3>

                                            <p>We may revise this Cookie Policy at any time and for any reason. Any updates will be reflected by changing the &ldquo;Last Updated&rdquo; date at the top of this policy. These changes will become effective immediately upon posting, and by continuing to use the Site after the updated Cookie Policy is posted, you agree to be bound by these changes. You waive the right to receive individual notices of these modifications. We encourage you to periodically review this Cookie Policy to stay informed of any updates. You will be deemed to have accepted changes by your continued use of the Site after the revised Cookie Policy has been posted.</p>
                                            <hr />
                                            <h3>What Are Cookies?</h3>

                                            <p>Cookies are small pieces of data stored on your computer, assigned with a unique identifier. Every time you submit a request or action on the Site, your browser sends this identifier to us. Cookies help track your activities, enhance navigation, save your preferences, remember your login details, facilitate transaction processes, and understand how the Site is being used. Ultimately, they help us improve your user experience.</p>
                                            <hr />
                                            <h3>Types of Cookies Used on Our Site</h3>

                                            <ol>We use various types of cookies to ensure that you have the best experience when using our Site. The following types may be deployed when you visit:
                                            <br />
                                                <li> Advertising Cookies : These cookies are placed by advertisers and ad servers to display advertisements that are relevant to you. They collect data about your activities both on our Site and across other websites, allowing us to show you targeted ads. This data is anonymous and only linked to your computer, not to your personal information.</li>
                                                <br />
                                                <li>  Analytics Cookies : These cookies track how visitors arrive at and navigate the Site. We use them to analyze how you interact with our content, what pages you visit, and how long you stay on each page. This helps us understand which parts of the Site perform best and which areas need improvement.</li>
                                                <br />
                                                <li>  Security Cookies : Security cookies are used to identify security risks and protect your data. They help verify your identity when you log in and ensure that your data is safe from unauthorized access or activity.</li>
                                                <br />
                                                <li>  Site Management Cookies : Site management cookies are essential for keeping track of your sessions. They ensure that you remain logged in while navigating the Site and that any data entered is preserved as you move between pages. These cookies are critical to the Site&rsquo;s core functionality and cannot be turned off without affecting your user experience.</li>
                                                <br />
                                                <li>  Third-Party Cookies : These cookies are placed by external companies providing services on our behalf. Third-party cookies may be used for analytics, advertising, or other functions integrated with the Site. Though these cookies are linked to your device, they are managed and collected by third parties. You can manage or block third-party cookies in your browser settings.</li></ol>
                                            <hr />
                                            <h3>Necessary Cookies</h3>

                                            <p>In addition to the cookies described above, CVTrade also uses necessary cookies that are critical to ensuring the basic functionality of the Site. Without these cookies, key features of the Site will not work properly. Disabling them in your browser may impair your experience and limit access to certain services.</p>
                                            <hr />
                                            <h3>Controlling Cookies</h3>

                                            <p>Most browsers are configured to accept cookies by default. However, you can change your browser settings to block cookies or alert you when a cookie is sent to your device. Keep in mind that blocking cookies may affect your ability to use certain features or sections of the Site. For instructions on how to control cookies, you can visit your browser&rsquo;s help section or refer to the following links: Apple Safari Google Chrome Microsoft Edge Mozilla Firefox Opera Android (Chrome)  Blackberry iPhone or iPad (Chrome) iPhone or iPad (Safari)</p>
                                            <hr />
                                            <h3>More About Your Privacy</h3>

                                            <p>To learn more about how CVTrade protects your privacy, including how we handle your personal data, please refer to our <a href="/privacy_policy">Privacy Policy</a> </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </section>
    );
}

export default CookiePolicy;