import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponents/CustomAlertMessage";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import { $ } from "react-jquery-plugin";
import { isGlobalEmailDomain, validateEmail, validPassword } from "../../../utils/Validation"
import { ProfileContext } from "../../../context/ProfileProvider";
import { useGoogleLogin } from '@react-oauth/google';
import GeeTest from 'react-geetest-v4';
import { Helmet } from "react-helmet-async";


const LoginPage = () => {
  const captchaRef = useRef(null);
  const googlecaptchaRef = useRef(null);

  const [signId, setSignId] = useState("");
  const [password, setPassword] = useState("");
  const [showPass2, setshowPass2] = useState(false);
  const [userDetails, setuserDetails] = useState();
  const [vCode, setVCode] = useState("");
  const [captchaData, setCaptchaData] = useState({});
  const [captchaData2, setCaptchaData2] = useState({});
  const [googleToken, setGoogleToken] = useState();
  const [authType, setauthType] = useState();

  const navigate = useNavigate();

  const { setLoginDetails } = useContext(ProfileContext);

  const handleLogin = async (signId, password, captchaData) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.login(signId, password, captchaData);
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        if (result?.data?.['2fa'] === 0) {
          alertSuccessMessage(result.message);
          sessionStorage.setItem("token", result.data.token);
          sessionStorage.setItem("userId", result.data.userId);
          setLoginDetails(result.data);
          navigate("/funds_details");
        } else {
          $("#Confirmation_model").modal('show');
          setauthType(result?.data?.['2fa']);
          setuserDetails(result?.data);
          setLoginDetails(result.data);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    } catch (error) {
      if (error.response) {
        // Handle specific HTTP response errors
        alertErrorMessage(error.response.data.message);
        LoaderHelper.loaderStatus(false);
      } else if (error.request) {
        // Handle network connection errors
        alertErrorMessage("Network error. Please check your internet connection.");
        LoaderHelper.loaderStatus(false);
      } else {
        // Handle other errors
        alertErrorMessage("An error occurred. Please try again later.");
        LoaderHelper.loaderStatus(false);
      }
    }
  };

  const handleAuthVerify = async (authType, vCode) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getCode(authType === 1 ? userDetails?.emailId : authType === 3 ? userDetails?.mobileNumber : signId, authType, vCode).then(async (result) => {
      if (result.success) {
        try {
          alertSuccessMessage(result.message);
          sessionStorage.setItem("token", result.data.token);
          sessionStorage.setItem("userId", result.data.userId);
          setLoginDetails(result.data);
          $("#Confirmation_model").modal('hide');
          navigate("/funds_details");
          LoaderHelper.loaderStatus(false);
        } catch (error) {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const mySubmitFunction = async (event) => {
    event.preventDefault()
    return true
  }

  const handleReset = () => {
    setPassword('')
    setSignId('')
  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: tokenResponse => {
      if (tokenResponse.access_token) {
        setGoogleToken(tokenResponse)
        if (googlecaptchaRef.current) {
          googlecaptchaRef.current.showCaptcha();
        }
        handleLoginGoogle(tokenResponse)
      }
    }
  });

  const handleLoginGoogle = async (tokenResponse, captchaData) => {
    LoaderHelper.loaderStatus(true);
    try {
      const result = await AuthService.googleLogin(tokenResponse, captchaData);
      if (result?.success) {
        alertSuccessMessage(result?.message);
        sessionStorage.setItem("token", result.data.token);
        sessionStorage.setItem("userId", result.data.userId);
        setLoginDetails(result?.data);
        navigate("/funds_details");
        LoaderHelper.loaderStatus(false);
      } else {
        alertErrorMessage(result?.message);
        LoaderHelper.loaderStatus(false);
      }
    } catch (error) {
      alertErrorMessage(error?.message);
      LoaderHelper.loaderStatus(false);
    }
  };



  const handleCaptchaSuccess = (result) => {
    setCaptchaData(result)
  };
  const handleCaptchaSuccess2 = (result) => {
    setCaptchaData2(result)
  };

  // useEffect(() => {
  //   if (Object.keys(captchaData).length > 0) {
  //     handleLogin(signId, password, captchaData); // Only call when captchaData is set
  //   }
  // }, [captchaData]);

  // useEffect(() => {
  //   if (Object.keys(captchaData2).length > 0) {
  //     handleLoginGoogle(googleToken, captchaData2); // Only call when captchaData is set
  //   }
  // }, [captchaData2]);
  return (
    <>
      <Helmet>
        <title> CV Trade | The world class new generation crypto asset exchange</title>
      </Helmet>
      <section className="  section-padding feature_bg pc_bg login_sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
            </div>
            <div className="col-lg-6 col-12 ">

              <form className="custom-form ticket-form mb-5 mb-lg-0" onSubmit={mySubmitFunction}>

                <div className="card-header login-header" >
                  <ul className="nav nav-pills mb-3 login-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="pills-Email-tab" data-bs-toggle="pill" data-bs-target="#pills-Email" type="button" role="tab" aria-controls="pills-Email" aria-selected="true" onClick={handleReset}>
                        <img src="/images/login_email_icon.svg" className="me-1 show_dark" width="30" height="30" />
                        <img alt="" src="/images/login_email_icon_light.svg" className=" show_light" width="30" height="30"></img>

                        <span>Email</span>
                      </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <button className="nav-link" id="pills-Phone-tab" data-bs-toggle="pill" data-bs-target="#pills-Phone" type="button" role="tab" aria-controls="pills-Phone" aria-selected="false" onClick={handleReset}>
                        <img src="/images/login_mobile_icon.svg" className="me-3 show_dark" width="30" height="30" />
                        <img alt="" src="/images/login_mobile_icon_light.svg" className="me-3 show_light" width="30" height="30"></img>
                        <span>Phone</span>
                      </button>
                    </li> */}
                  </ul>
                </div>
                <div className="tab-content pt-4" id="pills-tabContent">
                  <div className="tab-pane show active" id="pills-Email" role="tabpanel" aria-labelledby="pills-Email-tab">
                    <div className="card-body " >
                      <div className="row">
                        <div className="col-md-12" >
                          <label className=" mb-2" >Email Address<em className="text-danger" >*</em></label>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <input type="email" name="form-phone" id="form-signId" className="form-control email_input" placeholder="Enter Email" value={signId} onChange={(e) => setSignId(e.target.value)} />
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <label className=" mb-2">Password<em className="text-danger" >*</em></label>
                          <div className=" field-otp-box" >
                            <input type={showPass2 ? 'text' : "password"} name="form-password" id="form-password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <button type="button" className='btn btn-icon btn_view_pass btn-white'
                              onClick={() => { setshowPass2(!showPass2) }}  >
                              {
                                showPass2 ?
                                  <i className="ri-eye-line"></i>
                                  :
                                  <i className="ri-eye-close-line"></i>
                              }
                            </button>
                          </div>
                        </div>


                        <div className="col-lg-12 col-md-12 col-12 text-end mb-5">
                          <Link className=" " to="/forgot_password"> Forgot Password? </Link>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-10 col-12 mx-auto">
                        {!signId || !password || validateEmail(signId) !== undefined || validPassword(password) !== undefined || !isGlobalEmailDomain(signId) ?
                          <button type="button" className="btn custom-btn btn-xl w-100 btn-block invalid"
                          >
                            Login
                          </button>
                          :
                          <button
                            type="button"
                            className="btn custom-btn btn-xl w-100 btn-block "
                            onClick={() => {
                              if (validateEmail(signId) !== undefined) {
                                alertErrorMessage("Invalid Email");
                                return;
                              }
                              else {
                                handleLogin(signId, password, captchaData); 
                                // if (captchaRef.current) {
                                //   captchaRef.current.showCaptcha();
                                // }
                              }
                       
                            }}
                          >
                            Login
                          </button>

                        }

                        {/* <GeeTest
                          ref={captchaRef}
                          captchaId={'239063355a512035bb431b95a4f1a7eb'}
                          product={'bind'}
                          onSuccess={handleCaptchaSuccess}
                        />

                        <GeeTest
                          ref={googlecaptchaRef}
                          captchaId={'239063355a512035bb431b95a4f1a7eb'}
                          product={'bind'}
                          onSuccess={handleCaptchaSuccess2}
                        /> */}


                      </div>
                      <div className="registration__info">Or continue with</div>
                      <div className="col-lg-12 col-md-10 col-12 mx-auto ">

                        <button onClick={() => loginWithGoogle()} type="button" className="btn custom-btn custom-border-btn custom-border-btn-dark btn-xl w-100 btn-block " >  <i className="ri-google-line ri-xl me-3"></i> Sign in with Google </button>

                      </div>
                      <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                        <p>Do you have an account? <a className="fw-bold" href="/signup"> Register </a></p>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="pills-Phone" role="tabpanel" aria-labelledby="pills-Phone-tab">
                    <div className="card-body " >
                      <div className="row">
                        <div className="col-md-12" >
                          <label className=" mb-2" >Mobile Number<em className="text-danger" >*</em></label>
                        </div>
                        <div className="col-lg-4 col-md-12 col-12">
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <input type="number" name="form-phone" id="form-phone" className="form-control phone_input" placeholder="Enter Mobile Number" value={signId} onChange={(e) => setSignId(e.target.value)} onWheelCapture={e => {
                            e.target.blur()
                          }} />
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                          <label className=" mb-2" >Password<em className="text-danger" > *</em></label>
                          <div className=" field-otp-box" >
                            <input type={showPass2 ? 'text' : "password"} name="form-password" id="form-password2" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <button type="button" className='btn btn-icon btn_view_pass btn-white'
                              onClick={() => { setshowPass2(!showPass2) }}  >
                              {
                                showPass2 ?
                                  <i className="ri-eye-line"></i>
                                  :
                                  <i className="ri-eye-close-line"></i>
                              }
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12 text-end mb-5">
                          <Link className=" " to="/forgot_password"> Forgot Password? </Link>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-10 col-12 mx-auto">

                        <button
                          type="button"
                          className="btn custom-btn btn-xl w-100 btn-block"
                          onClick={() => {

                            handleLogin(signId, password, captchaData);
                          }}
                        >
                          Login
                        </button>

                        {/* <GeeTest
                          ref={captchaRef}
                          captchaId={'239063355a512035bb431b95a4f1a7eb'}
                          product={'bind'}
                          onSuccess={handleCaptchaSuccess}
                        /> */}
                      </div>
                      <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                        <p>Do you have an account? <a className="fw-bold" href="/signup"> Register </a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-6 col-12">
              <div class="login_qr">
                <div class="l_qr"><img src="/images/app_qr_code.png" width="240" class="img-fluid" alt="" /></div>
                <h5> Download Our App! </h5>
                <p>Scan This Code To Download <span class="fw-bold text-gradient"> CV TRADE</span>  Mobile App Now.</p>
              </div>
            </div>


          </div>
        </div>
      </section>

      <div className="modal fade" id="Confirmation_model" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <form className="modal-content" onSubmit={mySubmitFunction}>
            <div className="modal-header flex-column px-8">
              <h3 className="modal-title" id="placeBitLaebl">Verify {authType === 1 ? 'Email OTP' : authType === 3 ? 'Mobile OTP' : 'Authenticator Code'}</h3>
              <div className="d-flex justify-content-start align-items-center mt-3">
                {authType === 1 && `Code will be sent to ${userDetails?.emailId} `}
                {authType === 2 && 'Code will be sent to Google Authenticator App'}
                {authType === 3 && `Code will be sent to ${userDetails?.mobileNumber} `}
              </div>
              <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i className="ri-close-fill"></i></button>
            </div>
            <div className="modal-body">
              <div className="step_2 " >
                <div className="col-md-12 m-auto mb-5 text-center" >
                  <div className="pt-4" >
                    <input type="text" className="mb-3 form-control " placeholder="Enter Your OTP" value={vCode} onChange={(e) => setVCode(e.target.value)} />
                  </div>
                  <button type="submit" onClick={() => handleAuthVerify(authType, vCode)} className="next_btn btn-gradient m-auto btn px-5 mt-2 w-50 py-3"> <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>

  )
}

export default LoginPage


