import React from "react";
import { Link } from "react-router-dom";

const TermsCondition = () => {

  return (
    <section className="  section-padding feature_bg pc_bg  login_sec" >
      <div className="container">
        <div className="inner text-center">
          <h2 className="title"> Terms And Condition </h2>
          <nav className="mt-4">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Terms And Condition</li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="pb-90 pt-5 ">
        <div className="container">
          <div className="row" >
            <div className="col-md-10 m-auto" >
              <div className="create-item-wrapper plicy_sec" >
                <div className="row align-items-center py-5">
                  <div className="col-lg-12">

                    <h3>This CVTrade Terms and Conditions Agreement ("Agreement")</h3>
                    <p>This Agreement is entered into between you (the "User" or "Customer") and C.V. TECH SERVICES CO., LTD. (the "Company"), a company established under the laws of Thailand. By agreeing that you have read, understood, and accepted all the terms and conditions of this Agreement, you have effectively entered into this Agreement via the Company's service platform.</p>

                    <br />

                    <h3>Whereas:</h3>
                    <p>(1) The Company operates business as a digital asset trading center through the service platform. The Company’s services include the following:</p>
                    <p>Providing services for the purchase, sale, exchange, deposit, and withdrawal of supported digital assets, as well as other related and necessary services.</p>
                    <p>Providing USD wallets and supported digital asset wallets to facilitate the services mentioned in (1) above, as well as other related and necessary services.</p>
                    <p>Providing any other services related to the operation of digital asset businesses for which the Company is responsible and/or provides exclusively.</p>
                    <p>(collectively referred to as the "Company’s services")</p>
                    <p>(2) The User wishes to use the Company’s services through the service platform. The Company will provide these services, and the User will have rights and duties concerning the Company’s services according to the criteria and conditions set forth in this Agreement.</p>
                    <p>(3) The User acknowledges that:</p>
                    <p>Investment or transactions related to digital assets are highly risky, including risks related to value, volume, or transactions that may be impacted by various factors, both directly and indirectly, which can arise or change at any time. Such causes are beyond the Company’s control.</p>
                    <p>Businesses related to digital assets have unique features different from other businesses. A digital asset trading center is merely an intermediary for providing digital asset trading services. Its operating system and services depend on and connect with the blockchain network or the operating systems of digital asset developers. The Company may have to rely on parties related to digital assets, digital asset developers, digital token offering system providers, and/or other persons involved in the trading center’s services (“ecosystem-related parties”) to enable the trading center to provide services to users for each transaction. As the systems of these ecosystem-related parties are beyond the Company’s control, the Company’s services as a digital asset trading center are subject to the limitations, conditions, capabilities, and stability of the technology, blockchain network, or operating systems of the ecosystem-related parties involved in the Blockchain services.</p>
                    <p>(4) The User acknowledges the aforementioned risks and limitations of the Company’s services and wishes to use the Company’s services. The User has read, studied, and understood the terms and conditions of the Company’s services as specified in this Agreement thoroughly. Both parties agree to comply with the terms and conditions of the Company’s services as specified in this Agreement.</p>

                    <br />

                    <h3>Definitions</h3>
                    <p>(1) “Supported Digital Assets” refer to digital assets supported by the Company for transactions through the Company’s service platform.</p>
                    <p>(2) “User Account” refers to an account opened for the User to access and use the Company’s services.</p>
                    <p>(3) “KYC (Know Your Customer)” refers to the User identity verification process as determined by the Company.</p>
                    <p>(4) “KYB (Know Your Business)” refers to the identity verification process for legal entity User as determined by the Company.</p>
                    <p>(5) “Registration” refers to the completion of personal information during the Company’s identity verification process.</p>
                    <p>(6) “Person Identification Number (PIN)” refers to facial recognition, biometric technology, password for device usage, One Time Password (OTP), and other codes related to accessing the Company’s services, regardless of what they are called and whether the codes are provided by the Company or set by the User.</p>
                    <p>(7) “Company’s Service Platform” refers to the website orbixtrade.com or related websites, applications, program access methods, or any systems used by the Company to provide services to the User.</p>
                    <p>(8) “Ecosystem-Related Parties” has the meaning as specified above.</p>

                    <br />

                    <h3>Terms and Conditions of this Agreement</h3>
                    <p>2.1 Acceptance of the terms and conditions of this Agreement: You acknowledge and agree that this Agreement will take effect immediately upon your registration and successful identity verification, indicating that you accept, understand, and agree to strictly comply with the terms and conditions of this Agreement.</p>
                    <p>2.2 Amendment of the terms and conditions of this Agreement: The Company reserves the right to amend and/or update the terms and conditions of this Agreement at any time. Such changes will take effect immediately upon notification to the User through channels designated by the Company, such as the website and/or email provided by the User. After the notification of the changed terms and conditions, continued use of the Company’s services will indicate your acceptance of those changes.</p>
                    <p>2.3 Partial Invalidity: If any term or condition of this Agreement, or any part thereof, is found to be illegal, void, or unenforceable, that term or condition shall be severed from the other terms or conditions, which shall remain in full force and effect and enforceable.</p>
                    <p>2.4 Waiver of Rights: The User agrees and acknowledges that this Agreement is legally binding and enforceable between the User and the Company. The non-enforcement or delay in exercising any rights under this Agreement shall not be considered a waiver of such rights.</p>
                    <p>2.5 Governing Law: This Agreement shall be governed by and interpreted in accordance with the laws of Thailand. Any disputes arising out of and/or related to this Agreement shall be subject to the jurisdiction of Thai courts.</p>

                    <br />

                    <h3>User Qualifications</h3>
                    <p>(1) The User must be at least 20 years old.</p>
                    <p>(2) The User must be a resident of Thailand.</p>
                    <p>(3) The User must have a bank account opened in Thailand and the bank account name must match the name used in the user account registration.</p>
                    <p>(4) The User must have a telephone number that can be contacted in Thailand and this number must match the number used in the user account registration.</p>
                    <p>(5) The User must pass identity verification according to the KYC (Know Your Customer) process, including presenting identification as specified by the Company. For corporate customers, KYB (Know Your Business) verification is required.</p>
                    <p>(6) The User must provide accurate and complete personal information as required by the Company and must immediately notify the Company of any changes to personal information.</p>
                    <p>(7) The User must comply with and adhere to the laws, rules, and regulations related to digital asset trading in Thailand, including but not limited to anti-money laundering laws, anti-terrorism financing laws, and tax laws.</p>
                    <p>(8) Users who are individuals or organization from countries categorized as high-risk by FATF (Financial Action Task Force) must provide additional evidence to comply with anti-money laundering and anti-terrorism financing measures. The Company reserves the right to cancel or refuse services without prior notice.</p>
                    <p>(9) Users who are individuals or organizations subject to FATCA (Foreign Account Tax Compliance Act) must provide evidence of tax compliance and FATCA adherence themselves.</p>
                    <p>(10) The User must not be a person listed on any list under laws related to anti-terrorism financing and the proliferation of weapons of mass destruction as declared by the Anti-Money Laundering Office (AMLO) and/or any list announced by AMLO according to United Nations Security Council resolutions or any person subject to financial sanctions.</p>
                    <p>(11) The User must accept and comply with the terms and conditions of service as stated in this Agreement including any amendments or additions to the terms and conditions that the Company may make from time to time.</p>

                    <br />

                    <h3>Registration, Identity Verification, and User Account Opening</h3>
                    <p>4.1 Registration: To register for the Company's services, the User must fill in the necessary personal information, such as name, surname, email, phone number, and other information as specified by the Company. The registration will be complete when you receive confirmation from the Company that the registration has been successful.</p>
                    <p>4.2 Identity Verification: To prevent fraud and ensure the security of the services, the User must undergo the KYC (Know Your Customer) identity verification process and KYB (Know Your Business) for corporate users. This includes customer identification, submission of relevant documents and information such as copies of ID cards, copies of household registration, copies of bank account books, and other evidence as requested by the Company. The User agrees to disclose and consent to the company verifying, collecting, storing, and using personal information in any form or nature, whether the personal information is disclosed by the User to the Company, obtained from third parties, or through linkage with data sources, information providers, or various agencies.</p>
                    <p>4.3 Account Opening: After the User has registered and completed identity verification, the Company will open an account for you. The opening of this account is considered an agreement by the User to accept all the terms and conditions specified in this Agreement.</p>
                    <p>4.4 Managing User Credentials: Once the User’s account is opened, the User will receive or need to set up identification codes (such as Login Name, Username, and/or email used for login) and passwords (such as PIN and/or Password). The User is responsible for keeping these identification codes and passwords secure and confidential. The User acknowledges that disclosing these identification codes and/or passwords to others constitutes a violation of the terms and conditions of this Agreement.</p>
                    <p>4.5 Changes to Personal Information: If there are changes to the User’s personal information, the User must immediately notify the Company so that the Company can update and correct the personal information to be accurate and current.</p>
                    <p>4.6 Annual or Periodic Account Information Review: The User is responsible for complying with the procedures for reviewing the user account information according to the account opening period (Annual Account Review - KYC Review) following the Company’s know-your-customer process and customer identification. This includes evaluating the suitability of investing in digital assets and acknowledging the investment risks according to the Company’s policies and conditions.</p>
                    <p>4.7 Security: The Company has strict security measures for accessing and using the services. The User must comply with the security requirements set by the Company to prevent unauthorized access.</p>

                    <br />

                    <h3>System Access and Security Measures</h3>
                    <p>5.1 For access (or login) to use the Company’s service platform, the User must use their identification code and password as tools for logging in and/or accessing various services.</p>
                    <p>5.2 The User must keep their identification code and/or password secure and in a safe place to prevent loss. They must keep this information confidential and not disclose it or take any actions that might allow others to learn their identification code and/or password. Additionally, the User must ensure that their identification code and/or password do not fall under the possession of others under any circumstances.</p>
                    <p>5.3 The Company shall not be liable for any damages arising from the loss and/or disclosure of the identification code and/or password. The User can change their password at any time according to the rules, conditions, and methods specified by the Company without prior notice to the Company.</p>
                    <p>If the User forgets their password, they can reset it themselves through facial verification on the Company’s service platform by following the steps specified by the Company or by contacting the Company for further information.</p>
                    <p>5.4 The User acknowledges and agrees to use the services under this Agreement through the Company’s service platform as specified by the Company. They can access these services on only one (1) device at a time. If more than one device is used simultaneously, the system will allow only the most recently accessed device to use the service.</p>
                    <p>5.5 If the User’s mobile device or its system is found to be modified, such as Jailbreaking an iPhone (iOS) or Rooting an Android device, the Company reserves the right to suspend or terminate the services on the Company’s service platform (in whole or in part) immediately without prior notice to the User. This is to ensure the security of the service. The User waives the right to claim any damages from the Company.</p>
                    <p>5.6 If the mobile device is lost or stolen, or the User suspects unauthorized access to their account, they can contact the Company at 02-0266170, available 24 hours, or visit the Company’s office in person.</p>
                    <p>5.7 If the User enters the wrong password consecutively as many times as specified by the Company, their account may be temporarily suspended. The User can lift this suspension by following the steps specified by the Company.</p>
                    <p>5.8 The User can temporarily suspend their account at any time through the Company&rsquo;s service platform. This suspension will be effective once the Company receives notification and the required documents from the User. The Company will not be responsible for any damages not caused by the Company before the suspension of the service on the platform. If the User wishes to cancel the temporary suspension, they can contact the Company at the office or through other channels as specified by the Company.</p>

                    <p>5.9 Each User can only use one (1) mobile phone number for the service. The User certifies that they legally possess and can use the mobile phone number they register with and/or use for the Company&rsquo;s services and related services. The User consents to allow the mobile network provider to disclose necessary information to the Company for identity verification during registration and/or service usage, following the Company&rsquo;s procedures and criteria.</p>
                    <br />
                    <h3>Scope of Company Services on the Service Platform</h3>
                    <p>  6.1 The User acknowledges and agrees that the Company will provide one (1) user account per service user unless otherwise specified by the Company.</p>

                    <p>6.2 The User acknowledges that the Company&rsquo;s services are provided on the Company&rsquo;s service platform, as defined in this Agreement.</p>

                    <p>6.3 The User acknowledges and agrees that the Company&rsquo;s service platform may link to third-party services in various forms, such as websites, applications, software, or Public API (&ldquo;third-party services&rdquo;). These links are provided for the User&rsquo;s convenience. Allowing or permitting these links does not imply that the third-party services are endorsed, controlled, or the responsibility of the Company. Additionally, the Company is not an agent, representative, distributor, broker, client, partner, or authorized representative of these third parties. Therefore, the Company cannot guarantee the accuracy, reliability, or safety of third-party services and will not be liable for any loss or damage the User incurs from using or linking to third-party services. The User is responsible for thoroughly reviewing the terms and conditions of third-party services themselves.</p>

                    <p>The User also agrees that the Company has the sole and absolute right to decide whether to link or terminate the linking of third-party services, and the User will not dispute or claim against the Company for exercising this right.</p>

                    <p>The User must use their judgment carefully and cautiously when using information or content from third-party services for making decisions or using any services independently.</p>

                    <p>6.4 The User acknowledges that Public API is a method to connect the Company&rsquo;s service platform with third-party services. Such connections may experience issues, defects, delays, or incompleteness due to usage by the User and/or external parties involved in the Public API connection. Consequently, the Company cannot guarantee the completeness of the services or any services on the platform connected through Public API. The User agrees not to make any claims against the Company for damages, losses, or impacts arising from using the Company&rsquo;s services or accessing the Company&rsquo;s platform through Public API.</p>

                    <p>In addition, the User must not conduct any transactions through the Public API in a manner that could impact the service and/or the security of the Company&rsquo;s service platform. This includes but is not limited to behaviors such as sending commands or conducting transactions through the Public API multiple times within a short period, which could cause the Company&rsquo;s service platform to crash, slow down, delay the Company&rsquo;s services, or prevent other customers from accessing the Company&rsquo;s service platform as usual. The User acknowledges that the Company has the right to set conditions for the use of the Public API, including limiting rights and usage as appropriate. If the User uses the Public API in a manner that does not comply with the Company&rsquo;s terms and conditions, the User agrees to solely be responsible for, indemnify, and/or compensate for any damages, losses, and/or expenses incurred by the User, the Company, the company&rsquo;s directors, and/or third parties.</p>

                    <p>6.5 The User must ensure that their devices are not installed with any programs that might affect the service or the security of the Company&rsquo;s service platform, such as BOT programs or Remote Application. The User must not conduct transactions using such devices or programs multiple times within a short period, which could cause the Company&rsquo;s service platform to crash, slow down, delay the Company&rsquo;s services, or prevent other customers from accessing the service platform normally. The Company reserves the right to set conditions and limitations on the use of devices with such programs, including restricting rights and usage as appropriate. If the User uses any such programs in a manner that contradicts the Company&rsquo;s terms and conditions, the User agrees to solely be responsible for, indemnify, and/or compensate for any damages, losses, and/or expenses incurred by the User and/or the Company and/or the Company&rsquo;s directors and/or third parties.</p>

                    <p>6.6 The User acknowledges that the Company&rsquo;s service platform operates as a legal digital asset trading center, providing a central hub for buying, selling, or exchanging digital assets through order matching and facilitating users wishing to trade digital assets. The Company only provides services related to the Company&rsquo;s platform. Thus, the Company is not involved in being an authority, agency, advisory role, brokerage, representation, use, or support regarding any transactions or legal acts between the users on the platform and third parties outside of the Company&rsquo;s services and control.</p>

                    <p>The User agrees and acknowledges that the Company is not liable for any damages, losses, or impacts resulting from such transactions and legal acts. In addition, the User agrees to protect and hold the Company harmless from any liabilities, claims, lawsuits, or complaints arising from such transactions and legal acts.</p>

                    <p>6.7 The User acknowledges that the Company may allow the User to use services related to digital asset business operations as determined by the Company, for which the Company is solely responsible and/or operates exclusively (&ldquo;Other Company&rsquo;s services&rdquo;). The User agrees to grant the Company the right to set the terms and conditions for providing these additional services through the Company&rsquo;s service platform and/or other communication channels as determined by the Company. The User agrees to be bound by these terms and conditions.</p>

                    <p>6.8 The User acknowledges that the Company will send an annual report of the User&rsquo;s digital assets to the User. The delivery of such reports will be in accordance with the terms and conditions set by the company. However, the Company is not obligated to send the report if the User has no remaining digital assets in their user account and/or if their user account has been suspended. The User acknowledges that they can download the tax invoice for fees through the Company&rsquo;s service platform or through channels specified by the Company.</p>
                    <br />
                    <h3>Intellectual Property </h3>
                      
                    <p>  7.1 Any information of the Company, including but not limited to text, electronic data, letters, product and service names, images, videos, clips, numbers, data tables, graphs, colors, symbols, trademarks, font styles, prices or values of digital assets, various graphics, sounds, screen designs, interface designs, whether in the form of programs, software, or any other form, including all information that the User can download from the Company&rsquo;s service platform or any of the Company&rsquo;s communication channels (collectively referred to as the &ldquo;Content&rdquo;) is considered the property and intellectual property of the Company or the Company&rsquo;s licensors only. The User acknowledges and agrees that the Content is protected by intellectual property rights and/or other proprietary rights under Thai law and/or foreign laws (as the case may be), whether registered or not.</p>

                    <p>7.2 Unless otherwise specified by the Company, the User only has the right to view, download, print, disclose, publish, copy, send, or display the Content on the Company&rsquo;s service platform (as the case may be) solely for the benefit of their own digital asset investment or for educational purposes related to their digital asset investment.</p>

                    <p>7.3 The User agrees not to download, store, copy, reproduce, print, duplicate, modify, reverse engineer, create derivative works, develop, display, distribute, disclose, publish, sell, distribute, license, deliver, send, transfer, use, or exploit the Content on the Company&rsquo;s service platform, either by themselves or by others, in any of the following manners:</p>

                    <p>(1) For commercial benefit or to seek other benefits or returns beyond those specified in Clause 7 of the Intellectual Property whether such benefits or returns are monetary or not, unless prior written consent has been obtained from the Company.</p>

                    <p>(2) In a way that could cause damage, harm, tarnish the reputation, and/or undermine the credibility of the Company and/or related persons to the Company.</p>

                    <p>(3) In any other case unless prior written consent has been obtained from the Company.</p>

                    <p>7.4 The User acknowledges that the Company&rsquo;s intellectual property, including but not limited to trade names, product and service names, trademarks, service marks, and any other marks appearing on the Company&rsquo;s service platform, whether in any form and whether registered or not, are the intellectual property of the Company or the Company&rsquo;s licensors only.</p>
                    <br />
                    <h3>Accuracy of Content on the Company&rsquo;s Service Platform </h3>
                      
                    <p> 8.1 The User acknowledges and agrees that the content appearing on the Company&rsquo;s service platform may contain errors, inaccuracies, or non-material discrepancies, or may be outdated at any given time. Additionally, the Company reserves the right to change, modify, or remove the content on its service platform at any time without prior notice to the User. The Company only guarantees that the content appearing on its service platform is accurate, reliable, and appropriate in its material aspects. However, the Company does not guarantee the completeness or timeliness of the content or the accuracy of non-material content.</p>

                    <p>8.2 The User fully understands that any investment decision or transaction they make is their own decision. Prior to making any investment decisions, the User should study the information or consult with professional experts and exercise caution when considering investment or entering into any transaction to benefit their decision-making process.</p>

                    <p>8.3 The User acknowledges and agrees that the Company has the right to change, modify, or remove any information, announcements, policies, terms, or content related to the Company&rsquo;s services, including important information such as fees and criteria for selecting or delisting digital assets. The Company will notify the User from time to time.</p>
                    <br />
                 
                    <h3>Privacy of Other Service Users </h3>
                    <p>  9.1 Except for the exceptions provided in 9.2, if the User receives personal information of other users through the use of the Company&rsquo;s service platform, the User agrees to keep such personal information confidential and agrees not to disclose, publish, copy, or provide the personal information of other service users to any third party. The User must notify the Company of the receipt of such personal information promptly, but no later than twenty-four (24) hours from the time the User received such personal information. The User agrees to comply with all the Company&rsquo;s terms and conditions.</p>

                    <p>9.2 If the User receives personal information of other service users that falls under any of the following cases, the User is not required to notify the Company of the receipt of such personal information:</p>

                    <p>1) The User needs to know the personal information to complete a transfer transaction or a matching exchange transaction or any other similar actions to complete any transaction on the Company&rsquo;s service platform.</p>

                    <p>2) The User has obtained prior consent from the owner of the personal information.</p>

                    <p>The User agrees to store, use, or take necessary and appropriate actions concerning the personal information of other service users within the legal framework. The User must protect the Company from complaints, lawsuits, or any claims from the owner of the personal </p>
                    <p>information resulting from the User&rsquo;s receipt and/or use of the personal information of other service users or other persons as stipulated in 9.2.(2).</p>
                    <br />
                     <h3> Terms of Use for the Company&rsquo;s Service Platform</h3>
                      
                     <p> 10.1 The User acknowledges and agrees that:</p>

                    <p>1) Services on the Company&rsquo;s service platform are divided into 2 parts; 1) Services provided by the Company and 2) Services provided by third parties, which are under the responsibility of other operators who own or have control over those services, as outlined in clause 6.3.</p>

                    <p>2) When trading digital assets on the Company&rsquo;s service platform, the Company may place warning signs for investors under various circumstances as specified by the Company. Therefore, the User should closely monitor situations and exercise caution before making any decisions to invest, exchange, buy, sell, or conduct any transactions involving digital assets. The Company is not responsible for the User&rsquo;s decisions regarding the trading and investment in digital assets.</p>

                    <p>3) The User&rsquo;s account will include a digital asset wallet service and a USD wallet service, as well as any other services supported by the Company. The User agrees to strictly comply with the terms and conditions of this Agreement, as well as rules, regulations, guidelines, and terms related to the use of the User&rsquo;s account.</p>

                    <p>4) Placing orders and conducting any transactions are solely the right and discretion of the User. The Company has no involvement in the service User&rsquo;s decision to place orders or conduct transactions. The User accepts and willingly assumes the risks associated with the volatility of digital asset prices or values, and agrees not to claim any compensation or damages from the Company.</p>

                    <p>5) Unless otherwise provided by law, at the time the User invests, cryptocurrencies, digital tokens, and other types of digital assets (if any) that the User invests or transacts on the Company&rsquo;s service platform, the Company&rsquo;s services are considered digital asset services under the law governing digital asset businesses or other relevant laws and are effective. The Company&rsquo;s services on the service platform are not and do not constitute deposit-taking or asset deposit services. Moreover, investment in digital assets on the Company&rsquo;s service platform is not subject to principal, value, or price guarantees, whether by the government or the Company.</p>

                    <p>6) Digital assets are properties that cannot be specifically identified. Therefore, in placing orders and conducting various transactions involving digital assets, the Company will comply with the User&rsquo;s orders by executing transactions according to the type, kind, and quantity specified by the User. The Company may receive or deliver digital assets to the User in equivalent names, types, kinds, and quantities.</p>

                    <p>7) The Company has the discretion to set transaction limits for USD and/or digital assets, and the value of digital assets that the User can transact through their account on the Company&rsquo;s service platform, according to the Company&rsquo;s internal evaluation criteria. The User has the right to place orders and conduct any transactions within these transaction limits and digital asset values as stipulated by the Company&rsquo;s guidelines, terms, and conditions.</p>

                    <p>If the User places any transaction orders that do not comply with the transaction limits and digital asset values as per the Company&rsquo;s guidelines, terms, and conditions, the User acknowledges and agrees that the Company has the absolute and legitimate right to refuse, suspend, cancel, or take any actions regarding such orders as it deems appropriate. The User agrees not to dispute, claim damages, or demand any compensation from the Company due to such actions.</p>

                    <p>8) The Company will execute transactions only after receiving the User&rsquo;s order confirmation according to the Company&rsquo;s guidelines, terms, and conditions (&ldquo;Confirmed Orders&rdquo;). The User acknowledges and agrees that once the order is confirmed, it will remain effective, and the Company has the right to execute the confirmed order until the transaction is fully completed. The User understands that executing confirmed orders involves multiple steps, within the Company&rsquo;s digital asset trading system and the systems of service providers to the Company, blockchain operating systems, and the operating systems of digital asset issuers and developers, which are beyond the Company&rsquo;s control and cannot be intervened by the Company.</p>

                    <p>9) The User is responsible for carefully and prudently verifying all transactions under their account. The User agrees to be solely responsible for all consequences arising from completed transactions according to the confirmed orders and any transactions that cannot be completed or contain errors, for any reason, resulting from the User&rsquo;s orders. The User agrees not to claim any rights from the Company due to completed transactions according to the confirmed orders and transactions that cannot be completed or contain errors due to the User&rsquo;s orders. Furthermore, the User agrees to indemnify and hold the Company harmless from any lawsuits, litigation, claims, or liabilities, whether monetary or otherwise, penalties, or judgments under contracts, laws, administrative orders, orders from government or regulatory agencies, or court judgments, both domestically and internationally, arising from the Company&rsquo;s execution of confirmed orders, including but not limited to compensating the Company or any other party on behalf of the Company.</p>

                    <p>10) The Company has the right to verify the User&rsquo;s orders through the internet system, other electronic systems, or any other methods deemed appropriate by the Company to check the accuracy of the orders and/or to confirm that the User genuinely intends to conduct the transactions as per the orders placed through their account, and/or for any other purposes the Company deems appropriate.</p>

                    <p>11) The Company has the right to set rules, regulations, criteria, policies, terms, or conditions regarding the use of its services via the internet or other electronic systems. The Company will announce such criteria and conditions on the Company&rsquo;s service platform and/or through any other communication channels as determined by the Company. The Company has the right to cancel, amend, or change these criteria and conditions without prior notice but will notify the users from time to time.</p>

                    <p>12) The Company has sole discretion and rights in managing the distribution of digital assets to service users, known as Airdrop, as determined by the Company. The users agree not to make any claims against the Company for any decisions related to Airdrop. If the Company deems it appropriate to conduct an Airdrop of any digital assets, the Company will announce the schedule and relevant information to users through the Company&rsquo;s service platform or any other communication channels as determined by the Company.</p>

                    <p>13) The User accepts and acknowledges that the Company will not pay and is not obligated to pay any interest or returns to the User resulting from the User&rsquo;s retention of USD in the USD wallet or digital assets in the digital asset wallet provided by the Company to the User.</p>

                    <p>14) In case of any changes in the User&rsquo;s information or financial data and/or accounts connected to the Company&rsquo;s service platform, the User must promptly notify the Company of such changes and follow the procedures set by the Company.</p>

                    <p>To benefit the User in placing orders and conducting transactions, if the User ignores, neglects, or fails to notify and/or follow the Company&rsquo;s procedures as per the first paragraph, the User agrees and acknowledges that the Company is not responsible for any loss, damage, or impact arising from the User&rsquo;s neglect or failure to act, resulting in such loss or damage.</p>

                    <p>15) The User will place orders and conduct transactions on the Company&rsquo;s service platform in compliance with laws, rules, regulations, and in accordance with the criteria, conditions, and limitations set by the Company. Specifically, the User agrees and guarantees that they will not engage in any orders, transactions, actions, or behaviors that are or may be considered illegal, unfair in digital asset trading, or inappropriate investment or transaction behaviors. The User can review the criteria, conditions, and terms regarding order placement and transactions set by the Company through the Company&rsquo;s service platform and/or other communication channels as determined by the Company.</p>

                    <p>The User agrees not to claim any rights from the Company in case the Company takes any actions to prevent and stop orders, transactions, actions, or behaviors that are or may be considered illegal, unfair in digital asset trading, or inappropriate investment or transaction behaviors.</p>

                    <p>16) The User acknowledges and agrees that the Company has the sole right and discretion to suspend and/or halt any user accounts or transactions, whether temporarily or permanently, and/or to take actions to deduct or reclaim any excess money, digital assets, or benefits received by the User at any time without prior notice, in the following cases:</p>

                    <p>(1) The User has placed a deposit or withdrawal order for USD or digital assets, and the transaction has been successfully completed, but the service user has received an amount of USD or digital assets (as the case may be) that exceeds what they should have received from that order.</p>

                    <p>(2) The User has placed a buy or sell order for digital assets, and the transaction has been successfully completed, but any action related to the money or digital assets (as the case may be) was not completed according to the User&rsquo;s order, resulting in the User&rsquo;s failure to pay the debt or pay it incompletely.</p>

                    <p>(3) The User uses any other services of the Company outside of (1) or (2) mentioned above, and such services have been successfully completed, but the User received money or digital assets or other benefits (as the case may be) exceeding the amount they should have received from using such services, or any actions related to the money or digital assets or other benefits from the User&rsquo;s use of the services (as the case may be) were not completed, resulting in the User&rsquo;s failure to pay the debt or pay it incompletely.</p>

                    <p>(4) Any other cases as may be determined by the Company.</p>

                    <p> (Hereinafter referred to in this Agreement as &ldquo;Excessive assets received by the User&rdquo;).</p>

                    <p>The User acknowledges and agrees that they have no rights to the Excessive Assets, including but not limited to ownership, possession, retention, and/or usage rights. The User is obligated to promptly notify the Company about the Excessive Assets received, no later than one (1) day from the date they became aware of such assets, and must cooperate and comply with the Company&rsquo;s instructions to ensure the successful reclamation of the Excessive Assets.</p>

                    <p>In cases where the Company is unable to reclaim the Excessive Assets due to the User having sold, transferred, or moved the Excessive Assets out of their USD or digital asset wallet, or any other reasons preventing the Company from reclaiming the Excessive Assets, the User acknowledges and agrees to comply with the Company&rsquo;s directives as follows:</p>

                    <p>Transfer or deliver digital assets in the same currency and amount as the Excessive Assets received by the User to the Company as specified. Compensate the Company with an amount equivalent to the value of the Excessive Assets received by the User as determined by the Company. Take any other actions deemed appropriate by the Company to compensate for the Excessive Assets received by the User. The User acknowledges and agrees that if they neglect, ignore, or fail to comply with the above agreement, they demonstrate an intent to defraud and violate the Company&rsquo;s rights. The Company has the sole right and discretion to terminate the service platform without prior notice and to take any actions deemed appropriate, including legal proceedings against the User.</p>

                    <p>The User acknowledges and agrees that the Company shall not be liable for any damage, loss, or impact resulting from the exercise of the Company&rsquo;s rights as stated above.</p>

                    <p>17) The User agrees not to use the account opened with the Company to conduct any transactions related to the payment of goods or services with digital assets. If any User engages in such actions, the Company will consider it a violation of the Company&rsquo;s terms and conditions, and the Company may temporarily suspend or permanently terminate access to the services.</p>

                    <p>10.2 The User warrants that they acknowledge and agree to comply with the guidelines, conditions, and procedures related to placing orders and conducting transactions through the Company&rsquo;s service platform or other electronic media, and accept the risks that may arise in connection with placing orders and conducting transactions through the Company&rsquo;s service platform or other electronic media.</p>

                    <p>10.3 The User acknowledges and accepts that investing and conducting transactions related to digital assets carries risks. These risks may stem from the high volatility of digital asset values, the success, failure, or various issues of projects related to digital assets, the stability and trust in blockchain technology of digital assets, the digital asset network, technology, blockchain network systems, or the Company&rsquo;s operating systems and services. These include related parties within the ecosystem who are involved in or participate in the Company&rsquo;s operations and/or services, or changes in circumstances resulting from information, communication, or publicity from any party that may affect the price, value, and transaction volume of digital assets, which can occur at any time. In addition, system malfunctions or delays in providing services to the User, including but not limited to temporary suspension of certain functions or services for specific digital currencies, or system delays or instability during a certain period due to system adjustments or maintenance by the Company and/or external parties contracted by the Company for system services or maintenance, or force majeure, or any other special circumstances. The User understands, acknowledges, and agrees to accept the risks arising from investing and trading digital assets through the Company&rsquo;s services and/or the User&rsquo;s account, including any errors, defects, or malfunctions that may occur at any given time, as well as any damage or loss resulting from the User&rsquo;s inability to conduct transactions through the User&rsquo;s account promptly, or such transactions not being successfully completed due to computer, electrical, telecommunication, or other relevant system malfunctions. The Company shall not be liable for any risks, losses, or damages incurred by the user if the cause does not arise from the Company&rsquo;s actions.</p>
                    <br />
                            <h3>  Selection and Removal of Digital Assets for Service </h3>
                            <p>    11.1 Selection of Digital Assets</p>

                    <p>1) The Company will select digital assets to be provided on the Company&rsquo;s service platform based on criteria and conditions set by the Company. These include, but are not limited to, considerations of credibility, security, technological potential, liquidity, and other factors deemed relevant by the Company.</p>

                    <p>2) The selection of digital assets is solely at the Company&rsquo;s discretion. The User acknowledges and agrees that the Company&rsquo;s decision regarding the selection of digital assets is final, and the User has no right to dispute or make any claims against the Company.</p>

                    <p>11.2 Removal of Digital Assets</p>

                    <p>1) The Company reserves the right to remove digital assets provided on the Company&rsquo;s service platform at any time, at the Company&rsquo;s discretion, for any reason. These reasons include, but are not limited to, legal reasons, technical reasons, lack of liquidity, or other reasons deemed appropriate by the Company.</p>

                    <p>2) In the event of the removal of digital assets, the Company will notify the User in advance through channels deemed appropriate by the Company. The User is responsible for taking any necessary actions within the time frame specified by the Company. The Company is not liable for any loss or damage that may occur from the removal of digital assets.</p>

                    <p>3) The User acknowledges and agrees that the removal of digital assets may impact the value and liquidity of those digital assets. The Company is not liable for any damage or loss resulting from the removal of digital assets.</p>

                    <p>4) Unless otherwise specified by the Company, once the specified period for the service user to withdraw the digital assets has expired, the Company will close the system for withdrawing the removed digital assets. The Company has the right to consider retaining the digital assets remaining in the User&rsquo;s account (&ldquo;remaining digital assets&rdquo;) for the benefit of the User or take any actions deemed appropriate under the law or for the benefit of the User. This includes, but is not limited to, changing the blockchain network of one digital asset to another blockchain network supported by the Company&rsquo;s service platform, or changing one Stablecoin to another Stablecoin. The Company will notify the User in writing in advance.</p>

                    <p>5) The Company will select and remove digital assets in accordance with the criteria and regulations set by the Securities and Exchange Commission (SEC) to comply with all relevant laws and regulations.</p>

                    <p>11.3 Additional Conditions</p>

                    <p>1) The Company reserves the right to amend the criteria and conditions for the selection and removal of digital assets at its discretion without prior notice to the User. These amendments will take effect once announced through the Company&rsquo;s communication channels.</p>

                    <p>2) The User agrees to comply with all criteria and conditions set by the Company regarding the selection and removal of digital assets, including taking actions as notified by the Company in the event of the removal of digital assets.</p>
                    <br />
                   <h3>        Buying, Selling, Depositing, and Withdrawing Services</h3> 
                      
                   <p>  12.1 Buying and Selling Digital Assets</p>

                    <p>1) The User can buy and sell digital assets supported by the Company through the Company&rsquo;s service platform. The User must comply with the criteria, conditions, and procedures set by the Company.</p>

                    <p>2) The buying and selling of digital assets by the User must be in accordance with market prices and the conditions announced by the Company at that time. The User acknowledges that the prices of digital assets may fluctuate and change at any time.</p>

                    <p>3) The User is responsible for verifying the accuracy of their buy and sell orders before submitting them through the Company&rsquo;s service platform. All orders and transactions by the User will be considered final and cannot be canceled or modified unless the Company consents to them.</p>

                    <p>12.2 Depositing and Withdrawing Digital Assets</p>

                    <p>1) The User can deposit and withdraw digital assets supported by the Company through the Company&rsquo;s service platform. The User must comply with the criteria, conditions, and procedures set by the Company.</p>

                    <p>2) The User is responsible for verifying the accuracy of their deposit and withdrawal information before submitting it through the Company&rsquo;s service platform. All orders and transactions by the User will be considered final and cannot be canceled or modified unless with the Company&rsquo;s consent.</p>

                    <p>3) In case of depositing digital assets into the User&rsquo;s account, the Company will verify and confirm the accuracy of the transaction before the digital assets are credited to the User&rsquo;s account.</p>

                    <p>4) The User acknowledges and agrees that depositing and withdrawing digital assets may incur fees and expenses as determined by the Company, which will be notified to the User through the Company&rsquo;s service platform.</p>

                    <p>5) The User is responsible for ensuring that the deposit and withdrawal of digital assets are made through the correct and supported networks. If digital assets are deposited or withdrawn through incorrect or unsupported networks, or if unsupported digital assets are used, the Company is not liable for any loss or damage resulting from such actions.</p>

                    <p>6) The User acknowledges and agrees that if digital assets are sent through incorrect or unsupported networks, or if unsupported digital assets are used, the Company has the right to reject such deposits or withdrawals and is not responsible for any loss or damage arising from such transactions.</p>

                    <p>7) If the Company provides assistance in recovering assets due to incorrect transfers or errors on the part of the User, the User agrees that the Company has the right to charge fees and expenses for such recovery services, which will be determined and communicated by the Company in advance.</p>

                    <p>12.3 Additional Terms and Conditions</p>

                    <p>1) The User agrees and acknowledges that buying, selling, depositing, and withdrawing digital assets through the Company&rsquo;s service platform must comply with the criteria, requirements, and conditions set by the Company, including relevant laws, rules, regulations, and requirements.</p>

                    <p>2) The Company reserves the right to amend, change, or cancel the criteria, requirements, and conditions related to buying, selling, depositing, and withdrawing digital assets at its discretion without prior notice to the User. Such amendments, changes, or cancellations will take effect once announced through the Company&rsquo;s communication channels.</p>

                    <p>3) The User acknowledges and agrees that the Company is not responsible for any loss or damage arising from buying, selling, depositing, and withdrawing digital assets through the Company&rsquo;s service platform, except in cases of intentional misconduct or gross negligence by the Company.</p>

                    <p>12.4 Depositing and Withdrawing USD</p>

                    <p>1) The User can buy and sell digital assets supported by the Company through the Company&rsquo;s service platform, following the criteria, conditions, and procedures set by the Company.</p>

                    <p>2) The Company reserves the right to impose fees related to the depositing and withdrawing of USD, as performed under the Company&rsquo;s service scope. Details of such fees will be disclosed to the User before conducting the transaction.</p>

                    <p>3) The Company reserves the right to amend the fees for depositing and withdrawing USD as deemed appropriate. In the event of fee changes, the Company will notify the User through public channels before the changes take effect within a reasonable time frame.</p>

                    <p>4) The Company reserves the right to refuse to process deposits or withdrawals of USD if the transactions do not meet the specified conditions, including:</p>

                    <p>Deposits made from bank accounts with names that do not match the User&rsquo;s account or Withdrawals to bank accounts with names that do not match the User&rsquo;s account. Deposits using duplicate QR Codes or amounts not matching the order. Deposits made through channels not specified by the Company, such as E-wallet systems. The Company reserves the right to refuse transactions that do not meet the specified conditions and return funds only to the bank account used for the deposit and one that matches KYC requirements.</p>

                    <p>The User agrees to be responsible for and pay any fees the Company imposes for USD transactions on the USD wallet, including any fees related to transactions with commercial banks as charged by those banks.</p>

                    <p>The User acknowledges that the Company may change, modify, or cancel the criteria, procedures, requirements, and conditions related to depositing or withdrawing USD. The Company will notify the User periodically through the Company&rsquo;s service platform or other communication channels as specified by the Company.</p>
                    <br />
                    <h3>  Digital Asset Custody</h3>
                    <p> 13.1 The Company will store digital asset access credentials (Private Key) both online and offline in compliance with legal requirements.</p>

                    <p>13.2 The User acknowledges and agrees that the Company may need to retrieve certain information between offline and online systems to execute transactions according to the User&rsquo;s instructions, which may take longer than usual. The User agrees not to claim or seek damages or compensation from the Company for delays or any actions related to this process.</p>

                    <p>13.3 The User acknowledges that the Company will safeguard user assets, including both money and digital assets, according to legal requirements and conditions. The User&rsquo;s assets may not be under the Company&rsquo;s direct custody or control. For digital assets, the Company will disclose information and risks related to the custody of digital assets, including details about third-party custodians as required by law. Users are encouraged to review these details on the Company&rsquo;s website to manage their digital assets effectively.</p>

                    <p>13.4 The User acknowledges that the methods used by the Company to store money and/or digital assets may change according to legal requirements and conditions in effect.</p>
                    <br />
                   <h3> Fees, Service Charges, and Company Expenses </h3>
                   <p>    14.1 Collection of Fee and Service Charges</p>

                    <p>1) The Company has the right to charge fees and service charges for using the Company&rsquo;s service platform as determined by the Company. Such fees and charges will be communicated to the User through the Company&rsquo;s service platform.</p>

                    <p>2) The User agrees to pay the fees and service charges as determined by the Company and authorizes the Company to deduct these fees and charges from the User&rsquo;s account with prior notice.</p>

                    <p>14.2 Regarding the changes to fees and service charges, the Company reserves the right to change, modify, or cancel any fees and service charges at its discretion without prior notice to the User. Such changes, modifications, or cancellations will take effect once announced through the Company&rsquo;s communication channels.</p>

                    <p>14.3 Operational Expenses</p>

                    <p>1) The User agrees to be responsible for any costs arising from executing their instructions, including but not limited to transfer fees, deposit and withdrawal fees, transaction fees, and other related expenses.</p>

                    <p>2) If the Company must assist in recovering lost or incorrectly transferred digital assets or in cases of errors on the part of the User, the User agrees that the Company has the right to charge fees and expenses for such assistance. The Company will set and notify the User of these charges in advance. The Company does not guarantee or warrant that such assets will be able to be recovered or not.</p>

                    <p>14.4 Payment of Fees and Expenses</p>

                    <p>1) The User agrees to pay all fees and expenses as set by the Company. The company may deduct these fees and expenses from the User&rsquo;s account with prior notice.</p>

                    <p>2) If the User does not pay the fees and expenses as specified by the Company, the Company has the right to suspend or terminate the provision of our service platform to the User until all fees and expenses have been paid in full.</p>

                    <p>3) The Company has the right to charge additional fees and expenses incurred from using the Company&rsquo;s service platform, and will notify the User through the Company&rsquo;s communication channels.</p>

                    <p>4) The User agrees and acknowledges that using the Company&rsquo;s service platform constitutes acceptance of the fee, service charge, and expense conditions set by the Company.</p>
                    <br />
                    <h3> Temporary Suspension, Termination, and Cancellation of Service Access</h3>
                    <p>  15.1 Temporary Suspension</p>

                    <p>1) The Company has the right to temporarily suspend the User&rsquo;s access if the User exhibits behavior or performs actions inconsistent with the Company&rsquo;s terms and conditions, or if the Company reasonably believes the User may be engaging in activities contrary to the Anti-Money Laundering Act (AMLO), regulations of the Securities and Exchange Commission (SEC), or the laws and regulations of the Bank of Thailand (BOT).</p>

                    <p>2) In cases of orders from regulatory agencies (e.g., the Securities and Exchange Commission), law enforcement agencies (e.g., police), or court orders to suspend or freeze a user account, the Company must comply with such orders immediately without prior notice to the User.</p>

                    <p>3) Except where legally restricted, the Company will notify the User of the temporary suspension and the reasons for it through the communication channels registered by the User.</p>

                    <p>15.2 Termination of Service Access</p>

                    <p>1) The termination of service access for the User possibly occurs in the following cases:</p>

                    <p>The User requests to cancel the service by notifying the Company according to the method specified by the Company. The Company considers that the User has seriously violated the Company&rsquo;s terms and conditions, or has engaged in behavior that contravenes laws related to the Anti-Money Laundering Office (AMLO), the regulations of the Securities and Exchange Commission (SEC), or the laws and regulations of the Bank of Thailand (BOT). There is a legal order or court judgment requiring the termination of service access. 2) Upon termination of service access, the User must withdraw all digital assets and currencies from their account within the period specified by the Company.</p>

                    <p>15.3 Cancellation of Service Access</p>

                    <p>1) The Company has the right to cancel the User&rsquo;s service access if the User has seriously violated the Company&rsquo;s terms and conditions, or if the Company has reasonable grounds to believe that the User has engaged in behavior that contravenes laws related to the Anti-Money Laundering Office (AMLO), the regulations of the Securities and Exchange Commission (SEC), or the laws and regulations of the Bank of Thailand (BOT).</p>

                    <p>2) Unless there are legal restrictions, the Company will notify the User of the cancellation of service access along with the reasons for such cancellation through the communication channels registered by the User.</p>

                    <p>3) When the cancellation of service access takes effect, the User must withdraw all digital assets and currencies from their account within the period specified by the Company.</p>

                    <p>15.4 Effects of Temporary Suspension, Termination, and Cancellation of Service Access</p>

                    <p>1) In case of temporary suspension, termination, or cancellation of service access, the Company has the right to deny access to services, transactions, or the use of any services on the Company&rsquo;s service platform and will not be liable for any damages or losses incurred by the User.</p>

                    <p>2) The User agrees to be responsible for withdrawing all digital assets and currencies from their account within the period specified by the Company and acknowledges that the Company has no responsibility for the safekeeping or management of digital assets and currencies after such period.</p>
                    <br />
                      <h3>Limitation of Liability</h3> 
                      <p>   16.1 Limitation of Company&rsquo;s Liability</p>

                    <p>1) The Company will not be liable for any damages, losses, or expenses incurred from the use of the Company&rsquo;s service platform, except in cases where such damages result from the Company&rsquo;s negligence or illegal actions.</p>

                    <p>2) The Company will not be liable for any damages or losses arising from system errors or technical malfunctions beyond the Company&rsquo;s control, including but not limited to computer system failures, internet system failures, communication system failures, or other force majeure events.</p>

                    <p>3) The Company will not be liable for any damages or losses arising from the actions or omissions of users or third parties, including but not limited to unauthorized use of the User&rsquo;s account, erroneous transactions, provision of incorrect information, or illegal actions of the User.</p>

                    <p>4) The Company will not be liable for any losses or damages resulting from investments or transactions in digital assets with price volatility, including the risks associated with investing in digital assets.</p>

                    <p>5) The Company is not responsible for guaranteeing the completeness, accuracy, or reliability of information or content appearing on the Company&rsquo;s service platform, and the User agrees to use their own discretion when utilizing such information or content.</p>

                    <p>16.2 User&rsquo;s Liability</p>

                    <p>1) The User agrees to be responsible for any damages, losses, or expenses incurred directly or indirectly from the use of the Company&rsquo;s service platform, whether from their own actions or those of third parties using the User&rsquo;s account.</p>

                    <p>2) The User agrees not to make any claims or take any legal actions against the Company, the directors, shareholders, employees, consultants, contractors, agents, or any individuals of the Company in the event of any damages, losses, or expenses arising from the use of the Company&rsquo;s service platform.</p>

                    <p>3) The User agrees to defend and indemnify the Company against any claims, losses, damages, compensations, expenses, and legal costs arising from the User&rsquo;s actions or omissions, or actions that contravene the Company&rsquo;s terms and conditions.</p>

                    <p>16.3 Additional Limitation of Company&rsquo;s Liability</p>

                    <p>1) Investing in digital assets is a high-risk investment, and the User should consider and understand these risks before investing. The Company is not responsible for any losses or damages arising from investments in digital assets.</p>

                    <p>2) The Company reserves the right to amend or change the terms and conditions in this limitation of liability without prior notice. Such amendments or changes will take effect once the Company has announced them through the Company&rsquo;s communication channels.</p>

                    <p>3) The User agrees and accepts that using the Company&rsquo;s service platform constitutes acceptance of the limitations of liability as specified in this Agreement.</p>
                    <br />
                     <h3> Unclaimed Assets </h3>
                     <p> 17.1 Unclaimed assets refer to digital assets or currency remaining in the User&rsquo;s account that have had no activity or transactions for at least one year and the User has not contacted the Company or accessed the account during that period.</p>

                    <p>17.2 Management of Unclaimed Assets</p>

                    <p>1) The Company will attempt to contact the User with unclaimed assets to remind them to conduct a transaction or withdraw the assets from their account within a specified time frame.</p>

                    <p>2) If the User does not comply with the Company&rsquo;s reminder within the specified period, the Company has the right to take any appropriate actions according to the law, including freezing or transferring such assets to an account designated by the Company for holding unclaimed assets.</p>

                    <p>3) The Company may charge a custody fee for unclaimed assets as determined by the Company. This fee will be deducted from the value of the unclaimed assets.</p>

                    <p>17.3 Claiming Unclaimed Assets</p>

                    <p>1) The User wishing to claim unclaimed assets must contact the Company and follow the procedures specified by the Company, including identity verification and providing relevant account information.</p>

                    <p>2) The Company will review and consider the User&rsquo;s claim within a reasonable time. If the Company determines that the user has rights to the assets, it will return the assets to the user without additional fees, except for any custody fees previously charged.</p>

                    <p>17.4 Cancellation of Rights to Assets: If the User does not contact the Company to claim unclaimed assets within five years from the date the Company first attempted to contact the User, the assets will be considered permanently unclaimed. The Company has the right to manage such assets as deemed appropriate, including transferring the assets to charitable organizations or using the assets for public purposes as required by law.</p>

                    <p>17.5 Amendments and Changes: The Company reserves the right to amend or change the terms and conditions for managing unclaimed assets. The User will be notified in advance through the communication channels designated by the Company.</p>
                    <br />
                    <h3> Force Majeure and Special Circumstances</h3>
                    <p> 18.1 Definition of Force Majeure and Special Circumstances</p>

                    <p>1) Force majeure refers to any event or circumstance beyond the Company&rsquo;s control that cannot be prevented or remedied, even with reasonable care and effort. Force majeure includes, but is not limited to, natural disasters such as floods, earthquakes, fires, natural calamities, epidemics, wars, riots, terrorism, strikes, prohibitions or orders from government agencies, technical malfunctions of computer systems or internet networks, and other events beyond the Company&rsquo;s control.</p>

                    <p>2) Special circumstances refer to any event or situation that prevents the Company from operating or providing services normally, such as cancellation or suspension of services by third-party providers, cyber-attacks, data theft, severe technical malfunctions, etc.</p>

                    <p>18.2 Actions in Case of Force Majeure and Special Circumstances</p>

                    <p>1) In the event of force majeure or special circumstances, the Company has the right to suspend or restrict the provision of all or part of the services without prior notice to the User. However, the Company will notify the User as soon as possible through the communication channels designated by the Company.</p>

                    <p>2) The Company will not be liable for any loss, damage, or expense arising from force majeure or special circumstances, including the suspension or restriction of services.</p>

                    <p>18.3 Regarding the service restoration and remediation, the Company will make reasonable efforts to rectify and restore the services to normal operation as quickly as possible after the cessation of force majeure or special circumstances. However, the Company cannot guarantee the timeframe or success of such rectification and restoration.</p>

                    <p>18.4 Regarding the notifications and announcements, the Company will notify and announce to the User the status of the force majeure or special circumstances, including the rectification and restoration of services, through the communication channels designated by the Company.</p>

                    <p>18.5 Responsibilities of the User</p>

                    <p>1) The User agrees not to assert any claims or take any legal actions against the Company in the event of force majeure or special circumstances, including any loss, damage, or expense arising from such events.</p>

                    <p>2) The User should follow the Company&rsquo;s recommendations and guidelines in the event of force majeure or special circumstances to ensure the security and stability of service usage.</p>

                    <p>18.6 Regarding the amendments and changes, the Company reserves the right to amend or change the terms and conditions for managing force majeure and special circumstances. The User will be notified in advance through the communication channels designated by the Company.</p>
                    <br />
                    <h3> Termination of the Agreement</h3>
                    <p> 19.1 Termination by the Company</p>

                    <p>1) The Company has the right to terminate this Agreement immediately without prior notice if the User behaves or acts in serious violation of the terms and conditions of this Agreement, the Company&rsquo;s regulations, or engages in behavior that may constitute illegal activities, including but not limited to money laundering, fraud, computer-related crimes, or action that contravenes laws related to the Anti-Money Laundering Office (AMLO), the regulations of the Securities and Exchange Commission (SEC), or the laws and regulations of the Bank of Thailand (BOT).</p>

                    <p>2) The Company will notify the user of the termination of the Agreement along with the reasons for such termination through the communication channels registered by the User.</p>

                    <p>3) Upon termination of the Agreement, the User must withdraw all digital assets and currency from their account within the timeframe specified by the Company. If the User does not comply, the Company has the right to manage the assets as it deems appropriate within the legal framework.</p>

                    <p>19.2 Termination by the User: The User has the right to terminate this Agreement at any time by notifying the Company in writing through the communication channels specified by the Company. The termination will be effective once the Company has received and confirmed the termination notice from the User. After termination, the User must withdraw all digital assets and currency from their account within the timeframe specified by the Company.</p>

                    <p>19.3 Effects of Termination</p>

                    <p>1) In the event of termination, the Company has the right to deny access to services, transactions, or the use of any services on the Company&rsquo;s platform and will not be liable for any damage or loss incurred by the User.</p>

                    <p>2) The User agrees to be responsible for withdrawing all digital assets and currency from their account within the timeframe specified by the Company. The User acknowledges that the Company is not responsible for the safekeeping or maintenance of digital assets and currency after this period.</p>

                    <p>19.4 In the event of an order from a government agency and/or a court order or judgment to terminate the Agreement, the Company must comply with such order immediately and will not be liable for any loss or damage resulting from the termination as per such order.</p>

                    <p>19.5 For additional provisions, the Company reserves the right to amend or change the terms and conditions of this termination clause by notifying the User in advance through the communication channels specified by the Company.</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </section>
  );
}

export default TermsCondition;