import React from "react";

const MaitancePage = () => {

    return (
        <> 
             <marquee>Our website is currently undergoing maintenance as we update the system and database. We expect to be back online in 2-3 hours. Thank you for your patience!</marquee>
        <div style={{margin:'0px',padding:'0px',height:'100vh',width:'100vw',display:'flex' ,justifyContent:'center',background:'#ffffff'}}>
       

        <img src="/images/maintance.jpg" className="img-fluid"  />             
    </div>
    </>
    );
    
}

export default MaitancePage;