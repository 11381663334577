import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const BlogList = () => {

  let blogContent = [
    {
      title: 'Why Do Crypto Traders Need Trading Journals?',
      description: "Trading cryptocurrencies is like riding a rollercoaster — thrilling, unpredictable, and sometimes downright...",
      image: "/images/blogs/blog1.webp",
      index: "blog1"
    },
    {
      title: 'What is PEPE? Will it Lead to the Next Rise of Memecoin?',
      description: "The cryptocurrency world is constantly evolving, with new projects, coins, and trends emerging all the tim...",
      image: "/images/blogs/blog2.webp",
      index: "blog2"
    },
    {
      title: 'Riding the Green Wave: Smart Strategies for Thriving Safely in a Booming Market',
      description: "Trading cryptocurrencies is like riding a rollercoaster — thrilling, unpredictable, and sometimes downright...",
      image: "/images/blogs/blog2.webp",
      index: "blog3"
    },
    {
      title: 'Mastering the ‘Rising and Falling Three Methods’: Use These Patterns for Smarter Crypto Trades',
      description: "When I trade on CV Trade, I find that understanding market patterns gives me a competitive edge. As a leading cr...",
      image: "/images/blogs/blog4.webp",
      index: "blog4"
    },
    {
      title: 'Crypto Technical Analysis Made Simple: A Beginner’s Guide to the Basics',
      description: "Cryptocurrency trading can be an exhilarating experience, but it can also be quite daunting for beginne...",
      image: "/images/blogs/blog5.webp",
      index: "blog5"
    },
    {
      title: 'The Benefits of Trading Altcoins: Diversifying Your Portfolio',
      description: "Cryptocurrency has revolutionized the world of finance, with Bitcoin often taking center stage...",
      image: "/images/blogs/blog6.webp",
      index: "blog6"
    },
    {
      title: 'The Top 5 Crypto Futures Trading Mistakes And How to Avoid Them!',
      description: "Cryptocurrency has revolutionized the world of finance, with Bitcoin often taking center stage...",
      image: "/images/blogs/blog7.webp",
      index: "blog7"
    },


  ];
  const [blogs, setblogs] = useState(blogContent);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const navigate = useNavigate();

  // ***** Redirect To Blog Details Page *****//
  const nextPage = (data) => {
    navigate(`/blogdetails?${data}`);
  };




  return (
    <>
      <section className="inner-page-banner bg-2 bg-image">
        <div className="container">
          <div className="inner text-center">
            <h1 className="title">CV Trade Blog</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Blog</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className="pt-120 pb-90 blog_list">
        <div className="container">
          <div className="row" >

            <div className="col-xl-8 col-lg-8 " >
              <div className="row">
                {blogContent?.length > 0 ? blogContent?.map((item, index) => {

                  return (
                    <div key={index} className="col-xl-6 col-lg-12 mb-5" onClick={() => { nextPage(item?.index) }}>
                      <div className="single-blog h-100">
                        <a  className="thumb  cursor-pointer">
                          <img src={item?.image} alt="nft blog" />
                        </a>
                        <div className="content">
                          <ul className="meta">
                          </ul>
                          <h4 className="title"><h4>{item?.title}</h4>
                            <small>
                              {item?.description}
                            </small>
                          </h4>
                        </div>
                      </div>
                    </div>
                  )
                }) : <p className="text-center no-data h-100 mb-0" >
                  <img src="/images/no-data.png" className='img-fluid mb-2' alt="no data" width="52" />
                  No blog found
                </p>}
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 " >
              <aside className="sidebar">
                <div className="single-widget recent-post mt-5 ">
                  <h3 className="title">Trending</h3>
                  <div className="inner">
                    <ul className="list_with_img"  >
                      {blogs?.map((item, index) => {
                        return (
                          <li key={index} className="d-flex mt-3 justify-content-between align-items-start" onClick={() => { nextPage(item?.index) }} >
                            <div>
                              <a className="d-block mt-0 cursor-pointer" >{item?.title} </a>
                            </div>
                            {/* <img src="/images/authors/1.jpg" className="img-fluid" width="70" /> */}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>

              </aside>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogList;
