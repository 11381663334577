import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ProfileContext } from "../../context/ProfileProvider";

const Footer = () => {
    const { setActiveTab } = useContext(ProfileContext)

    const handleupper = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-12 pb-2">
                        <Link className="navbar-brand" to="/" onClick={handleupper}>
                            <img src="/images/logo.svg" className="show_light" />
                            <img src="/images/logo_dark_m.svg" className="show_dark" />
                        </Link>
                        <p className="my-4 footer_text">CVtrade is a blockchain-based banking platform for crypto traders and investors, and aims to connect the world of traditional finance and cryptocurrencies.</p>
                        <div className="social">
                            <a className="icon-facebook" target="_blank" href="https://www.facebook.com/cvtrade"><i className="ri-facebook-line"></i></a>
                            <a className="icon-facebook" target="_blank" href="https://www.instagram.com/officialcvtrade/"><i className="ri-instagram-line"></i></a>
                            {/* <a className="icon-twitter" target="_blank" href="https://www.reddit.com/user/Cvtrade/?utm_source=share&utm_medium=web3x&utm_name=web3xcss&utm_term=1&utm_content=share_button"><i className="ri-reddit-line"></i></a> */}
                            <a className="icon-instagram" target="_blank" href="https://www.youtube.com/@CVTrade"><i className="ri-youtube-line"></i></a>
                            <a className="icon-linkedin" target="_blank" href="https://t.me/cvtradeexchange"><i className="ri-telegram-line"></i></a>
                            <a className="icon-linkedin" target="_blank" href="https://cvtrade.medium.com/"><i className="ri-medium-line"></i></a> 
                            <a className="icon-linkedin" target="_blank" href="https://www.quora.com/profile/CV-Trade"><i className="" >Q</i></a>
                            <a className="icon-linkedin" target="_blank" href=" https://x.com/CvTradeExchange"><i className="ri-twitter-line"></i></a>
                        </div>
                    </div>
                    {/* <div className="col-lg-2 col-md-6 col-12 mb-4 mb-lg-0 border-start ps-md-4">
                        <h5 className="site-footer-title mb-3">About Us</h5>
                        <p className="text-white d-flex"><Link to="/blogs" className="site-footer-link" onClick={handleupper}> Blog </Link></p>
                        <p className="text-white d-flex"><a target="_blank" rel="noreferrer" href="https://docs.google.com/document/d/1QkwYgB0VWzPPc_Eomb3VUmualeerEksiYsQ2-NFT-Ic/edit?usp=sharing" className="site-footer-link" onClick={handleupper}>Terms of use</a></p>
                        <p className="text-white d-flex"><a target="_blank" rel="noreferrer" href="https://docs.google.com/document/d/1-vEBcDefu6Xh78BwmvJdu-jyIPuuczlEZpbhNRqx-gU/edit?usp=sharing" className="site-footer-link" onClick={handleupper}>Privacy policy</a></p>
                        <p className="text-white d-flex"><a target="_blank" rel="noreferrer" href="https://docs.google.com/document/d/1SNTIes9OLCl6u_DtHNQW7SMfzzdut9nKzjP5YucbLl8/edit?usp=sharing" className="site-footer-link" onClick={handleupper}>Aml Policy</a></p>
                        <p className="text-white d-flex"><a target="_blank" rel="noreferrer" href="https://docs.google.com/document/d/1ZycK1FigiZiWAHuoXM1nRnGmpWf9ieygPIyD3XywLDQ/edit?usp=sharing" className="site-footer-link" onClick={handleupper}>Cookie Policy</ a></p>
                    </div> */}
                    <div className="col-lg-2 col-md-6 col-12 mb-4 mb-lg-0 border-start ps-md-4">
                        <h5 className="site-footer-title mb-3">About Us</h5>
                        <p className="text-white d-flex"><Link to="/blogs" className="site-footer-link" onClick={handleupper}> Blog </Link></p>
                        <p className="text-white d-flex"><Link to="/terms_conditions" className="site-footer-link" onClick={handleupper}>Terms of use</Link></p>
                        <p className="text-white d-flex"><Link to="/privacy_policy" className="site-footer-link" onClick={handleupper}>Privacy policy</Link></p>
                        <p className="text-white d-flex"><Link  to="/aml_policy" className="site-footer-link" onClick={handleupper}>Aml Policy</Link></p>
                        <p className="text-white d-flex"><Link to="/cookie_policy" className="site-footer-link" onClick={handleupper}>Cookie Policy</ Link></p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0  border-start ps-md-4">
                        <h5 className="site-footer-title mb-3">Service</h5>
                        <p className="text-white d-flex"><Link to="/token" className="site-footer-link" onClick={handleupper}>CV Tokens </Link></p>
                        <p className="text-white d-flex"><Link to="/coin_list" className="site-footer-link" onClick={handleupper}>List your coin </Link></p>
                        {/* <p className="text-white d-flex"><Link to="/academy" className="site-footer-link">Academy</Link></p> */}
                        <p className="text-white d-flex"><Link to="/refrefer_earn" className="site-footer-link" onClick={handleupper}>Referral program</Link></p>
                        <p className="text-white d-flex"><Link to="/partnership" className="site-footer-link" onClick={handleupper}> Partnership </Link></p>
                    </div>
                    <div className="col-lg-2 col-md-6 col-12 mb-4 mb-lg-0  border-start ps-md-4">
                        <h5 className="site-footer-title mb-3">Contact</h5>
                        <p className="text-white d-flex"><Link to="/contactus" className="site-footer-link" onClick={handleupper}>Help Center</Link></p>
                        <p className="text-white d-flex"><Link to="/fees" className="site-footer-link" onClick={handleupper}>Fees</Link></p>
                        <p className="text-white d-flex"><Link to="/security_system" className="site-footer-link" onClick={handleupper}>Security</Link></p>
                        <p className="text-white d-flex"><Link to="/user_profile" className="site-footer-link" onClick={() => { handleupper(); setActiveTab('Support') }}>Support</Link></p>
                    </div>
                </div>
                <div className="site-footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12 mt-4 text-center">
                                <p className="copyright-text"> Copyright © 2024 <b className="text-gradient" > CV Trade </b>. All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>


    )
}

export default Footer
