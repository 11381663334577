import React from "react";
import { Link } from "react-router-dom";

const AmlPolicy = () => {

  return (
    <section className="  section-padding feature_bg pc_bg  login_sec" >
      <div className="container">
        <div className="inner text-center">
          <h2 className="title"> AML POLICY </h2>
          <nav className="mt-4">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">AML POLICY</li>
            </ol>
          </nav>
        </div>
      </div>
      <section className="pb-90 pt-5 ">
        <div className="container">
          <div className="row" >
            <div className="col-md-10 m-auto" >
              <div className="create-item-wrapper plicy_sec" >
                <div className="row align-items-center py-5">
                  <div className="col-lg-12">
                    <div>
                      <h2>CV Trade Anti-Money Laundering (AML) Policy</h2>

                      <h3>1. Introduction</h3>

                      <p>CV Trade (hereinafter referred to as &quot;the Company&quot;) is a financial institution under the relevant Anti-Money Laundering regulations. The Company is required to comply with applicable laws, including but not limited to the Prevention of Money Laundering Act and related rules and regulations issued by the Anti-Money Laundering Office (AMLO) and the Securities and Exchange Commission (SEC). The Company is committed to preventing and suppressing money laundering, combating the financing of terrorism (CFT), and countering the proliferation of weapons of mass destruction. This policy outlines the Company's approach to fulfilling its legal and ethical obligations in these areas.</p>
<hr/>
                      <h3>2. Objective</h3>

                      <p>This policy aims to establish guidelines for assessing, managing, and mitigating risks related to money laundering, financing of terrorism, and proliferation of weapons of mass destruction within the organization. It also provides a framework for preventing such activities, including measures for managing risks associated with new products, services, or technologies.</p>
                      <hr/>
                      <h3>3. Policy Formulation and Approval</h3>

                      <p>The Compliance Department is primarily responsible for implementing the Company's AML/CFT policies and risk management guidelines. The Company's Board of Directors and the Chief Executive Officer shall consider and approve these policies and guidelines.</p>
                      <hr/>
                      <h3>4. Risk Management Measures</h3>

                      <p>The Compliance Department and the Control and Risk Management Department are responsible for establishing measures to control AML/CFT risks that may arise from transactions or business relationships related to digital assets and any other services provided by the Company.</p>
                      <hr/>
                      <h3>5. Roles and Responsibilities</h3>

                      <p>5.1 Executive Board Set regulations, orders, or measures regarding the Company's business operations to ensure efficient implementation of AML/CFT policies. 5.2 AML Committee, Audit Committee, and Risk Management Committee Responsible for setting risk control measures and internal controls related to AML/CFT. 5.3 AML/CFT Personnel Responsible for preparing transaction reports, customer identification, and due diligence. Ensure compliance with customer acceptance policies and relevant laws. 5.4 Compliance Department Act as a knowledge center for AML/CFT measures and policies. Coordinate with the Anti-Money Laundering Office. Provide advice on compliance with AML/CFT laws and transaction reporting. Monitor new techniques and measures in AML/CFT. Review and propose updates to AML/CFT policies and procedures. 5.5 Internal Audit Department Conduct independent audits of AML/CFT operations. Prepare internal audit reports for senior management and the Audit Committee.</p>
                      <hr/>
                      <h3>6. Training</h3>

                      <p>All personnel performing AML/CFT duties must undergo initial training before starting their work and receive continuous training throughout their employment. Training should be updated when laws or regulations change.</p>

                      <h3>7. Employee Screening</h3>
                      <hr/>
                      <p>The Human Resources department shall implement high-standard procedures for selecting personnel, including criminal background checks and screening against lists of designated individuals, prior to hiring for AML/CFT duties.</p>
                      <hr/>
                      <h3>8. Policy Review and Update</h3>

                      <p>The Company shall review and update the AML/CFT Policy and internal control procedures at least annually or when deemed necessary to align with changes in risks or regulations.</p>
                      <hr/>
                      <h3>9. Know Your Customer (KYC) and Customer Due Diligence (CDD)</h3>

                      <p>9.1 Customer Acceptance Procedures Strict compliance with official regulations and the Company's customer acceptance policy. Require customers to provide identification information and evidence as specified by the Company. Implement both face-to-face and non-face-to-face customer onboarding processes. 9.2 Customer Verification Verify customer identity using secure and reliable electronic systems. Continuously update and check customer information against lists of designated persons received from the AMLO. 9.3 High-Risk Customers Implement enhanced due diligence measures for high-risk customers. Consider factors such as nationality, residence, and connection to high-risk countries.</p>
                      <hr/>
                      <h3>10. Transaction Monitoring and Reporting</h3>

                      <p>10.1 Ongoing Monitoring Monitor financial movements and transactions throughout the business relationship. Implement systems to detect suspicious transactions. 10.2 Reporting Requirements Submit Cash Transaction Reports (CTRs) and Suspicious Transaction Reports (STRs) to the AMLO within specified timeframes. Report any transactions related to designated persons immediately.</p>
                      <hr/>
                      <h3>11. Risk Assessment</h3>

                      <p>11.1 Organizational Risk Assessment Conduct an AML/CFT risk assessment at least annually. Consider factors including customer risk, geographic risk, product/service risk, and delivery channel risk. 11.2 New Products and Technologies Assess AML/CFT risks before launching new products, services, or technologies. Implement appropriate risk mitigation measures based on the assessment results.</p>
                      <hr/>
                      <h3>12. Record Keeping</h3>

                      <p>Retain customer identification information, transaction details, and due diligence records for at least 10 years from the date of account closure or termination of the relationship. Ensure that records can be readily retrieved and provided to authorities upon request.</p>
                      <hr/>
                      <h3>13. Information Sharing and Confidentiality</h3>

                      <p>Maintain strict confidentiality regarding AML/CFT investigations and reports. Share information with other financial institutions or authorities only as required by law or with customer consent.</p>
                      <hr/>
                      <h3>14. Sanctions Compliance</h3>

                      <p>Regularly screen customers and transactions against relevant sanctions lists. Implement procedures to freeze assets and reject transactions as required by applicable sanctions regulations.</p>
                      <hr/>
                      <h3>15. Policy Adherence and Disciplinary Action</h3>

                      <p>All employees are required to adhere to this AML/CFT policy. Violations of this policy may result in disciplinary action, up to and including termination of employment.</p>
                      <hr/>
                      <h3>16. Contact Information</h3>

                      <p>For any queries or to report suspicious activities, please contact: Compliance Department Email: compliance@cvtrade.io This policy is effective immediately and supersedes all previous AML/CFT policies of CV Trade.</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </section>
  );
}

export default AmlPolicy;