import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { ApiConfig } from "../../api/apiConfig/apiConfig";
import { ProfileContext } from "../../context/ProfileProvider";
import moment from 'moment';
import AuthService from '../../api/services/AuthService';


const AuthHeader = (props) => {

  const { newStoredTheme, handleTheme } = useContext(ProfileContext);
  const [notifications, setNotifications] = useState();
  const navigate = useNavigate();


  const logOut = () => {
    sessionStorage.clear();
    navigate("/");
    window.location.reload()
  }

  const handlToggle = () => {
    const element = document.getElementById('navbarNav');
    const element2 = document.getElementById('main_header');
    const element3 = document.getElementById('brand');
    const element4 = document.getElementById('toggleButton');
    if(element){
      element.classList.remove('show');
    }
    if( element2 ){
      element2.classList.remove('show');
    }
    if(element3 ){
      element3.classList.remove('show');
    }
    if( element4){
      element4.classList.add('collapsed');
    }
 
  };



  useEffect(() => {
    handleNotifications();
  }, [])

  const handleNotifications = async () => {
    await AuthService.notifications().then(async (result) => {
      if (result?.data?.length > 0) {
        try {
          setNotifications(result?.data?.reverse());
        } catch (error) {

        }
      }
    });
  };


  return (
    <nav className="navbar navbar-expand-lg head_login" >
      <div className="container-fluid">
        <Link to="/" className="navbar-brand" id="brand" onClick={handlToggle}>
          <img alt="" src="/images/logo.svg" className=" show_light" />
          <img alt="" src="/images/logo_dark_m.svg" className="show_dark" />
        </Link>
        <div className="collapse navbar-collapse justify-content-start" id="navbarNav" >
          <ul className="navbar-nav align-items-lg-center ms-md-4">
            <li className="nav-item">
              <Link to="/market" className="nav-link click-scroll" onClick={handlToggle} >
                Market
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/trade/Header" className="nav-link click-scroll" onClick={handlToggle}>
                Trade
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/comingsoon" className="nav-link click-scroll" onClick={handlToggle}>P2P</Link>
            </li>
            <li className="nav-item">
              <Link to="/partnership" className="nav-link click-scroll" onClick={handlToggle}> Partnership </Link>
            </li>
            <li className="nav-item">
              <Link to="/blogs" className="nav-link click-scroll" onClick={handlToggle}>Blogs</Link>
            </li>
            <li className="nav-item">
              <Link to="/refrefer_earn" className="nav-link click-scroll" onClick={handlToggle}   >Refer & Earn</Link>
            </li>
            <li className="nav-item">
              <Link to="/quick_buy_sell" className="nav-link click-scroll" onClick={handlToggle} >Quick Buy / Sell</Link>
            </li>
          </ul>

        </div>
        <div className="nav_right push_btn_nr" id='main_header' >

          <Link className=" btn-link-icon px-0 pe-2 mx-md-2 bell_icon" id="theme-toggle-btn " >
            <div className='header__activity' >
              <span className='brll_alert' ></span>
              <i className="ri-notification-2-line"></i>
            </div>
            <div className="header__body js-header-body">
              <div className="header__title">Notifications</div>
              <div className="header__list scroll_y">

                {notifications?.length > 0 ? (
                  notifications?.map((item, index) => (
                    <span className="header__notification" key={index}>
                      <div className="header__subtitle">{item?.title}</div>
                      <div className="header__date">
                        {moment(item?.createdAt).format(
                          "MMMM Do YYYY, h:mm:ss A"
                        )}
                      </div>
                    </span>
                  ))
                ) : (
                  <div className="py-5 py-5 text-center" >
                    <p className="mb-0 text-center">No Notifications Available</p>
                  </div>
                )}
              </div>
              {notifications?.length > 0 ?
                <div className="header__btns">
                  <Link className="btn custom-btn custom-border-btn  custom-border-btn-white btn-block w-100 btn-sm" to="/notification">View all</Link>
                </div>
                :
                ""
              }
            </div>

          </Link>

          <Link to="/funds_details" className="btn custom-btn custom-border-btn  custom-border-btn-white me-2 btn-sm  d-none d-md-inline-flex"  >
            <i className="ri-wallet-fill me-2 d-inline-flex d-md-none "></i>
            <span> Wallet </span>
          </Link>

          <Link className=" btn-link-icon px-1 me-2" id="theme-toggle-btn " onClick={() => handleTheme()}>{newStoredTheme === 'dark' ?
            <i className="ri-sun-fill"></i> : <i className="ri-sun-line"></i>}
          </Link>




          <div className="avatar-info custom-dropdown cursor-pointer me-md-0" >
            {!props?.userDetails?.profilepicture ? <img alt='' className="me-md-0" src="/images/user.png" data-bs-toggle="dropdown" aria-expanded="false" /> :
              <img alt='' className="me-md-0" src={`${ApiConfig.baseUrl + props?.userDetails?.profilepicture}`} data-bs-toggle="dropdown" aria-expanded="false"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/images/user.png";
                }} />}
            <div className="dropdown-menu">
              <Link className="header__el" to="/user_profile">
                <i className="ri-user-line"></i>
                <div className="header__details">
                  <div className="header__title">Account Settings</div>
                  <div className="header__content">Important account details</div>
                </div>
              </Link>
              <Link className="header__el" to="/funds_details">
                <i className="ri-wallet-line"></i>
                <div className="header__details">
                  <div className="header__title">Wallet</div>
                  <div className="header__content"> View Your Estimated Portfolio  </div>
                </div>
              </Link>

              <div onClick={logOut} className="header__el" to="/funds_details">
                <i className="ri-logout-circle-r-line" ></i>
                <div className="header__details">
                  <div className="header__title">Logout</div>
                </div>
              </div>
            </div>
          </div>
          <button className="navbar-toggler collapsed ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" id='toggleButton'>
            {/* <span className="navbar-toggler-icon"></span> */}
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
      </div>
    </nav>
  )
}

export default AuthHeader
