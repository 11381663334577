import React, { useState, useEffect, useContext } from "react";
import { validateEmail, notEqualsZero, validateNumber, } from "../../../utils/Validation";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponents/CustomAlertMessage";
import DefaultInput from "../../../customComponents/DefaultInput";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import { ProfileContext } from "../../../context/ProfileProvider";

const SettingsPage = (props) => {

  const { userDetails, handleUserDetails } = useContext(ProfileContext);

  const [emailId, setEmailId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [myfile, setMyfile] = useState('');
  const [localSelfy, setLocalSelfy] = useState("");
  const [disableBtn, setDisbaleBtn] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const [mobileOtp, setMobileOtp] = useState("");


  useEffect(() => {
    setEmailId(props?.userDetails?.emailId);
    setMobile(props?.userDetails?.mobileNumber);
    setFirstName(props?.userDetails?.firstName);
    setLastName(props?.userDetails?.lastName);
    setMyfile(props?.userDetails?.profilepicture);
  }, [props, userDetails]);

  const handleChangeSelfie = async (event) => {
    event.preventDefault();
    const fileUploaded = event.target.files[0];
    const imgata = URL.createObjectURL(fileUploaded);
    setMyfile(fileUploaded);
    setLocalSelfy(imgata);
  };


  const handleGetOtp = async (emailorMobile, type) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getOtp(emailorMobile, type).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        try {
          alertSuccessMessage(result?.message);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    });
  };

  const handleSettings = async (firstName, lastName, emailId, mobile, emailOtp, mobileOtp) => {
    var formData = new FormData();
    formData.append("profilepicture", myfile);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("emailId", emailId);
    formData.append("mobileNumber", mobile);
    formData.append("eotp", emailOtp);
    formData.append("motp", mobileOtp);
    LoaderHelper.loaderStatus(true);
    await AuthService.updateSettings(formData).then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          alertSuccessMessage(result?.message);
          handleUserDetails();
        } catch {
          alertErrorMessage(result?.message);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result?.message);
      }
    });
  };


  return (
    <div className="tab-pane" id="SecurityPill" role="tabpanel" aria-labelledby="Security-pill">
      <div className="row">
        <div className="col-md-12 col-lg-8 m-auto">
          <div className="form-field-wrapper switch_btn  border-dashed border-gray-300 bg-lighten card-rounded p-md-4 p-3">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="img-prev" >
                    <div className="avatar-preview">
                      {!myfile && !localSelfy ? (<img src="/images/user.png" className="img-fluid" />) : (
                        <img src={!localSelfy ? `${ApiConfig.baseUrl}${myfile}` : localSelfy} alt="User Avatar" className="img-fluid" 
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/images/user.png";
                      }}/>
                      )}
                    </div>
                    <div className="avatar-edit">
                      <input type="file" id="imageUpload" name="myfile" onChange={handleChangeSelfie} />
                      <label htmlFor="imageUpload">
                        <i className="ri-upload-cloud-2-line me-2"></i> Upload Photo
                      </label>
                      <small> Click button to upload photo </small>
                    </div>
                  </div>

                </div>
                <div className="col-md-12 mb-3 mb-md-4">
                  <div className="field-box">
                    <label htmlFor="text" className="form-label"> First Name </label>
                    <DefaultInput type="text" name="firstName" className="form-control" aria-describedby="First Name" placeholder="Enter Your First Name" value={firstName === "undefined" ? "" : firstName} onChange={(e) => setFirstName(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-12  mb-3 mb-md-4">
                  <div className="field-box">
                    <label htmlFor="text" className="form-label"> Last Name </label>
                    <DefaultInput className="form-control" type="text" name="lastName" placeholder="Enter Last Name" value={lastName === "undefined" ? "" : lastName} onChange={(e) => setLastName(e.target.value)} />
                  </div>
                </div>
                <div className="col-md-12  mb-3 mb-md-4">
                  <div className="field-box">
                    <label htmlFor="text" className="form-label"> Email </label>
                    <DefaultInput className="form-control" type="email" name="Email" placeholder="Enter Email" value={emailId === "undefined" ? "" : emailId?.trim()} errorstatus={validateEmail(emailId) !== undefined && notEqualsZero(emailId)} errormessage={emailId === "undefined" ? "" : validateEmail(emailId)} onChange={(e) => setEmailId(e.target.value)} disabled={props?.userDetails?.emailId ? 'disabled' : undefined} />
                  </div>
                </div>

                {props?.userDetails?.emailId ? '' :
                  <div className="col-lg-12 col-md-12 col-12 mb-3 mb-md-4">
                    <label className="form-label" >Email Verification Code</label>
                    <div className=" field-otp-box" >
                      <input type="text" name="form-otp" id="form-otp" className="form-control" value={emailOtp} onChange={(e) => setEmailOtp(e.target.value)} />
                      <button type="button" className="btn btn-xs  custom-btn" onClick={() => { handleGetOtp(emailId, "registration"); setDisbaleBtn(true) }}>
                        <span> {disableBtn ? 'Resend OTP' : 'GET OTP '}  </span>
                      </button>
                    </div>
                  </div>
                }
                {/* <div className="col-lg-12 col-md-12 col-12 mb-3 mb-md-4">
                  <div className="field-box">
                    <label htmlFor="text" className="form-label"> Mobile Number </label>
                    <DefaultInput className="form-control" type="text" name="Mobile" placeholder="Enter Mobile Number" errorstatus={validateNumber(mobile)} value={!mobile ? " " : +mobile} onChange={(e) => setMobile(e.target.value)} disabled={props?.userDetails?.mobileNumber ? 'disabled' : undefined} />
                  </div>
                </div>
                {
                  props?.userDetails?.mobileNumber ? '' :
                    <div className="col-lg-12 col-md-12 col-12 ">
                      <label className="mb-1" >Mobile Verification Code</label>
                      <div className=" field-otp-box" >
                        <input type="text" name="form-otp" id="form-otp" className="form-control" value={mobileOtp} onChange={(e) => setMobileOtp(e.target.value)} />
                        <button type="button" className="btn btn-xs  custom-btn" onClick={() => { handleGetOtp(mobile, "registration"); setDisbaleBtn(true) }}>
                          <span> {disableBtn ? 'Resend OTP' : 'GET OTP '}  </span>
                        </button>
                      </div>
                    </div>
                } */}
                <div className="col-md-12  mb-3 mb-md-4 mt-md-4 mt-3">
                  <div className="field-box">
                    <button className="btn custom-btn btn-block w-100 btn-xl" type="button" onClick={() => handleSettings(firstName, lastName, emailId, mobile, emailOtp, mobileOtp)}>
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
