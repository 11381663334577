import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ProfileContext } from "../../context/ProfileProvider";

const UserHeader = () => {

  const { newStoredTheme, handleTheme } = useContext(ProfileContext);
  const token = sessionStorage.getItem("token");

  const handlToggle = () => {
    const element = document.getElementById('navbarNav');
    const element2 = document.getElementById('main_header');
    const element3 = document.getElementById('brand');
    const element4 = document.getElementById('toggleButton');
    if(element){
      element.classList.remove('show');
    }
    if( element2 ){
      element2.classList.remove('show');
    }
    if(element3 ){
      element3.classList.remove('show');
    }
    if( element4){
      element4.classList.add('collapsed');
    }
 
  };

  return (
    <nav className="navbar navbar-expand-lg price_bar_nav">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand" id="brand" onClick={handlToggle}>
          <img alt="" src="/images/logo.svg" className=" show_light" />
          <img alt="" src="/images/logo_dark_m.svg" className="show_dark" />
        </Link>
        <div className="collapse navbar-collapse justify-content-start" id="navbarNav" >
          <ul className="navbar-nav align-items-lg-center ms-md-4">

            <li className="nav-item">
              <Link to="/market" className="nav-link click-scroll" onClick={handlToggle} >
                Market
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/trade/Header" className="nav-link click-scroll" onClick={handlToggle}>
                Trade
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/comingsoon" className="nav-link click-scroll" onClick={handlToggle}>P2P</Link>
            </li>
            <li className="nav-item">
              <Link to="/partnership" className="nav-link click-scroll" onClick={handlToggle}> Partnership </Link>
            </li>
            <li className="nav-item">
              <Link to="/blogs" className="nav-link click-scroll" onClick={handlToggle}>Blogs</Link>
            </li>
            <li className="nav-item">
              <Link to={token ? '/refrefer_earn' : '/login'} className="nav-link click-scroll" onClick={handlToggle}>Refer & Earn</Link>
            </li>
            <li className="nav-item">
              <Link to={'quick_buy_sell'} className="nav-link click-scroll" onClick={handlToggle}>Quick Buy / Sell</Link>
            </li>
          </ul>
        </div>
        <div className="nav_right push_btn_nr" id="main_header">

          <Link className=" btn-link-icon px-0 mx-md-2" id="theme-toggle-btn" onClick={() => handleTheme()} >
            {newStoredTheme === "dark" ? (
              <i className="ri-sun-fill"></i>
            ) : (
              <i className="ri-sun-line"></i>
            )}
          </Link>
          <Link to="/login" className="btn custom-btn custom-border-btn  custom-border-btn-white ms-2 btn-sm" onClick={handlToggle} >
            <i className="ri-user-line me-2 d-inline-flex d-md-none"></i>
            <span>Login</span>
          </Link>
          <Link to="/signup" className="btn custom-btn ms-2  btn-sm" onClick={handlToggle} >
            <i className="ri-user-add-line me-2 d-inline-flex d-md-none"></i>
            <span>Register</span>
          </Link>

            <button className="navbar-toggler collapsed ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" id='toggleButton'>
              {/* <span className="navbar-toggler-icon"></span> */}
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span> 
            </button>

        </div>
      </div>
    </nav>
  );
};

export default UserHeader;
