import { ApiConfig } from "../apiConfig/apiConfig";
import { ApiCallGet, ApiCallPost, ApiCallPut } from "../apiConfig/apiCall";
import { ConsoleLogs } from "../../utils/ConsoleLogs";

const TAG = "AuthService";

const AuthService = {

  login: async (signId, password, captchaData) => {
    const { baseAuth, login } = ApiConfig;
    const url = baseAuth + login;
    const { captcha_output, gen_time, lot_number, pass_token } = captchaData
    const params = {
      email_or_phone: signId,
      password: password,
      captcha_output, gen_time, lot_number, pass_token
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  register: async (signId, otp, password, cPassword, invitation, captchaData) => {
    const { baseAuth, register } = ApiConfig;
    const url = baseAuth + register;
    const { captcha_output, gen_time, lot_number, pass_token } = captchaData
    const params = {
      email_or_phone: signId,
      verification_code: otp,
      password: password,
      confirm_password: cPassword,
      referral_code: invitation,
      captcha_output, gen_time, lot_number, pass_token
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getOtp: async (signid, type) => {
    const { baseAuth, getOtp } = ApiConfig;
    const url = baseAuth + getOtp;
    const params = {
      email_or_phone: signid,
      type: type,
      "resend": true
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getCode: async (signId, type, otp) => {
    const { baseAuth, getcode } = ApiConfig;
    const url = baseAuth + getcode;
    const params = {
      "email_or_phone": signId,
      "type": type,
      "otp": Number(otp)
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  forgotPassword: async (inputData) => {
    const { baseAuth, forgotpassword } = ApiConfig;
    const url = baseAuth + forgotpassword;
    const params = {
      email_or_phone: inputData?.signId,
      verification_code: inputData?.otp,
      new_password: inputData?.password,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  googleLogin: async (tokenResponse, captchaData) => {
    const { baseUrl, loginwithGoogle } = ApiConfig;
    const url = baseUrl + loginwithGoogle;
    const { captcha_output, gen_time, lot_number, pass_token } = captchaData
    const params = {
      Token: tokenResponse?.access_token,
      type: 'google',
      captcha_output, gen_time, lot_number, pass_token
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  getActivityLogs: async () => {
    const token = localStorage.getItem("token");
    const { baseAuth, activityLogs } = ApiConfig;
    const url = baseAuth + activityLogs;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  setCurrency: async (currencyType) => {
    const token = localStorage.getItem("token");
    const { baseAuth, setCurrency } = ApiConfig;
    const url = baseAuth + setCurrency;
    const params = {
      currency: currencyType,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  transferToSpot: async (selectedCurrency, withdrawAmount) => {
    const token = localStorage.getItem("token");
    const { baseP2p, transferFunds } = ApiConfig;
    const url = baseP2p + transferFunds;
    const params = {
      spot_wallet: true,
      amount: +withdrawAmount,
      short_name: selectedCurrency
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  transferToP2P: async (selectedCurrency, withdrawAmount) => {
    const token = localStorage.getItem("token");
    const { baseP2p, transferFunds } = ApiConfig;
    const url = baseP2p + transferFunds;
    const params = {
      funding_wallet: true,
      amount: +withdrawAmount,
      short_name: selectedCurrency
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getUserfunds: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, userfunds } = ApiConfig;
    const url = baseWallet + userfunds;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  userP2pWallet: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, userP2pWallet } = ApiConfig;
    const url = baseWallet + userP2pWallet;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  walletHistory: async () => {
    const token = localStorage.getItem('token');
    const { baseTrans, walletHistory } = ApiConfig;
    const url = baseTrans + walletHistory;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  tradeHistory: async (skip, limit) => {
    const token = localStorage.getItem('token');
    const { baseTrans } = ApiConfig;
    const url = baseTrans + `trade-history?skip=${skip}&limit=${limit}`;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  allOpenOrder: async (skip, limit) => {
    const token = localStorage.getItem('token');
    const { baseExchange } = ApiConfig;
    const url = baseExchange + `all-open-orders?skip=${skip}&limit=${limit}`;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  getbannerdata: async () => {
    const { baseAdmin, bannerList } = ApiConfig;
    const url = baseAdmin + bannerList;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },


  generateAddress: async (currID, chain) => {
    const token = localStorage.getItem("token");
    const { baseWallet, generateAddress } = ApiConfig;
    const url = baseWallet + generateAddress;
    const params = {
      "currency_id": currID,
      "chain": chain
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },

  estimatedPortfolio: async () => {
    const token = localStorage.getItem("token");
    const { baseWallet, estimatedPortfolio } = ApiConfig;
    const url = baseWallet + estimatedPortfolio;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  nexbAmount: async (baseCurrencyId, quoteCurrencyId, userId, usdtCoin) => {
    console.log("apiii")
    const token = localStorage.getItem("token");
    const { baseUrl, convertToken } = ApiConfig;
    const url = baseUrl + convertToken;
    const params = {
      userId: userId,
      base_Currency_id: baseCurrencyId,
      quote_Currency_id: quoteCurrencyId,
      amountToPay: usdtCoin,
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  swapAmount: async (baseCurrencyId, quoteCurrencyId, userId, usdtCoin, nexbCoin) => {
    const token = localStorage.getItem("token");
    const { baseUrl, swapToken } = ApiConfig;
    const url = baseUrl + swapToken;
    const params = {
      userId: userId,
      base_Currency_id: baseCurrencyId,
      quote_Currency_id: quoteCurrencyId,
      amountToPay: usdtCoin,
      amountToBuy: nexbCoin,
      fromCurrency: "USDT",
      toCurrency: "NEXB",
      side: "BUY"
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },



  withdrawalCurrency: async (walletAddress, amountValue, otp, selectedChain, signId, balance) => {
    const token = localStorage.getItem("token");
    const { baseWallet, withdrawalcurrencypath } = ApiConfig;
    const url = baseWallet + withdrawalcurrencypath;
    const params = {
      withdrawal_address: walletAddress,
      amount: amountValue,
      chain: selectedChain?.chain,
      coinName: selectedChain?.name,
      verification_code: otp,
      email_or_phone: signId,
      usdt_balance: balance,
    };
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };
    return ApiCallPost(url, params, headers);
  },

  addkyc: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseAuth, identity } = ApiConfig;
    const url = baseAuth + identity;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },

  categoryList: async () => {
    const token = localStorage.getItem('token');
    const { baseAdmin, categoryList } = ApiConfig;
    const url = baseAdmin + categoryList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  favoriteCoin: async (pairId) => {
    const token = localStorage.getItem('token');
    const { baseAuth, favoriteCoin } = ApiConfig;
    const url = baseAuth + favoriteCoin;
    const params = {
      "pair_id": pairId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);

  },

//   favoriteList: async () => {
//     const token = localStorage.getItem('token');
//     const { baseAuth, favoriteList } = ApiConfig;
//     const url = baseAuth + favoriteList;

//     const headers = {
//       'Content-Type': 'application/json',
//       "Authorization": token,
//     };
//     return ApiCallGet(url, headers);

//   },

  setSecurity: async (oldpassword, password, conPassword, verificationcode, email_or_phone) => {
    const token = localStorage.getItem("token");
    const { baseAuth, setSecurity } = ApiConfig;
    const url = baseAuth + setSecurity;
    const params = {
      old_password: oldpassword,
      new_password: password,
      confirm_password: conPassword,
      verification_code: verificationcode,
      email_or_phone: email_or_phone
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  updateSettings: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseAuth, updateSettings } = ApiConfig;
    const url = baseAuth + updateSettings;
    ConsoleLogs(TAG + ', updateSettings', `url : ' + ${url}`);
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };
    return ApiCallPut(url, formData, headers);
  },

  placeOrder: async (infoPlaceOrder, buyprice, buyamount, base_currency_id, quote_currency_id, side) => {
    const token = localStorage.getItem('token');
    const { baseExchange, placeOrder } = ApiConfig;
    const url = baseExchange + placeOrder;
    const params = {
      "order_type": infoPlaceOrder,
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
      "side": side,
      "price": +buyprice,
      "quantity": +buyamount
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  pastOrder: async (base_currency_id, quote_currency_id, skip, limit) => {
    const token = localStorage.getItem('token');
    const { baseExchange, pastOrder } = ApiConfig;
    const url = baseExchange + pastOrder;
    const params = {
      "base_currency_id": base_currency_id,
      "quote_currency_id": quote_currency_id,
      "skip": skip,
      "limit": limit,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  cancelOrder: async (orderId) => {
    const token = localStorage.getItem('token');
    const { baseExchange, cancelOrder } = ApiConfig;
    const url = baseExchange + cancelOrder;
    const params = {
      "order_id": orderId
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  cancelAllOrder: async () => {
    const token = localStorage.getItem('token');
    const { baseExchange, cancel_all_order } = ApiConfig;
    const url = baseExchange + cancel_all_order;
    const params = {};
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  coinDetails: async (currency_id) => {
    const token = localStorage.getItem('token');
    const { baseExchange, coinDetails } = ApiConfig;
    const url = baseExchange + coinDetails;
    const params = {
      "currency_id": currency_id
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  update2fa: async (authType, code, verifyType) => {
    const token = localStorage.getItem("token");
    const { baseAuth, update2fa } = ApiConfig;
    const url = baseAuth + update2fa;
    const params = {
      type: authType,
      verification_code: Number(code),
      email_or_phone: verifyType
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPut(url, params, headers);
  },


  googleAuth: async () => {
    const token = localStorage.getItem("token");
    const { baseAuth, googleAuth } = ApiConfig;
    const url = baseAuth + googleAuth;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getHistoricalData: async (fromSymbol, toSymbol, from, to) => {
    const token = localStorage.getItem('token');
    const { baseExchange, getHistoricalData } = ApiConfig;
    const url = baseExchange + getHistoricalData;
    const params = {
      base_currency: fromSymbol,
      quote_currency: toSymbol,
      from: from,
      to: to,
      limit: 2000,
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  rateOrder: async (rating) => {
    const token = localStorage.getItem("token");
    const { baseP2p, rateOrder } = ApiConfig;

    const url = baseP2p + rateOrder;
    const params = {
      ratings: rating,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    return ApiCallPost(url, params, headers);
  },

  editBankDetails: async (accountType, bankName, holderName, accountNumber, ifscCode, branchAddress, id) => {
    const token = localStorage.getItem("token");
    const { baseAuth, editBankDetails } = ApiConfig;
    const url = baseAuth + editBankDetails;
    const params = {
      _id: id,
      account_type: accountType,
      bank_name: bankName,
      account_holder_name: holderName,
      account_number: accountNumber,
      ifsc_code: ifscCode,
      branch_name: branchAddress,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  editUpiDetails: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseAuth, editUpiDetails } = ApiConfig;
    const url = baseAuth + editUpiDetails;

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPut(url, formData, headers);
  },


  cryptoCompareApi: async (base, quote, to, resolution) => {
    const token = localStorage.getItem('token');
    const url = `https://min-api.cryptocompare.com/data/v2/${resolution}?fsym=${base}&tsym=${quote}&toTs=${to}&limit=2000`
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  getDetails: async () => {
    const token = localStorage.getItem("token");
    const { baseAuth, getDetails } = ApiConfig;
    const url = baseAuth + getDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  inrDeposit: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseWallet, depositInr } = ApiConfig;
    const url = baseWallet + depositInr;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },

  inrWithdrawal: async (withdrawAmount) => {
    const token = localStorage.getItem("token");
    const { baseWallet, withdrawalInr } = ApiConfig;
    const url = baseWallet + withdrawalInr;
    const params = {
      amount: withdrawAmount,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getBankDetails: async () => {
    const token = localStorage.getItem("token");
    const { baseAdmin, adminBankDetails } = ApiConfig;
    const url = baseAdmin + adminBankDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getUserBankDetails: async () => {
    const token = localStorage.getItem("token");
    const { baseAuth, userBankDetails } = ApiConfig;
    const url = baseAuth + userBankDetails;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  quickBuySellHistory: async (skip, limit) => {
    const token = localStorage.getItem('token');
    const { baseSwap, quickBuySellHistory } = ApiConfig;
    const url = baseSwap + quickBuySellHistory + `?skip=${skip}&limit=${limit}`;
    const headers = { 'Content-Type': 'application/json', "Authorization": token };
    return ApiCallGet(url, headers);
  },


  swapToken: async (base_currency, quote_currency, side, amount, swapped_amount) => {
    const token = localStorage.getItem('token');
    const { baseSwap, quick_buy_sell } = ApiConfig;
    const url = baseSwap + quick_buy_sell;
    const params = {
      base_currency, quote_currency, side, amount, swapped_amount
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  sendKginOtpOtp: async (kginNumber) => {
    const token = localStorage.getItem("token");
    const { baseAuth, sendkginotp } = ApiConfig;
    const url = baseAuth + sendkginotp;
    const params = { mobileNumber: Number(kginNumber) };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  verifyKginOtpOtp: async (kginNumber, kginOtp) => {
    const token = localStorage.getItem("token");
    const { baseAuth, verifykginotp } = ApiConfig;
    const url = baseAuth + verifykginotp;
    const params = {
      mobileNumber: Number(kginNumber),
      otp: kginOtp
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  notifications: async () => {
    const token = localStorage.getItem("token");
    const { baseAdmin, notifiactionlist } = ApiConfig;
    const url = baseAdmin + notifiactionlist;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },


  refferlsList: async () => {
    const token = localStorage.getItem("token");
    const { baseAuth, reffercode } = ApiConfig;
    const url = baseAuth + reffercode;
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallGet(url, headers);
  },

  getPairs: async () => {
    const token = localStorage.getItem('token');
    const { baseCoin, getPairs } = ApiConfig;
    const url = baseCoin + getPairs;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  get_top_GL: async () => {
    const token = localStorage.getItem('token');
    const { baseCoin, get_top_GL } = ApiConfig;
    const url = baseCoin + get_top_GL;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },


  get_trending: async () => {
    const token = localStorage.getItem('token');
    const { baseCoin, get_trending } = ApiConfig;
    const url = baseCoin + get_trending;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  p2pCoinList: async () => {
    const token = localStorage.getItem('token');
    const { baseCoin, p2pCoinList } = ApiConfig;
    const url = baseCoin + p2pCoinList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  getCoinList: async () => {
    const token = localStorage.getItem('token');
    const { baseCoin, getCoinList } = ApiConfig;
    const url = baseCoin + getCoinList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  fetchPaymentMethods: async () => {
    const token = localStorage.getItem('token');
    const { baseP2p, fetchPaymentMethods } = ApiConfig;
    const url = baseP2p + fetchPaymentMethods;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  p2pBuyOrder: async (short_name) => {
    const token = localStorage.getItem('token');
    const { baseP2p, buyOrder } = ApiConfig;
    const url = baseP2p + buyOrder;
    const params = { short_name };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  p2pSellOrder: async (short_name) => {
    const token = localStorage.getItem('token');
    const { baseP2p, sellOrder } = ApiConfig;
    const url = baseP2p + sellOrder;
    const params = { short_name };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  currentPrice: async (fiat, asset) => {
    const token = localStorage.getItem('token');
    const { baseP2p, currentPrice } = ApiConfig;
    const url = baseP2p + currentPrice;
    const params = {
      "base_currency": asset,
      "quote_currency": fiat
    };
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  fiatCurrencyList: async () => {
    const token = localStorage.getItem('token');
    const { baseP2p, fiatCurrencyList } = ApiConfig;
    const url = baseP2p + fiatCurrencyList;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  myAds: async () => {
    const token = localStorage.getItem('token');
    const { baseP2p, myAds } = ApiConfig;
    const url = baseP2p + myAds;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  myOrders: async () => {
    const token = localStorage.getItem('token');
    const { baseP2p, myOrders } = ApiConfig;
    const url = baseP2p + myOrders;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  runtime: async (status, orderId) => {
    const token = localStorage.getItem('token');
    const { baseP2p, p2pRuntime } = ApiConfig;
    const url = baseP2p + p2pRuntime;
    const params = {
      status: status,
      order_id: orderId
    };
    const headers = {
      "Authorization": token,
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },


  notify: async (orderId) => {
    const token = localStorage.getItem('token');
    const { baseP2p, notify } = ApiConfig;
    const url = baseP2p + notify;
    const params = {
      "order_id": orderId
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  addOrderNotification: async (orderId) => {
    const token = localStorage.getItem('token');
    const { baseP2p, addOrderNotification } = ApiConfig;
    const url = baseP2p + addOrderNotification;

    const params = {
      order_id: orderId
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  swapHistory: async () => {
    const token = localStorage.getItem("token");
    const { baseP2p, swapHistory } = ApiConfig;
    const url = baseP2p + swapHistory;
    const params = {
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);

  },

  notifySellerTrader: async (status, orderId) => {
    const token = localStorage.getItem('token');
    const { baseP2p, notifyMerchent } = ApiConfig;
    const url = baseP2p + notifyMerchent;
    const params = {
      order_id: orderId,
      trader_status: status,
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  notifySellerMerchent: async (status, orderId) => {
    const token = localStorage.getItem('token');
    const { baseP2p, notifySeller } = ApiConfig;
    const url = baseP2p + notifySeller;
    const params = {
      order_id: orderId,
      merchant_status: status,
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  buyRequest: async (orderId) => {
    const token = localStorage.getItem('token');
    const { baseP2p, buyRequest } = ApiConfig;
    const url = baseP2p + buyRequest;
    const params = {
      "order_id": orderId
    }

    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  buyCurrency: async (payableAmount, receivableAmount, id, postAdId, timestamp, sellerSelectedPayment, method) => {
    const token = localStorage.getItem('token');
    const { baseP2p, buyCurrency } = ApiConfig;
    const url = baseP2p + buyCurrency;
    const params = {
      "amount": payableAmount,
      "receiving_amount": receivableAmount,
      "postAd_user": postAdId,
      "add_id": id,
      "payment_timestamp": timestamp,
      "payment_type": sellerSelectedPayment,
      "payment_method": method,
    }
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  createNewPost: async (input, paymentMethod) => {
    const token = localStorage.getItem('token');
    const { baseP2p, createNewPost } = ApiConfig;
    const url = baseP2p + createNewPost;
    const params = {
      "base_currency": input?.asset,
      "quote_currency": input?.fiat,
      "side": input?.side,
      "price_type": input?.priceType,
      "fixed_price": input?.price,
      "payment_time": input?.time,
      // "min_amount": input?.minAmount,
      // "max_amount": input?.maxAmount,
      "payment_method": paymentMethod,
      "kyc": input?.kyc,
      "totalTransactions": input?.transaction,
      "counterCurrency": input?.minCurrency,
      "currencyAmount": input?.currencyAmount || 0,
      "volume": input?.volume,
      "registered_days": +input?.regDays
    }
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallPost(url, params, headers);
  },

  assetAddList: async (projectName, contactName, telegramId, phoneNumber, emailAddress, referredBy, comments, countryCode, contractAddress) => {
    const { baseAuth, coinListedDetails } = ApiConfig;
    const url = baseAuth + coinListedDetails;
    const params = {
      ProjectName: projectName,
      ContactName: contactName,
      TelegramID: telegramId,
      PhoneNumber: phoneNumber,
      emailID: emailAddress,
      referredBy: referredBy,
      comments: comments,
      countryCode: countryCode,
      contractAddress: contractAddress,
      status: 'pending',
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  addPartnerShipDetails: async (formData) => {
    const token = localStorage.getItem("token");
    const { basePartner, partnerships } = ApiConfig;
    const url = basePartner + partnerships;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },

  addUpiDetails: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseAuth, addUpiDetails } = ApiConfig;
    const url = baseAuth + addUpiDetails;
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: token,
    };
    return ApiCallPost(url, formData, headers);
  },

  addBankDetails: async (account_type, bank_name, account_holder_name, account_number, ifsc_code, branch_name) => {
    const token = localStorage.getItem("token");
    const { baseAuth, addBankDetails } = ApiConfig;
    const url = baseAuth + addBankDetails;
    const params = { account_type, bank_name, account_holder_name, account_number, ifsc_code, branch_name }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  registerPartner: async (emailAddress, password) => {
    const url = ApiConfig.baseUrl + 'v1/partnerShip/signUp';
    const params = {
      email: emailAddress,
      password: password
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  totalReferCount: async () => {
    const token = localStorage.getItem('token');
    const { baseAuth, total_refer_count } = ApiConfig;
    const url = baseAuth + total_refer_count;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  getReferList: async () => {
    const token = localStorage.getItem('token');
    const { baseAuth, refer_list } = ApiConfig;
    const url = baseAuth + refer_list;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  getLatestNews: async () => {
    const token = localStorage.getItem('token');
    const { baseAuth, getLatestNews } = ApiConfig;
    const url = baseAuth + getLatestNews;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  totalReferBalance: async () => {
    const token = localStorage.getItem('token');
    const { baseAuth, referral_balance } = ApiConfig;
    const url = baseAuth + referral_balance;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  getjoiningbalance: async () => {
    const token = localStorage.getItem('token');
    const { baseAuth, joining_balance } = ApiConfig;
    const url = baseAuth + joining_balance;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  getCoinDetails: async (currency_id) => {
    const token = localStorage.getItem("token");
    const { baseExchange, coin_details } = ApiConfig;
    const url = baseExchange + coin_details;
    const params = {
      currency_id
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  cancelWithdrawal: async (_id, amount, currency_id, fee) => {
    const token = localStorage.getItem("token");
    const { baseWallet, user_cancel_withdrawal } = ApiConfig;
    const url = baseWallet + user_cancel_withdrawal;
    const params = {
      _id, amount, currency_id, fee
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },


  verifyDeposit: async () => {
    const token = localStorage.getItem('token');
    const { baseWallet, verify_deposit } = ApiConfig;
    const url = baseWallet + verify_deposit;
    const headers = {
      'Content-Type': 'application/json',
      "Authorization": token,
    };
    return ApiCallGet(url, headers);
  },

  submitTicket: async (formData) => {
    const token = localStorage.getItem("token");
    const { baseSupport, submitTicket } = ApiConfig;
    const url = baseSupport + submitTicket;
    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': token
    };

    return ApiCallPost(url, formData, headers);
  },

  getUserTickets: async () => {
    const token = localStorage.getItem("token");
    const { baseSupport, getUserTickets } = ApiConfig;
    const url = baseSupport + getUserTickets;
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };

    return ApiCallGet(url, headers);
  },

  replyTicket: async (messagerply, id) => {
    const token = localStorage.getItem("token");
    const { baseSupport, replyTicket } = ApiConfig;
    const url = baseSupport + replyTicket;
    const params = {
      replyBy: 1,
      query: messagerply,
      ticket_id: id,

    }
    const headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };

    return ApiCallPost(url, params, headers);
  },

  request_refund: async (order_id) => {
    const token = localStorage.getItem("token");
    const { baseP2p, request_refund } = ApiConfig;
    const url = baseP2p + request_refund;
    const params = { order_id }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    return ApiCallPost(url, params, headers);
  },

  getReferredUserData: async (code) => {
    const url = `https://cvtoken.us/stake/api/user-data/${code}`
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },



  // P2P Ends here........
};

export default AuthService;
