import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponents/CustomAlertMessage";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import { ProfileContext } from "../../../context/ProfileProvider";
import { isGlobalEmailDomain, validateEmail } from "../../../utils/Validation";
// import GeeTest from 'react-geetest-v4';
import { Helmet } from "react-helmet-async";

const SignupPage = () => {
    const captchaRef = useRef(null);
    const navigate = useNavigate();
    const ref = window.location.href.split("=")[1];
    const [invitation, setInvitation] = useState(ref);
    const [password, setPassword] = useState("");
    const [cPassword, setCpassword] = useState("");
    const [signId, setSignId] = useState("");
    const [otp, setOtp] = useState("");
    const [checkButton, setCheckButton] = useState(true);
    const [checkButton2, setCheckButton2] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCnf, setShowPasswordCnf] = useState(false);
    const [disableBtn, setDisbaleBtn] = useState(false);
    const [isNumb, setisNumb] = useState(false);
    const [passLength, setpassLength] = useState(false);
    const [specialCharacter, setspecialCharacter] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [captchaData, setCaptchaData] = useState({});
    const [showPassModal, setshowPassModal] = useState(false);
    const [timer, setTimer] = useState(0);
    const passwordInputType = showPassword ? "text" : "password";
    const passwordInputTypeCnf = showPasswordCnf ? "text" : "password";
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };
    const handleTogglePasswordCnf = () => {
        setShowPasswordCnf(!showPasswordCnf);
    };
    var matches = password.match(/\d+/g);
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;


    const { setLoginDetails } = useContext(ProfileContext);

    useEffect(() => {
        if (matches != null) {
            setisNumb(true);
        } else {
            setisNumb(false);
        } if (password.length >= 8) {
            setpassLength(true)
        } else {
            setpassLength(false)
        } if (password.match(format)) {
            setspecialCharacter(true);
        } else {
            setspecialCharacter(false);
        }
    }, [password, cPassword]);

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            setDisbaleBtn(false);
        }
        return () => clearInterval(interval);
    }, [timer]);

    const handleRegister = async () => {
        if (!signId) {
            alertErrorMessage("Please enter your email.");
            return;
        }
        else if (!isGlobalEmailDomain(signId)) {
            alertErrorMessage("Please enter valid email.");
            return;
        }
        else if (!otp) {
            alertErrorMessage("Please enter the OTP.");
            return;
        } else if (!password) {
            alertErrorMessage("Please enter your password.");
            return;
        } else if (password.length < 8) {
            alertErrorMessage("Password should be at least 8 characters long.");
            return;
        } else if (password !== cPassword) {
            alertErrorMessage("Passwords do not match.");
            return;
        }
         else if (!checkButton) {
            alertErrorMessage("Please agree to CV Trade Terms and Use");
            return;
        }

        LoaderHelper.loaderStatus(true);
        await AuthService.register(signId, otp, password, cPassword, invitation, captchaData).then(async (result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage('Registration Successfull!!')
                    sessionStorage.setItem("token", result.data.token);
                    sessionStorage.setItem("userId", result.data.userId);
                    setLoginDetails(result.data);
                    navigate("/funds_details");
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        });
    };

    const handleGetOtp = async (signId, type) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getOtp(signId, type).then(async (result) => {
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                try {
                    alertSuccessMessage(result?.message);
                    setDisbaleBtn(true);
                    setTimer(30);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message);
            }
        });
    };


    const handleReset = () => {
        setCpassword('')
        setPassword('')
        setSignId('')
        setOtp('')
        setShowMessage(false)
        setCheckButton(false)
        setCheckButton2(false)
    };

    const initCaptcha = () => {
        if (captchaRef.current) {
            captchaRef.current.showCaptcha();
        }
    };
  
    // const handleCaptchaSuccess = (result) => {
    //     setCaptchaData(result)
    // };

    // useEffect(() => {
    //     if (Object.keys(captchaData).length > 0) {
    //         handleRegister(); // Only call when captchaData is set
    //     }
    // }, [captchaData]);


    return (
        <>
            <Helmet>
                <title> CV Trade | The world class new generation crypto asset exchange</title>
            </Helmet>
            <section className="  section-padding feature_bg login_sec pc_bg ">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12 mx-auto">
                            <div className="page_title" >
                                <h3 className="d-block text-center mb-5" >Register to <strong className="text-gradient"> CV Trade </strong>
                                </h3>
                            </div>
                            <form className="custom-form ticket-form mb-5 mb-lg-0">
                                <div className="card-header login-header" >
                                    <ul className="nav nav-pills mb-3 login-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-Email-tab" data-bs-toggle="pill" data-bs-target="#pills-Email" type="button" role="tab" aria-controls="pills-Email" aria-selected="true" onClick={handleReset}>
                                                <img src="/images/login_email_icon.svg" className="me-1 show_dark" width="30" height="30" />
                                                <img alt="" src="/images/login_email_icon_light.svg" className=" show_light" width="30" height="30"></img>
                                                <span>Email</span>
                                            </button>
                                        </li>
                                        {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-Phone-tab" data-bs-toggle="pill" data-bs-target="#pills-Phone" type="button" role="tab" aria-controls="pills-Phone" aria-selected="false" onClick={handleReset}>
                                            <img src="/images/login_mobile_icon.svg" className="me-3 show_dark" width="30" height="30" />
                                            <img alt="" src="/images/login_mobile_icon_light.svg" className="me-3 show_light" width="30" height="30"></img>
                                            <span>Phone</span>
                                        </button>
                                    </li> */}
                                    </ul>
                                </div>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane show active" id="pills-Email" role="tabpanel" aria-labelledby="pills-Email-tab">
                                        <div className="card-body " >
                                            <div className="row">
                                                <div className="col-md-12" >
                                                    <label className="  mb-1">Email Address<em className="text-danger">*</em></label>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <input type="email" className="form-control email_input" placeholder="Enter Email" value={signId} onChange={(e) => setSignId(e.target.value)}
                                                        onBlur={(e) => setSignId(e.target.value.trim())} />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <label className="  mb-1" >Email Verification Code<em className="text-danger" >*</em></label>
                                                    <div className=" field-otp-box" >
                                                        <input type="number" name="form-otp" id="form-otp" className="form-control" placeholder="Enter  Code" value={otp} onChange={(e) => setOtp(e.target.value)} onClick={() => setshowPassModal(false)} onWheel={(e) => e.target.blur()} />

                                                        <button type="button" className="btn btn-xs  custom-btn" disabled={disableBtn} onClick={() => { validateEmail(signId) ? alertErrorMessage("Invalid Email") : !isGlobalEmailDomain(signId) ? alertErrorMessage("Please use an email from a global domain like Gmail, Yahoo, Hotmail, outlook ") : handleGetOtp(signId, 'registration'); }}>
                                                            <span>{disableBtn ? `Resend OTP (${timer}s)` : "GET OTP"}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <label className="  mb-1" >Password<em className="text-danger">*</em></label>
                                                    <div className=" field-otp-box" >
                                                        <input
                                                            name="form-password"
                                                            id="form-password"
                                                            type={passwordInputType}
                                                            className="form-control"
                                                            placeholder="Password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            onClick={() => setshowPassModal(true)}
                                                        />
                                                        <button type="button" className='btn btn-icon btn_view_pass btn-white' onClick={handleTogglePassword}  >
                                                            {
                                                                showPassword ?
                                                                    <i className="ri-eye-line"></i>
                                                                    :
                                                                    <i className="ri-eye-close-line"></i>
                                                            }
                                                        </button>
                                                        <div className="pass_conditions" style={{ display: showPassModal ? '' : 'none' }}>
                                                            <div className="d-flex align-items-center">
                                                                <i className={isNumb ? "ri-check-double-line text-success" : "ri-close-fill text-danger"}></i>
                                                                <span style={{ fontSize: "small" }}>At least 1 number</span>
                                                            </div>
                                                            <div className="d-flex align-items-center"><i className={specialCharacter ? "ri-check-double-line text-success" : "ri-close-fill text-danger"}></i>
                                                                <span style={{ fontSize: "small" }}>At least 1 special character</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <i className={passLength ? "ri-check-double-line text-success" : "ri-close-fill text-danger"}></i>
                                                                <span style={{ fontSize: "small" }}>Minimum 8 characters</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <label className="  mb-1" >Confirm Password<em className="text-danger" > *</em></label>
                                                    <div className=" field-otp-box" >
                                                        <input
                                                            type={passwordInputTypeCnf}
                                                            style={{ marginBottom: "10px" }}
                                                            name="form-password"
                                                            id="form-c-password"
                                                            className="form-control"
                                                            placeholder="Confirm Password"
                                                            value={cPassword}
                                                            onChange={(e) => setCpassword(e.target.value)}
                                                            onClick={() => setshowPassModal(false)}
                                                        />
                                                        <button type="button" className='btn btn-icon btn_view_pass btn-white' onClick={handleTogglePasswordCnf}  >
                                                            {
                                                                showPasswordCnf ?
                                                                    <i className="ri-eye-line"></i>
                                                                    :
                                                                    <i className="ri-eye-close-line"></i>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12" style={{ marginTop: "13px" }} >
                                                    <label className="  mb-1" >Referral Code (Optional)</label>
                                                    <input type="text" name="form-Referral" id="form-Referral" className="form-control" placeholder="Referral Code" value={invitation} onChange={(e) => setInvitation(e.target.value)} disabled={ref} />
                                                </div>
                                                <div className="col-md-12" >
                                                    <div className="form-check termsConditions mb-4">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={checkButton} onClick={() => setCheckButton((checkButton) => !checkButton)} />
                                                        <label className="form-check-label" for="flexCheckDefault" required> I agree to CV Trade <a target="_blank" href="https://docs.google.com/document/d/1QkwYgB0VWzPPc_Eomb3VUmualeerEksiYsQ2-NFT-Ic/edit?tab=t.0" rel="noreferrer" className="btn-link"> Terms and Use </a>
                                                            {!checkButton ? (
                                                                showMessage ? (
                                                                    <div style={{ color: "red", fontWeight: "bold", }}> Please check this box if you want to proceed. </div>
                                                                ) : (
                                                                    " "
                                                                )
                                                            ) : (
                                                                " "
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-10 col-12 mx-auto  mt-4">

                                                {/* <GeeTest
                                                    ref={captchaRef}
                                                    captchaId={'239063355a512035bb431b95a4f1a7eb'}
                                                    product={'bind'}
                                                    onSuccess={handleCaptchaSuccess}
                                                /> */}

                                                <button type="button" className="btn custom-btn w-100 btn-block btn-xl"
                                                    onClick={() => { handleRegister() 
                                                    }} > Register </button>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                                                <p>Already have an account <a className="fw-bold" href="/login"> Login</a></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane" id="pills-Phone" role="tabpanel" aria-labelledby="pills-Phone-tab">
                                        <div className="card-body " >
                                            <div className="row">
                                                <div className="col-md-12" >
                                                    <label className="  mb-1">Mobile Number<em className="text-danger">*</em></label>
                                                </div>
                                                <div className="col-lg-4 col-md-12 col-12">

                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <input type="number" name="form-phone" id="form-phone" className="form-control" placeholder="Enter Mobile Number" value={signId} onChange={(e) => setSignId(e.target.value)} onWheel={e => e.target.blur()} />
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <label className="  mb-1" >Phone Verification Code<em className="text-danger" >*</em></label>
                                                    <div className=" field-otp-box" >
                                                        <input type="text" name="form-otp" id="form-otp" className="form-control" placeholder="Enter  Code" value={otp} onChange={(e) => setOtp(e.target.value)}
                                                            onClick={() => setshowPassModal(false)} />
                                                        <button type="button" className="btn btn-xs  custom-btn" onClick={() => { handleGetOtp(signId, 'registration'); setDisbaleBtn(true) }}>
                                                            <span> {disableBtn ? 'Resend OTP' : 'GET OTP '}  </span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <label className="  mb-1" >Password<em className="text-danger">*</em></label>
                                                    <div className=" field-otp-box" >
                                                        <input
                                                            name="form-password"
                                                            id="form-password"
                                                            type={passwordInputType}
                                                            className="form-control"
                                                            placeholder="Password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            onClick={() => setshowPassModal(true)}
                                                        />
                                                        <button type="button" className='btn btn-icon btn_view_pass btn-white' onClick={handleTogglePassword}  >
                                                            {
                                                                showPassword ?
                                                                    <i className="ri-eye-line"></i>
                                                                    :
                                                                    <i className="ri-eye-close-line"></i>
                                                            }
                                                        </button>
                                                        <div className="pass_conditions" style={{ display: showPassModal ? '' : 'none' }}>
                                                            <div className="d-flex align-items-center">
                                                                <i className={isNumb ? "ri-check-double-line text-success" : "ri-close-fill text-danger"}></i>
                                                                <span style={{ fontSize: "small" }}>At least 1 number</span>
                                                            </div>
                                                            <div className="d-flex align-items-center"><i className={specialCharacter ? "ri-check-double-line text-success" : "ri-close-fill text-danger"}></i>
                                                                <span style={{ fontSize: "small" }}>At least 1 special character</span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <i className={passLength ? "ri-check-double-line text-success" : "ri-close-fill text-danger"}></i>
                                                                <span style={{ fontSize: "small" }}>Minimum 8 characters</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <label className="  mb-1" >Confirm Password<em className="text-danger" > *</em></label>
                                                    <div className=" field-otp-box" >
                                                        <input
                                                            type={passwordInputTypeCnf}
                                                            style={{ marginBottom: "10px" }}
                                                            name="form-password"
                                                            id="form-c-password"
                                                            className="form-control"
                                                            placeholder="Confirm Password"
                                                            value={cPassword}
                                                            onChange={(e) => setCpassword(e.target.value)}
                                                            onClick={() => setshowPassModal(false)}
                                                        />
                                                        <button type="button" className='btn btn-icon btn_view_pass btn-white' onClick={handleTogglePasswordCnf}  >
                                                            {
                                                                showPasswordCnf ?
                                                                    <i className="ri-eye-line"></i>
                                                                    :
                                                                    <i className="ri-eye-close-line"></i>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12" style={{ marginTop: "13px" }} >
                                                    <label className="  mb-1" >Referral Code (Optional)</label>
                                                    <input type="text" name="form-Referral" id="form-Referral" className="form-control" placeholder="Referral Code" value={invitation} onChange={(e) => setInvitation(e.target.value)} disabled={ref} />
                                                </div>
                                                <div className="col-md-12" >
                                                    <div className="form-check termsConditions mb-4">
                                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" value={checkButton2} onClick={() => setCheckButton2((checkButton2) => !checkButton2)} />
                                                        <label className="form-check-label" for="flexCheckDefault" required> I agree to CV Trade <a target="_blank" rel="noreferrer" href="https://docs.google.com/document/d/1QkwYgB0VWzPPc_Eomb3VUmualeerEksiYsQ2-NFT-Ic/edit?tab=t.0" className="btn-link"> Terms and Use </a>
                                                            {!checkButton2 ? (
                                                                showMessage ? (
                                                                    <div style={{ color: "red", fontWeight: "bold", }}> Please check this box if you want to proceed. </div>
                                                                ) : (
                                                                    " "
                                                                )
                                                            ) : (
                                                                " "
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-10 col-12 mx-auto  mt-4">
{/* 
                                                <GeeTest
                                                    ref={captchaRef}
                                                    captchaId={'239063355a512035bb431b95a4f1a7eb'}
                                                    product={'bind'}
                                                    onSuccess={handleCaptchaSuccess}
                                                /> */}
                                                <button type="button" className="btn custom-btn w-100 btn-block btn-xl"
                                                    onClick={() => {handleRegister() }} > Register </button>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                                                <p>Already have an account <a className="fw-bold" href="/login"> Login</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}

export default SignupPage


