import React, { useState, useContext, useEffect } from "react";
import { ApiConfig } from "../../../api/apiConfig/apiConfig";
import { Link, useNavigate } from "react-router-dom";
import "../LandingPage/home.css";
import "swiper/css";
import "swiper/css/pagination";
import Slider from "react-slick";
import { SocketContext } from "../../../customComponents/SocketContext";
import LoaderHelper from "../../../customComponents/Loading/LoaderHelper";
import AuthService from "../../../api/services/AuthService";
import { $ } from "react-jquery-plugin";

const LandingPage = () => {
  const [socketData, setSocketData] = useState([]);
  const [socketDatamarket, setSocketDatamarket] = useState([]);
  const [bannerList, setBannerList] = useState([]);

  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const { socket, settings, settingstwo } = useContext(SocketContext);

  const nextPage = (data) => {
    sessionStorage.setItem('RecentPair', JSON.stringify(data))
    navigate(`/trade/${data?.base_currency}_${data?.quote_currency}`);
  };



  useEffect(() => {
    let interval;
    if (socket) {
      let payload = {
        'message': 'market',
      }
      socket.emit('message', payload);
      interval = setInterval(() => {
        let payload = {
          'message': 'market',
        }
        socket.emit('message', payload);
      }, 2000)
      socket.on('message', (data) => {
        setSocketData(data?.pairs?.slice(0, 10));
        setSocketDatamarket(data?.pairs?.slice(0, 7));
      });
    }
    return (() => {
      clearInterval(interval)
    })
  }, [socket]);



  const handleBannerList = async () => {
    await AuthService.getbannerdata().then(async (result) => {
      if (result?.success) {
        setBannerList(result?.data);
      } else {
        LoaderHelper.loaderStatus(false);
      }
    })
  };

  useEffect(() => {
    $("#showModal").modal("show");
  }, []);

  useEffect(() => {
    handleBannerList();
  }, [])
  return (

    <>
      <div className="home_section" id="home">
        <section className="hero-section bg-1 hero-1" id="section_1">
          <div className="ripple-shape">
            <span className="ripple-1"></span>
            <span className="ripple-2"></span>
            <span className="ripple-3"></span>
            <span className="ripple-4"></span>
            <span className="ripple-5"></span>
          </div>
          <div className="container d-flex justify-content-center align-items-center">
            <div className="row ">
              <div className="col-12 col-lg-6 m-auto">
                <h5>Next Generation of  Crypto Exchange</h5>
                <h1 className="mb-4 text-capitalize">
                  Experience the Evolution with  <span className="text-gradient">
                    CV TRADE</span>{" "}
                </h1>
                <h6>
                  {" "}
                  SAFE <span className="mx-3"> |</span> SECURE{" "}
                  <span className="mx-3"> |</span> CREATIVE{" "}
                </h6>
                <div className="gg_user">
                  <div className="row mb-4">
                    <div className="col-3 col-lg-2">
                      <h5>100k+ <br /><small>Global User</small></h5>
                    </div>
                    <div className="col-9 col-lg-8">
                      <h5>350+ <br /><small>Listed Crypto Currency  </small></h5>
                    </div>
                  </div>
                  <Link to={token ? "/trade/landing" : "/login"} className="btn  custom-btn px-4 px-md-5 btn-xl">
                    {token ? "Trade" : "Get Started Now "}{" "}
                    <i className="ms-3 ri-arrow-right-up-line"></i>{" "}
                  </Link>
                </div>
              </div>
              <div className="col-12 col-lg-6 ">
                <img src="/images/hero_img.svg" className="img-fluid alltuchtopdown" alt="" />
              </div>
            </div>
          </div>

          <div className="nc_list_sec" >
            <div className="container" >
              <div className=" ann_slider " >
                <div className="section-title text-start  mb-4">
                  {bannerList.filter(item => item?.status === 'Active').length > 0 ? (<h3><small>New Listed <span className="text-gradient"> Coin's</span></small></h3>) : null}
                </div>
                <Slider className="snn_slide" {...settingstwo}>
                  {bannerList
                    ? bannerList
                      .filter(item => item?.status === 'Active')
                      .map((item, index) => {
                        return (
                          <div className="slide" key={index}>
                            <img src={`${ApiConfig?.baseUrl}/${item?.banner_path}`} className="img-fluid" alt="" />
                          </div>
                        );
                      })
                    : null}
                </Slider>
              </div>
            </div>
          </div>


        </section>



        <section className="market_update_sec market_update_table ">
          <div className="container">
            <div className="section-title  text-center">
              <h2>
                {" "}
                Market <span className="text-gradient">Update </span>{" "}
              </h2>
              <p>
                {" "}
                Crypto market update: Fluctuations reflect dynamic nature,  influencing <br /> investor sentiment and strategic decisions.
              </p>
            </div>
            <div className="card_table" >
              <div className="table-responsive">
                <table className="table ">
                  {socketData?.length > 0 ? (
                    <thead>
                      <tr>
                        <th> Pair</th>
                        <th> Price</th>
                        <th> 24H Change</th>
                        <th className="text-center"> 24H Vol</th>
                        <th className="text-end" > Operation</th>
                      </tr>
                    </thead>
                  ) : null}
                  <tbody>
                    {socketDatamarket
                      ? socketDatamarket.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div className="td_div">
                                <img src={ApiConfig.baseUrl + item?.icon_path} className="img-fluid icon_img coinimg" alt="" />
                                <div className="coin_name" >
                                  <span> {item?.base_currency}/{item?.quote_currency}</span>  <br />
                                  <small> {item?.base_currency}  </small>
                                </div>
                              </div>
                            </td>
                            <td><b>{item?.sell_price?.toFixed(3)}</b></td>
                            <td className={item?.change >= 0 ? "text-green" : "text-danger"}>
                              <b>{item?.change?.toFixed(3)}</b>
                            </td>
                            <td className="text-center" ><b>{item?.high?.toFixed(3)}</b></td>
                            <td className="text-end" >
                              <span onClick={() => nextPage(item)} className="btn custom-btn  custom-btn btn-sm cursor-pointer">Trade</span>
                            </td>
                          </tr>
                        )
                      })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="text-center mt-4" >
              <Link to="/market" className="btn custom-btn custom-border-btn custom-border-btn-white btn-sm" > View All
                <i className="ms-1 ri-arrow-right-line"></i>
              </Link>
            </div>
          </div>
        </section>



        {/* <section className=" live_prices  ">
        <div className="container-fluid px-0">
          <Slider className="mb_slide" {...settings}>
            {socketData
              ? socketData?.map((item, index) => {
                return (
                  <div className="slidess" key={index}>
                    <div className="main__card " >
                      <div className="main__icon" onClick={() => nextPage(item)}>
                        <img src={ApiConfig.baseUrl + item?.icon_path} alt="CV Trade" className="img-fluid" />
                      </div>
                      <div className="main__details">
                        <div className="main__line">
                          <div className="main__subtitle">{item?.base_currency}/{item?.quote_currency} </div>
                          <span className={`price_small badge main__negative ${item?.change >= 0 ? "badge-success" : "badge-danger"}`}  >
                            {item?.change?.toFixed(2)}
                          </span>
                        </div>
                        <div className="main__price  ">{item?.sell_price?.toFixed(2)}</div>
                        <div className="main__money">{item?.high?.toFixed(2)}</div>
                      </div>
                    </div>
                  </div>
                );
              })
              : null}
          </Slider>
        </div>
      </section> */}

        <section className="how_it_sec h_app_sec">
          <div className="container">
            <div className="row  align-items-center ">
              <div className="  col-lg-5 ">
                <div className="section-title  text-start">
                  <h1>Streamline Your  Trade with<span className="text-gradient text-underline"> Smart Trading </span></h1>
                  <p> Maximize Your Crypto Trading Potential with Spot and Futures Markets </p>
                  <a className="btn  custom-btn   btn-xl" href="/trade/landing"> Start Trade <i className="ms-3 ri-arrow-right-line"></i> </a>
                </div>
              </div>
              <div className="col-lg-7">
                <img src="images/mob_mockup.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>

        <section className="how_it_sec">
          <div className="container">
            <div className="section-title text-center">
              <h2>
                World Class Trading <br /> Platform
              </h2>
            </div>
            <div className="row justify-content-center g-3 mobile_row_scroll ">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 ">
                <div className="card fe_body h-100">
                  <div className="card-body">
                    <span className="sw_icon" ><img src="/images/features/1.svg" className="img-fluid" /> </span>
                    <h5>Exchange</h5>
                    <p className="mb-0">
                      ¬Join the Future of Finance. Log in to Your CV Trade Account and Embark on Your Journey of Buying and Selling Crypto currencies Today!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 ">
                <div className="card fe_body h-100">
                  <div className="card-body">
                    <span className="sw_icon" ><img src="/images/features/2.svg" className="img-fluid" /> </span>
                    <h5>High Performance</h5>
                    <p className="mb-0">
                      Seamless Trading." Trade with Confidence and Easy, Supported by Cutting-Edge Software and Exceptional Service.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 ">
                <div className="card  fe_body h-100">
                  <div className="card-body">
                    <span className="sw_icon" ><img src="/images/features/3.svg" className="img-fluid" /> </span>
                    <h5>Super Fast KYC</h5>
                    <p className="mb-0">
                      Lightning-Fast KYC. Experience Seamless Verification and User-Friendly KYC Processes. Get Trading in Seconds!"
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 ">
                <div className="card fe_body h-100">
                  <div className="card-body">
                    <span className="sw_icon" ><img src="/images/features/4.svg" className="img-fluid" /> </span>
                    <h5>Order Types</h5>
                    <p className="mb-0">
                      With a diverse array of order types at your fingertips, tailored to your every trading need, experience the ultimate freedom in crypto asset transactions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 ">
                <div className="card  fe_body h-100">
                  <div className="card-body">
                    <span className="sw_icon" ><img src="/images/features/5.svg" className="img-fluid" /> </span>
                    <h5>Minimal Interface</h5>
                    <p className="mb-0">
                      Where Minimalism Meets Functionality. Our platform boasts a sleek and minimal interface, designed for maximum user efficiency
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 ">
                <div className="card fe_body h-100">
                  <div className="card-body">
                    <span className="sw_icon" ><img src="/images/features/6.svg" className="img-fluid" /> </span>
                    <h5>Safe And Secure</h5>
                    <p className="mb-0">
                      We prioritize your security above all else. Our platform employs top-notch security measures, guaranteeing the safety of your transactions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="how_it_sec h_app_sec  h_dash_sec">
          <img src="images/laptop_mockup.png" className="img-fluid lap_mockup" alt="" />
          <div className="container">
            <div className="row gx-md-5 align-items-center ">
              <div className="  col-lg-7 ">
                <div className="section-title mb-0  text-start">
                  <h2>
                    Streamline Your Trade with <span className="text-gradient text-underline"> Smart Trading </span>
                  </h2>
                  <p> Maximize Your Crypto Trading Potential with Spot and Futures Markets </p>
                  <div className="css-15tod8s">
                    <div className="css-ojf8hg">
                      <div className="css-1xro94b"></div>
                      <p className="css-ozco81  m-0" >Advanced Order Types</p>
                    </div>
                    <p className="css-1dsb4aq m-0" >Achieve greater precision and control in executing your trades</p>
                    <ul className="css-1m2yo4n">
                      <li className="subFeature css-15tod8s">
                        <div className="css-ojf8hg">
                          <p className="css-1tmxl4i m-0" type="medium" >TWAP Orders</p>
                        </div>
                        <p className="css-1dsb4aq m-0">Evenly executes trades over time to minimize market impact and achieve a better average price with TWAP Order Type</p>
                      </li>
                      <li className="subFeature css-15tod8s">
                        <div className="css-ojf8hg">
                          <p className="css-1tmxl4i  m-0" type="medium" >Dynamic Orders</p>
                        </div>
                        <p className="css-1dsb4aq  m-0" >Adjust order parameters based on market conditions, optimizing trade execution and minimizing risks with Dynamic order Type</p>
                      </li>
                    </ul>
                  </div>
                  <Link className="btn custom-btn btn-xl" to={token ? "/trade/landing" : "/trade/landing"}> Start Trade <i className="ms-3 ri-arrow-right-line"></i> </Link>
                </div>
              </div>
              <div className="col-lg-5 ">
              </div>
            </div>
          </div>
        </section>
        <section className="dd_sec tbf_section">
          <div className="container">
            <div className="section-title  mb-0 text-center">
              <h2> How it works </h2>
              <p>
                {" "}
                Stacks is a production-ready library of stackable <br /> content
                blocks built in React Native.{" "}
              </p>
            </div>
            <div className="dd_cards">
              <div className="row   ">
                <div className="col-md-4">
                  <div className="wallet-block text-center hiw_card hiw_card_bar  ">
                    <div className="thumb d-flex justify-content-center">
                      <img src="/images/how_1.svg" alt="" className="img-fluid" />
                    </div>
                    <span>Step 1</span>
                    <h4 className="" >Login & Register  </h4>
                    <p>Enter your email address and create a strong password.</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="wallet-block text-center hiw_card hiw_card_bar  ">
                    <div className="thumb d-flex justify-content-center">
                      <img src="/images/how_2.svg" alt="" className="img-fluid" />
                    </div>
                    <span>Step 2</span>
                    <h4>Complete KYC  </h4>
                    <p>
                      Complete the two-factor authentication process (2FA). Wait
                      for your account to be verified and approved .
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="wallet-block text-center hiw_card hiw_card_bar">
                    <div className="thumb d-flex justify-content-center">
                      <img src="/images/how_3.svg" alt="" className="img-fluid" />
                    </div>
                    <span>Step 3</span>
                    <h4>Start Trading  </h4>
                    <p>
                      {" "}
                      Once approved, login to your account and start trading.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="partner_community pc_bg pt-5">
          <div className="community_sec">
            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-lg-7">
                      <div className="section-title mb-0 text-start pb-0 no-border">
                        <h1 className="">Join Our Community </h1>
                        <p className=" mb-0">
                          CV Trade is global. Join the conversation in
                          any of our worldwide communities.
                        </p>
                        <div className="d-flex  joc_social_row ">
                          <a className="joc_social" title=" Facebook" target="_blank" href="https://www.facebook.com/cvtrade">
                            <i className="ri-facebook-line  ri-xl"></i>
                          </a>
                          <a className="joc_social" title=" Twitter" target="_blank" href="https://x.com/CvTradeExchange">
                            <i className="ri-twitter-line  ri-xl"></i>
                          </a>
                          <a className="joc_social" title=" Instagram" target="_blank" href="https://www.instagram.com/officialcvtrade/">
                            <i className="ri-instagram-line  ri-xl"></i>
                          </a>
                          <a className="joc_social" title="Telegram" target="_blank" href="https://t.me/cvtradeexchange">
                            <i className="ri-telegram-line ri-xl"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <img src="/images/joc_img.svg" alt="" className="img-fluid joc_img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button id="btnScrollToTop" type="button">
          {" "}
          <i className="ri-arrow-up-line"></i>{" "}
        </button>
      </div>



      <div
        onClick={() => window.open("https://t.me/cvt_official_bot?profile", "_blank")}
        className="modal image_modal "
        id="showModals"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">

          <button
            type="button"
            className="btn-custom-closer"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="ri-close-fill"></i>
          </button>
          <div className="modal-content-landing" >
            <div className="ratio ratio-16x9">
              {/* <a
                href="https://t.me/cvt_official_bot?profile"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default modal behavior
                  e.stopPropagation(); // Prevent event propagation
                  window.open("https://t.me/cvt_official_bot?profile", "_blank"); // Open the link in a new tab
                }}
              > */}
              <img
                alt=""
                className="w-100 cc_modal_img"
                width="500px"
                src="/images/an_banner.png"
              />
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>


      {/* Transfer to p2p modal  */}
      <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="showModalLaebl" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content" style={{ background: "none", border:"none" }}>
            <div class="modal-headern ">
              <h3 class="modal-title" id="placeBitLaebl">
              </h3>
              <button type="button" class="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close">
                <i class="ri-close-fill"></i>
              </button>
            </div>
            {/* <div class="modal-body "> */}
            <>
              <a
                href="https://t.me/cvt_official_bot?profile"
                target="_blank"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default modal behavior
                  e.stopPropagation(); // Prevent event propagation
                  window.open("https://t.me/cvt_official_bot?profile", "_blank"); // Open the link in a new tab
                }}
              >   <img src="/images/airdrop.jpg" className="img-fluid" />
              </a>
            </>

          </div>
          {/* </div> */}
        </div>
      </div>

    </>
  );
};

export default LandingPage;
